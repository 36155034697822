import React from "react";

import { ButtonIcon } from "_components";

import { ToLocaleDateString } from "_utils";
import {
  faMinusSquare,
  faPlusSquare,
  faPen,
} from "@fortawesome/free-solid-svg-icons";

import { PropTypes } from "prop-types";

class LigneTacheClient extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAccordionOpen: false,
    };
  }

  render() {
    return (
      <React.Fragment key={this.props.tache.id}>
        <tr className={this.props.index % 2 ? "shadowLine" : ""}>
          <td
            className={this.props.tache.joursRetard ? "bg-warning" : ""}
            style={{ width: "20px" }}
          >
            <ButtonIcon
              icon={this.state.isAccordionOpen ? faMinusSquare : faPlusSquare}
              className="btn"
              onClick={() =>
                this.setState((prevState) => ({
                  isAccordionOpen: !prevState.isAccordionOpen,
                }))
              }
              style={{ padding: "0rem" }}
              iconSize="sm"
            ></ButtonIcon>
          </td>
          <td style={{ maxWidth: "200px" }}>
            {this.props.tache.statut?.designation ?? "-"}
          </td>
          <td style={{ maxWidth: "250px" }}>
            {ToLocaleDateString(this.props.tache.dateCreation)}
          </td>
          <td>
            {this.props.tache.contact ? (
              <a
                href={"/contacts/" + this.props.tache.contact.id}
                target="_blank"
              >
                {this.props.tache.contact.nom_Contact +
                  " " +
                  this.props.tache.contact.prenom_Contact ?? "-"}
              </a>
            ) : (
              "-"
            )}
          </td>
          <td>{this.props.tache.action?.designation ?? "-"}</td>
          <td>
            {this.props.tache.typeEntiteReference ? (
              <a
                href={
                  "/" +
                  this.props.tache.typeEntiteReference +
                  "/" +
                  this.props.tache.idReference
                }
                target="_blank"
              >
                {this.props.tache.typeEntiteReference +
                  "/" +
                  this.props.tache.idReference}
              </a>
            ) : (
              "-"
            )}
          </td>
          <td style={{ maxWidth: "120px" }}>
            {this.props.tache.produitInterne?.designation ?? "-"}
          </td>
          <td>{this.props.tache.priorite?.designation ?? "-"}</td>

          <td style={{ maxWidth: "200px" }}>
            {this.props.tache.assigne
              ? this.props.tache.assigne.nom +
                " " +
                this.props.tache.assigne.prenom
              : "-"}
          </td>
        </tr>
        {this.state.isAccordionOpen ? (
          <>
            <tr className={this.props.index % 2 ? "shadowLine" : ""}>
              <th
                className={this.props.tache.joursRetard ? "bg-warning" : ""}
              ></th>
              <th>Date d'échéance</th>
              <th>Retard</th>
              <th>Famille d'affaire</th>
              <th>Process</th>
              <th>Date de fin</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
            <tr className={this.props.index % 2 ? "shadowLine" : ""}>
              <td
                className={this.props.tache.joursRetard ? "bg-warning" : ""}
              ></td>
              <td>
                {ToLocaleDateString(this.props.tache.dateEcheance) ?? "-"}
              </td>
              <td>
                {this.props.tache.joursRetard
                  ? this.props.tache.joursRetard + "j"
                  : "-"}
              </td>
              <td>{this.props.tache.familleAffaire?.designation ?? "-"}</td>
              <td>{this.props.tache.process?.designation ?? "-"}</td>
              <td>
                {this.props.tache.dateFin != null
                  ? ToLocaleDateString(this.props.tache.dateFin)
                  : "-"}
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td style={{ textAlign: "right" }} className="pe-2">
                <ButtonIcon
                  icon={faPen}
                  iconSize="sm"
                  tooltip="Editer"
                  onClick={() =>
                    this.props?.handleClickEditionTache?.(this.props.tache.id)
                  }
                  className="btn btn-secondary"
                ></ButtonIcon>
              </td>
            </tr>
            <tr className={this.props.index % 2 ? "shadowLine" : ""}>
              <td
                className={this.props.tache.joursRetard ? "bg-warning" : ""}
              ></td>
              <th colSpan={9}>Commentaire</th>
            </tr>
            <tr className={this.props.index % 2 ? "shadowLine" : ""}>
              <td
                className={this.props.tache.joursRetard ? "bg-warning" : ""}
              ></td>
              <td colSpan={9}>
                <span style={{ whiteSpace: "pre-line" }}>
                  {this.props.tache.commentaire ?? "-"}
                </span>
              </td>
            </tr>
          </>
        ) : null}
      </React.Fragment>
    );
  }
}

LigneTacheClient.propTypes = {
  tache: PropTypes.any,
};

export { LigneTacheClient };
