import React from "react";
import { App } from "App";

import { Box } from "_components";

import { WarningBar } from "_components";

import { ToLocaleDateString } from "_utils";

class LLD extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight("Client", "BDD Materiel");
    if (right == "RW" || right == "RO") {
      return (
        <>
          <Box
            noPaddingBottom={true}
            header={
              <div className="col-12 align-self-center py-2">
                <div>Contrats en cours</div>
              </div>
            }
            body={
              <table className="table table-striped table-bordered table-sm">
                <thead
                  className={
                    "sticky-top" +
                    (this.props.client?.contratsEnCours?.length == 0
                      ? " theadBorderRadius"
                      : "")
                  }
                >
                  <tr>
                    <th>N° Etude</th>
                    <th>Catégorie</th>
                    <th>Dates abonnements</th>
                    <th>Pack</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.client.contratsEnCours.map((contrat) => (
                    <tr key={contrat.id}>
                      <td className="d-flex">
                        <a
                          href={"/contrats/" + contrat.id}
                          target="_blank"
                          data-text={contrat.numEtude}
                          className="fixWidthHover"
                        >
                          {contrat.numEtude}
                        </a>
                      </td>
                      <td>{contrat.categorie?.designation ?? "-"}</td>
                      <td>
                        {ToLocaleDateString(contrat.debutAbonnement) +
                          " - " +
                          ToLocaleDateString(contrat.finAbonnement)}
                      </td>
                      <td>{contrat.pack?.designation ?? "-"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            }
          />
          <Box
            noPaddingBottom={true}
            header={
              <div className="col-12 align-self-center py-2">
                <div>Contrats terminés</div>
              </div>
            }
            body={
              <table className="table table-striped table-bordered table-sm">
                <thead
                  className={
                    "sticky-top" +
                    (this.props.client?.contratsTermines?.length == 0
                      ? " theadBorderRadius"
                      : "")
                  }
                >
                  <tr>
                    <th>N° Etude</th>
                    <th>Catégorie</th>
                    <th>Dates abonnements</th>
                    <th>Pack</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.client.contratsTermines?.map((contrat) => (
                    <tr key={contrat.id}>
                      <td>{contrat.numEtude}</td>
                      <td>{contrat.categorie?.designation ?? "-"}</td>
                      <td>
                        {ToLocaleDateString(contrat.debutAbonnement) +
                          " - " +
                          ToLocaleDateString(contrat.finAbonnement)}
                      </td>
                      <td>{contrat.pack?.designation ?? "-"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            }
          />
        </>
      );
    } else
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
  }
}

export { LLD };
