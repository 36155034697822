import React from "react";
import { App } from "App";

import { BoxInformationsInline, Box, WarningBar } from "_components";

import { AccordionParAnnee } from "./SubTab/AccordionParAnnee";

import { formatValueDecimal } from "_utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

class FinancierAffaire extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight("Affaire", "Financier");
    if (right == "RW" || right == "RO") {
      const isAffaireEmpty = !Object.entries(this.props.affaire).length;
      if (isAffaireEmpty) {
        return (
          <div className="alert alert-danger mt-2 mx-auto" role="alert">
            Une erreur a été rencontrée ! (Les informations de l'affaire ne sont
            pas disponibles)
          </div>
        );
      } else if (this.props.loading) {
        return (
          <div className="d-flex justify-content-center py-2">
            <FontAwesomeIcon icon={faSpinner} size="lg" className="fa-spin" />
          </div>
        );
      } else {
        const infoRecette = [
          {
            label: "Somme des commandes",
            accessor: "sommeCommandes",
            colSize: 2,
          },
          {
            label: "Somme des factures",
            accessor: "sommeFactures",
            colSize: 2,
          },
          {
            label: "Somme des montants payés",
            accessor: "sommePayeFacture",
            colSize: 2,
          },
        ];

        const infoDepense = [
          {
            label: "Somme des bons de commandes d'achat (HT)",
            accessor: "sommeBonsCommande",
            colSize: 3,
          },
          // {
          //   label: "Somme des heures",
          //   accessor: "sommeHeurePersonnel",
          //   colSize: 3,
          // },
          // {
          //   label: "Somme des notes de frais",
          //   accessor: "sommeNoteFrais",
          //   colSize: 3,
          // },
          {
            label: "Somme des dépenses",
            accessor: "sommeDepense",
            colSize: 3,
          },
          // {
          //   label: "Prime",
          //   accessor: "prime",
          //   colSize: 3,
          // // },
          // {
          //   label: "Majoration et récupération",
          //   accessor: "majorationRecup",
          //   colSize: 3,
          // // },
          // {
          //   label: "Somme des sous-traitances",
          //   accessor: "sommeSousTraitance",
          //   colSize: 3,
          // },
        ];

        const infoRecap = [
          {
            label: "Marge en euros",
            accessor: "margeEnEuros",
            colSize: 4,
          },
          {
            label: "Taux de marge",
            accessor: "tauxMarge",
            functionAppliedToValue: (tauxMarge) => {
              return tauxMarge
                ? formatValueDecimal(tauxMarge, null, 2) + " %"
                : "-";
            },
            colSize: 4,
          },
        ];

        // On crée la hierarchie des documents à afficher :
        // Devis : Chaque devis qui vient des commandes de l'affaire.
        //    Commandes : Les commandes associées à chaque devis.
        //        Factures : Les factures (plusieurs en cas d'avoir) associées à chaque commande.
        // TODO : différencier les factures d'avoir des factures classiques.
        var arrDevisIds = [];
        var arrDevis = [];
        var arrCommandesSansDevis = [];
        var arrFacturesIds = [];
        this.props.commandes?.forEach((c) => {
          var facturesLiees = this.props.factures?.filter(
            (f) => f.ref_BC == c.reference
          );
          facturesLiees.forEach((f) => arrFacturesIds.push(f.num_Facture));

          if (c.devis != null) {
            if (!arrDevisIds.includes(c.devis.id)) {
              arrDevisIds.push(c.devis.id);
              arrDevis.push({
                ...c.devis,
                commandes: [
                  {
                    ...c,
                    factures: facturesLiees,
                  },
                ],
              });
            } else {
              arrDevis[
                arrDevis.findIndex((d) => d.id == c.devis.id)
              ].commandes.push({
                ...c,
                factures: facturesLiees,
              });
            }
          } else {
            arrCommandesSansDevis.push({
              ...c,
              factures: facturesLiees,
            });
          }
        });

        var arrFacturesSansCommandes = this.props.factures?.filter(
          (f) => !arrFacturesIds.includes(f.num_Facture)
        );

        arrDevis.sort(
          (a, b) => new Date(b.date_Create) - new Date(a.date_Create)
        );
        arrDevis.forEach((d) =>
          d.commandes.sort(
            (a, b) => new Date(b.date_Create) - new Date(a.date_Create)
          )
        );
        arrDevis.forEach((d) =>
          d.commandes.forEach((c) =>
            c.factures.sort(
              (a, b) => new Date(b.dateCreation) - new Date(a.dateCreation)
            )
          )
        );

        arrCommandesSansDevis.sort(
          (a, b) => new Date(b.date_Create) - new Date(a.date_Create)
        );
        arrCommandesSansDevis.forEach((c) =>
          c.factures.sort(
            (a, b) => new Date(b.dateCreation) - new Date(a.dateCreation)
          )
        );

        arrFacturesSansCommandes.sort(
          (a, b) => new Date(b.dateCreation) - new Date(a.dateCreation)
        );

        var arrDocumentParAnnee = {};

        arrDevis.forEach((devis) =>
          arrDocumentParAnnee[new Date(devis.date_Create).getFullYear()]
            ? arrDocumentParAnnee[new Date(devis.date_Create).getFullYear()]
                ?.devis
              ? arrDocumentParAnnee[
                  new Date(devis.date_Create).getFullYear()
                ].devis.push(devis)
              : (arrDocumentParAnnee[
                  new Date(devis.date_Create).getFullYear()
                ].devis = [devis])
            : (arrDocumentParAnnee[new Date(devis.date_Create).getFullYear()] =
                {
                  annee: new Date(devis.date_Create).getFullYear(),
                  devis: [devis],
                  commandes: [],
                  factures: [],
                })
        );

        arrCommandesSansDevis.forEach((commande) =>
          arrDocumentParAnnee[new Date(commande.date_Create).getFullYear()]
            ? arrDocumentParAnnee[new Date(commande.date_Create).getFullYear()]
                ?.commandes
              ? arrDocumentParAnnee[
                  new Date(commande.date_Create).getFullYear()
                ].commandes.push(commande)
              : (arrDocumentParAnnee[
                  new Date(commande.date_Create).getFullYear()
                ].commandes = [commande])
            : (arrDocumentParAnnee[
                new Date(commande.date_Create).getFullYear()
              ] = {
                annee: new Date(commande.date_Create).getFullYear(),
                devis: [],
                commandes: [commande],
                factures: [],
              })
        );

        arrFacturesSansCommandes.forEach((facture) =>
          arrDocumentParAnnee[new Date(facture.dateCreation).getFullYear()]
            ? arrDocumentParAnnee[new Date(facture.dateCreation).getFullYear()]
                ?.factures
              ? arrDocumentParAnnee[
                  new Date(facture.dateCreation).getFullYear()
                ].factures.push(facture)
              : (arrDocumentParAnnee[
                  new Date(facture.dateCreation).getFullYear()
                ].factures = [facture])
            : (arrDocumentParAnnee[
                new Date(facture.dateCreation).getFullYear()
              ] = {
                annee: new Date(facture.dateCreation).getFullYear(),
                devis: [],
                commandes: [],
                factures: [facture],
              })
        );

        return (
          <>
            <div className="row">
              <div className="col-12">
                <Box
                  noPaddingBottom={true}
                  header={
                    <div className="col-12 align-self-center py-2">
                      <div>Devis/Commandes/Factures</div>
                    </div>
                  }
                  body={
                    <table className="table table-sm table-striped table-bordered">
                      <thead
                        className={
                          "sticky-top" +
                          (Object.entries(arrDocumentParAnnee)?.length == 0
                            ? " theadBorderRadius"
                            : "")
                        }
                      >
                        <tr>
                          <th>Année</th>
                          <th>Document</th>
                          <th>Date de création</th>
                          <th>Prix HT</th>
                          <th>Est Reglée</th>
                          <th>Montant Reglé</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(arrDocumentParAnnee)
                          .sort((a, b) => b[0] - a[0])
                          .map(function ([key, value], index) {
                            return (
                              <AccordionParAnnee
                                key={key}
                                annee={key}
                                isInitiallyOpen={index == 0}
                                devis={value.devis}
                                commandes={value.commandes}
                                factures={value.factures}
                              />
                            );
                          })}
                      </tbody>
                    </table>
                  }
                />
              </div>
              <div className="col-12">
                <BoxInformationsInline
                  title="Recette"
                  datas={infoRecette}
                  state={this.props.affaire}
                  stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                  service={this.props.service}
                  handleChange={this.props.handleChange}
                  colSize="4"
                  NoEdition
                />
              </div>

              <div className="col-12">
                <BoxInformationsInline
                  title="Dépense"
                  datas={infoDepense}
                  state={this.props.affaire}
                  stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                  service={this.props.service}
                  handleChange={this.props.handleChange}
                  colSize="4"
                  NoEdition
                />
              </div>

              <div className="col-12">
                <BoxInformationsInline
                  title="Récapitulatif"
                  datas={infoRecap}
                  state={this.props.affaire}
                  stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                  service={this.props.service}
                  handleChange={this.props.handleChange}
                  colSize="4"
                  NoEdition
                />
              </div>
            </div>
          </>
        );
      }
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

export { FinancierAffaire };
