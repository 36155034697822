import http from "http-common";

const ContratService = class {
  /**
   * Fonction permettant de récuperer tous les contactss avec une possibilité de filtre via params
   * @param {*} params, paramètres à utiliser pour la requête (page, nombre d'éléments par page et filtres sur les champs)
   * @returns une liste des contactss respectant les critères de params et le nombre de page pour ces critères
   */
  static getAll(params) {
    return http.get("/contrats/", { params });
  }

  static getAllNonLieByClient(idClient) {
    return http.get("/contrats/ByClientNonLie/" + idClient);
  }
  /**
   * Fonction permettant de récuperer un contacts via son id
   * @param {*} id, id de du contacts à récupérer
   * @returns le contacts souhaité
   */
  static getById(id) {
    return http.get("/contrats/" + id);
  }

  /**
   * Fonction permettant de créer un nouveau contact pour un client donné
   * @param {*} contact, le contact à ajouter
   * @param {*} idClient, l'id du client à qui ajouter le contact
   * @returns le contact crée avec son id
   */
  static post(contrat) {
    return http.post("/contrats", contrat);
  }

  /**
   * Fonction permettant de mettre à jour un contact
   * @param {*} contact, le contact à mettre à jour
   * @returns
   */
  static put(contrat) {
    // contact.date_Modif = new Date().toISOString();
    return http.put("/contrats/" + contrat.id, contrat);
  }

  static delete(id) {
    // contact.date_Modif = new Date().toISOString();
    return http.delete("/contrats/" + id);
  }

  /**
   * @returns un fichier
   */
  static print(id, modele) {
    return http({
      url: "/contrats/impression/" + id + "/" + modele,
      method: "GET",
      responseType: "blob",
    });
  }

  /**
   * @returns un fichier
   */
  static printAss(id) {
    return http({
      url: "/contrats/impressionAss/" + id,
      method: "GET",
      responseType: "blob",
    });
  }

  static Extract(payload) {
    const params = new URLSearchParams(payload);
    return http({
      url: "/contrats/extraction?" + params,
      method: "GET",
      responseType: "blob",
    });
  }
};

export { ContratService };
