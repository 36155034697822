import React from "react";

import { BoxInformationsInline, WarningBar } from "_components";

import { BoxContrats } from "./SubTable/Contrats";
import { BoxDatesQuestionnaire } from "./SubTable/DatesQuestionnaire";
import { BoxScope3 } from "./SubTable/Scope3";
import { BoxQualite } from "./SubTable/Qualite";
import { App } from "App";

const Gestion = (props) => {
  let right = App.RightsGuard?.current?.hasRight("Fournisseurs", "Fiche");

  const rightb = App.RightsGuard?.current?.isPersonne(
    props.fournisseur?.referentInterne?.oid
  );

  if (rightb == "RW") {
    right = rightb;
  }

  if (right == "RO" || right == "RW") {
    const blocJuridique = [
      {
        label: "NDA",
        accessor: "nda",
        colSize: 4,
        type: "checkbox",
        functionAppliedToValue: (value) => {
          return value ? "Oui" : "Non";
        },
      },
      {
        label: "Date de fin NDA",
        accessor: "dateFinNDA",
        colSize: 4,
        type: "date",
      },
      {
        label: "Confidentialité RH",
        accessor: "confidentialiteRH",
        colSize: 4,
        type: "checkbox",
        functionAppliedToValue: (value) => {
          return value ? "Oui" : "Non";
        },
      },
      {
        label: "Indépendance capitalistique",
        accessor: "independanceCapitalistique",
        colSize: 4,
        type: "checkbox",
        functionAppliedToValue: (value) => {
          return value ? "Oui" : "Non";
        },
      },
      {
        label: "Rang FRN",
        accessor: "rangFRN",
        colSize: 4,
        type: "selectSearch",
        options: [
          {
            id: 1,
            value: 1,
          },
          {
            id: 2,
            value: 2,
          },
        ],
        optionFieldToDisplay: "value",
        valueFieldToDisplay: "value",
        optionFieldToReturn: "value",
        functionAppliedToValue: (value) => {
          return value ?? "-";
        },
      },
    ];

    const blocRSE = [
      {
        label: "Politique RSE",
        accessor: "politiqueRSE",
        colSize: 4,
        type: "checkbox",
        functionAppliedToValue: (value) => {
          return value ? "Oui" : "Non";
        },
      },
      {
        label: "RSE - date de mise à jour",
        accessor: "dateMaJRSE",
        colSize: 4,
        type: "date",
      },
      {
        label: "Scope 3",
        accessor: "scope3",
        colSize: 4,
        type: "checkbox",
        functionAppliedToValue: (value) => {
          return value ? "Oui" : "Non";
        },
      },
      {
        label: "Retour questionnaire Sol",
        accessor: "retourQuestionnaireSol",
        colSize: 4,
        type: "checkbox",
        functionAppliedToValue: (value) => {
          return value ? "Oui" : "Non";
        },
      },
    ];
    const blocQualite = [
      {
        label: "Certification Organisme CIR",
        accessor: "certificationOrganismeCIR",
        colSize: 5,
        type: "checkbox",
        colSizeInnerFields: 4,
        functionAppliedToValue: (value) => {
          return value ? "Oui" : "Non";
        },
      },
      {
        label: "Date Fin Certification Organisme CIR",
        accessor: "dateFinCertificationOrganismeCIR",
        colSize: 5,
        type: "date",
        colSizeInnerFields: 4,
      },

      {
        label: "Dates fermeture Estivale",
        accessor: "datesFermetureEstivale",
        colSize: 5,
        type: "period",
      },

      {
        label: "Dates fermeture hivernale",
        accessor: "datesFermetureHivernale",
        colSize: 5,
        type: "period",
      },
      {
        label: "Commentaire",
        accessor: "commentaire",
        colSize: 10,
        type: "textArea",
      },
    ];

    return (
      <>
        <div className="row ficheMateriel">
          <div className="col-6 ">
            <BoxInformationsInline
              title="Juridique"
              datas={blocJuridique}
              state={props.fournisseur}
              stateFieldNameToUpdate={props.stateFieldNameToUpdate}
              service={props.service}
              handleChange={props.handleChange}
              handleEditing={props.handleEditing}
              editing={props.editing}
              handleUpdate={props.handleUpdate}
              NoEdition={right == "RO"}
            />
          </div>
          <div className="col-6 ">
            <BoxContrats
              handleChange={props.handleChange}
              handleEditing={props.handleEditing}
              editing={props.editing}
              handleUpdate={props.handleUpdate}
              fournisseur={props.fournisseur}
              NoEdition={right == "RO"}
            />
          </div>

          <div className="col-6 ">
            <BoxInformationsInline
              title="Qualité"
              datas={blocQualite}
              state={props.fournisseur}
              stateFieldNameToUpdate={props.stateFieldNameToUpdate}
              service={props.service}
              handleChange={props.handleChange}
              handleEditing={props.handleEditing}
              editing={props.editing}
              handleUpdate={props.handleUpdate}
              NoEdition={right == "RO"}
            />
          </div>
          <div className="col-6 ">
            <BoxQualite
              handleChange={props.handleChange}
              handleEditing={props.handleEditing}
              editing={props.editing}
              handleUpdate={props.handleUpdate}
              fournisseur={props.fournisseur}
              NoEdition={right == "RO"}
            />
          </div>
          <div className="col-6 ">
            <BoxInformationsInline
              title="RSE"
              datas={blocRSE}
              state={props.fournisseur}
              stateFieldNameToUpdate={props.stateFieldNameToUpdate}
              service={props.service}
              handleChange={props.handleChange}
              handleEditing={props.handleEditing}
              editing={props.editing}
              handleUpdate={props.handleUpdate}
              NoEdition={right == "RO"}
            />
          </div>

          <div className="col-6 ">
            <BoxDatesQuestionnaire
              handleChange={props.handleChange}
              handleEditing={props.handleEditing}
              editing={props.editing}
              handleUpdate={props.handleUpdate}
              fournisseur={props.fournisseur}
              NoEdition={right == "RO"}
            />
          </div>
          <div className="col-6 ">
            <BoxScope3
              handleChange={props.handleChange}
              handleEditing={props.handleEditing}
              editing={props.editing}
              handleUpdate={props.handleUpdate}
              fournisseur={props.fournisseur}
              NoEdition={right == "RO"}
            />
          </div>
        </div>
      </>
    );
  } else
    return (
      <>
        <WarningBar
          active={false}
          content={"Vous n'avez pas le droit de voir cette page"}
        />
      </>
    );
};

export { Gestion };
