import {
  faCheck,
  faPen,
  faPlus,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Input } from "_components/Input";

const { Box, ButtonIcon, FormWithValidation } = require("_components");
const { useState } = require("react");

const LigneQualite = (props) => {
  return (
    <tr className="ligneFournisseurQualite">
      <td className="col3 background-color">
        <Input
          accessor={"" + props.qualite.id + ".date"}
          value={props.qualite.date}
          type="date"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td className="col3 background-color">
        <Input
          accessor={"" + props.qualite.id + ".accreditationQualite"}
          value={props.qualite.accreditationQualite}
          type="text"
          placeholder=""
          handleBlur={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
          required={true}
        />
      </td>
      <td className="col4 background-color" style={{ width: "55px" }}>
        <ButtonIcon
          smallText=""
          icon={faTrash}
          iconSize="sm"
          onClick={() => {
            props?.deleteLineQualite(props.qualite.id);
          }}
          className="btn btn-danger deleteLineQualite"
          style={!props.editing ? { display: "none" } : {}}
        />
      </td>
    </tr>
  );
};

const BoxQualite = (props) => {
  const [savedFournisseur, setSavedFournisseur] = useState({});

  const [editingQualite, setEditingQualite] = useState(false);
  const [qualites, setQualites] = useState([]);

  const handleClickEdition = () => {
    setSavedFournisseur({ ...props.fournisseur });
    props.handleEditing(true);
    setEditingQualite(true);
    if (props.fournisseur.qualites == null) {
      setQualites([]);
    } else {
      setQualites(
        props.fournisseur.qualites.map((e) => {
          return { ...e };
        })
      );
    }
  };

  const handleClickUndo = () => {
    props.handleChange("fournisseur", savedFournisseur);
    props.handleEditing(false);
    setEditingQualite(false);
    setQualites([]);
  };

  const handleClickValidate = () => {
    props.handleChange("qualites", qualites);
    props.handleEditing(false);
    setEditingQualite(false);
    setQualites([]);
  };

  const addLineQualite = () => {
    let newLineId = Math.min(...qualites.map((el) => el.id), 0) - 1;

    let newQualite = {
      id: newLineId,
      facteurEmission: 0,
      dateFin: null,
    };
    setQualites([...qualites, newQualite]);
  };

  const deleteLineQualite = (qualiteId) => {
    let qualitetmp = qualites.map((e) => {
      return { ...e };
    });
    qualitetmp.splice(
      qualitetmp.findIndex((el) => el.id == qualiteId),
      1
    );
    console.log(qualites, qualitetmp);
    setQualites([...qualitetmp]);
  };

  const handleChangeQualite = (accessor, value) => {
    let accessorSplit = accessor.split(".");
    var id = accessorSplit[0];
    var realAccessor = accessorSplit[1];

    let qualitetmp = qualites.map((e) => {
      return { ...e };
    });

    let qualiteToUpdate = qualitetmp.find((el) => el.id == id);
    qualiteToUpdate[realAccessor] = value;

    qualitetmp.splice(
      qualitetmp.findIndex((el) => el.id == id),
      1,
      qualiteToUpdate
    );

    setQualites(qualitetmp);
  };

  return (
    <FormWithValidation
      id="FormulaireBlocQualite"
      onSubmit={handleClickValidate}
    >
      <Box
        header={
          <>
            <div className="col-6 align-self-center py-2">
              <div>Accréditations & certifications</div>
            </div>
            <div className="col-6 text-end py-2">
              <ButtonIcon
                icon={faPlus}
                iconSize="sm"
                onClick={() => addLineQualite?.()}
                className="btn btn-success addEnfant"
                style={!editingQualite ? { display: "none" } : {}}
              />
              <ButtonIcon
                icon={faCheck}
                className="btn btn-success text-light ms-1"
                type="submit"
                style={
                  !editingQualite ? { display: "none" } : { width: "42px" }
                }
              />
              <ButtonIcon
                icon={faTimes}
                className="btn btn-danger text-light ms-1"
                onClick={handleClickUndo}
                style={
                  !editingQualite ? { display: "none" } : { width: "42px" }
                }
              />
              <ButtonIcon
                icon={faPen}
                className="btn btn-secondary text-light ms-1"
                onClick={handleClickEdition}
                style={
                  props.editing || props.NoEdition
                    ? { display: "none" }
                    : { width: "42px" }
                }
              />
            </div>
          </>
        }
        body={
          <>
            <table className={"text-uppercase table table-sm "}>
              <thead
                className={
                  "sticky-top" +
                  (editingQualite
                    ? qualites.length == 0
                      ? " theadBorderRadius"
                      : " "
                    : props.fournisseur.qualites?.length == 0
                    ? " theadBorderRadius"
                    : " ")
                }
              >
                <tr>
                  <th>Date</th>
                  <th>Nom {editingQualite ? "*" : ""}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {editingQualite
                  ? qualites
                      ?.slice()
                      .map((qualite) => (
                        <LigneQualite
                          key={qualite.id}
                          qualite={qualite}
                          handleChange={handleChangeQualite}
                          editing={editingQualite}
                          deleteLineQualite={deleteLineQualite}
                        />
                      ))
                  : props.fournisseur.qualites
                      ?.slice()
                      .map((qualite) => (
                        <LigneQualite
                          key={qualite.id}
                          qualite={qualite}
                          handleChange={handleChangeQualite}
                          editing={editingQualite}
                          deleteLineQualite={deleteLineQualite}
                        />
                      ))}
              </tbody>
            </table>
          </>
        }
      />
    </FormWithValidation>
  );
};

export { BoxQualite };
