import http from "http-common";

const FournisseurService = class {
  /**
   * Fonction permettant de récuperer tous les fichesDeSuivi avec une possibilité de filtre via params
   * @param {*} params, paramètres à utiliser pour la requête (page, nombre d'éléments par page et filtres sur les champs)
   * @returns une liste des fichesDeSuivi respectant les critères de params et le nombre de page pour ces critères
   */
  static getAll(params) {
    return http.post("/fournisseurs/get", params);
  }

  /**
   * Fonction permettant de mettre à jour un fichesDeSuivi
   * @param {*} fichesDeSuivi, la fiche de suivi à mettre à jour
   * @returns
   */
  static put(fournisseur) {
    return http.put("/fournisseurs", fournisseur);
  }

  /**
   * Fonction permettant de récuperer un fichesDeSuivi via son id
   * @param {*} id, id de du fichesDeSuivi à récupérer
   * @returns la fiche de suivi souhaité
   */
  static getById(id) {
    return http.get("/fournisseurs/" + id);
  }

  static getTaches(id) {
    return http.get("/fournisseurs/taches/" + id);
  }
  /**
   * Fonction permettant de récuperer l'historique des états d'avancement d'une fiche de suivi via son ID
   * @param {*} id, id de la fichesDeSuivi dont ou souhaite récupérer l'historique
   * @returns l'historique de la fiche de suivi
   */
  static saveScore(id) {
    return http.get("/fournisseurs/saveScore/" + id);
  }

  /**
   * Fonction permettant de supprimer un fichesDeSuivi
   * @param {*} fichesDeSuivi, la fiche de suivi à supprimer
   * @returns
   */
  static delete(fichesDeSuivi) {
    return http.delete("/fournisseurs/" + fichesDeSuivi.id);
  }

  static print(modele, idFdS) {
    return http({
      url: "/fournisseurs/impression/" + modele + "/" + idFdS,
      method: "GET",
      responseType: "blob",
    });
  }

  static Extract(payload) {
    return http({
      url: "/fournisseurs/extraction",
      method: "POST",
      responseType: "blob",
      data: payload,
    });
  }

  static postHistorique(id, historique) {
    return http.post("/fournisseurs/" + id + "/historique", historique);
  }

  static getHistoriques(id) {
    return http.get("/fournisseurs/historiques/" + id);
  }

  static getStatistiques(id, anneeStatistique) {
    return http.get("/fournisseurs/statistiques/" + id, {
      params: {
        anneeStatistique: anneeStatistique,
      },
    });
  }

  static printNDA(id) {
    return http({
      url: "/fournisseurs/impressionNDA/" + id,
      method: "GET",
      responseType: "blob",
    });
  }
};

export { FournisseurService };
