import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { faSpinner } = require("@fortawesome/free-solid-svg-icons");

const Loading = () => {
  return (
    <div
      style={{
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <FontAwesomeIcon icon={faSpinner} size="lg" className="fa-spin" />
    </div>
  );
};

export { Loading };
