import React from "react";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { App } from "App";

import { General, NotesDeFrais } from "./Onglets";
import { WarningBar } from "_components";

import { CarteBancaireService } from "_services";
import { ButtonIconWithValidation } from "_components";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import { Helmet } from "react-helmet";
import TabSelector from "_components/FicheComponents/TabSelector";

class FicheCarteBancaire extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tabs: ["Général", "Note de frais"],
      active: "Général",
      editing: false,
      cartebancaire: {},
      numeroFrais: null,
      lineACopier: null,
      exportAll: false,
    };

    this.renderActive = this.renderActive.bind(this);
    this.generateTabs = this.generateTabs.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEditing = this.handleEditing.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

    this.handleUpdate = this.handleUpdate.bind(this);

    this.handleEditingNotesDeFrais = this.handleEditingNotesDeFrais.bind(this);
  }

  componentDidMount() {
    if (sessionStorage.getItem("CBOnglet") == null) {
      sessionStorage.setItem("CBOnglet", this.state.active);
    } else {
      this.setState({ active: sessionStorage.getItem("CBOnglet") });
    }

    let { id } = this.props.match.params;
    CarteBancaireService.getById(id).then((res) => {
      this.setState({ cartebancaire: res.data, loading: false });
    });
  }

  handleEditingNotesDeFrais(editing) {
    this.setState((prevState) => ({
      ...prevState,
      editing: editing,
      editingNdF: editing,
    }));
  }

  handleChange(accessor, value, callbackFunction) {
    if (accessor == "cartebancaire") {
      this.setState({ cartebancaire: value });
    } else {
      this.setState(
        (prevState) => ({
          cartebancaire: {
            ...prevState.cartebancaire,
            [accessor]: value,
          },
        }),
        callbackFunction
      );
    }
  }

  handleUpdate() {
    return CarteBancaireService.put(this.state.cartebancaire).then((res) => {
      this.setState({ cartebancaire: res.data });
    });
  }

  handleDelete() {
    return CarteBancaireService.delete(this.state.cartebancaire.id).then(
      (res) => {
        this.setState({ cartebancaire: res.data });
      }
    );
  }

  handleEditing(editing) {
    this.setState((prevState) => ({
      ...prevState,
      editing: editing,
    }));
  }

  /**
   * Génère les onglets de la fiche
   * @param {*} tabs
   * @returns
   */
  generateTabs(tabs) {
    return (
      <div>
        <TabSelector
          tabs={tabs}
          activeTab={this.state.active}
          onSelect={(tab) => {
            this.setState({ active: tab });
            sessionStorage.setItem("CBOnglet", tab);
          }}
          notAllowed={this.state.editing}
        ></TabSelector>
      </div>
    );
  }

  renderActive() {
    switch (this.state.active) {
      case "Général":
        return (
          <General
            cartebancaire={this.state.cartebancaire}
            stateFieldNameToUpdate="cartebancaire"
            handleChange={this.handleChange}
            service={CarteBancaireService}
            history={this.props.history}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
          />
        );
      case "Note de frais":
        return (
          <NotesDeFrais
            cartebancaire={this.state.cartebancaire}
            stateFieldNameToUpdate="cartebancaire"
            handleChange={this.handleChange}
            service={CarteBancaireService}
            history={this.props.history}
            exportAll={this.state.exportAll}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
            handleUpdate={this.handleUpdate}
            openDialogue={this.state.openDialogFunc}
          />
        );
      default:
        return (
          <h4 className="mt-5">Désolé la page n&apos;a pas réussi à charger</h4>
        );
    }
  }

  render() {
    const isContactEmpty = !Object.entries(this.state.cartebancaire).length;
    return (
      <>
        <Helmet>
          <title>
            {"Carte bancaire : ".concat(this.state.cartebancaire.name)}
          </title>
        </Helmet>
        {this.state.loading ? (
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon icon={faSpinner} size="lg" className="fa-spin" />
          </div>
        ) : (
          <div className=" p-3 m-4" id="ficheClient">
            <div className="d-flex overflow-auto justify-content-between py-1">
              <h4 className="nowrap pr-100">
                {"Carte bancaire : ".concat(this.state.cartebancaire.name)}
              </h4>
              <div className="d-flex">
                <Link
                  to="/cartesbancaires"
                  className="btn btn-primary nowrap ms-1"
                >
                  Base Carte Bancaire
                </Link>
                {this.props.history.length > 1 && (
                  <button
                    className="btn btn-primary nowrap ms-1"
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                  >
                    Retour
                  </button>
                )}
                {App.RightsGuard.current?.hasRight(
                  "Cartes bleues",
                  "Suppression"
                ) == "RW" ? (
                  <ButtonIconWithValidation
                    icon={faTrash}
                    onClick={this.handleDelete}
                    className="form-control btn btn-danger btn-sm text-light ms-1"
                    alertTitle=" Suppression"
                    alertBody={
                      <div>
                        Souhaitez-vous réellement désactiver cette carte ?
                        <br />
                      </div>
                    }
                    alertButtonValidationText="Oui, je veux supprimer."
                  />
                ) : null}
              </div>
            </div>
            {this.generateTabs(this.state.tabs)}

            <WarningBar
              active={this.state.cartebancaire.actif}
              content={"Cette carte n'est pas active"}
            />
            {!isContactEmpty && this.renderActive()}
          </div>
        )}
      </>
    );
  }
}

/**
 * Validation des props :
 */
FicheCarteBancaire.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export { FicheCarteBancaire };
