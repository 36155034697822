import React from "react";
import { App } from "App";

import { Box, WarningBar } from "_components";
import { AccordionFacture } from "./SubTab/AccordionFacture";

class Factures extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight("Affaire", "Factures");
    if (right == "RW" || right == "RO") {
      return (
        <>
          <Box
            noPaddingBottom={true}
            header={
              <div className="col-12 align-self-center py-2">
                <div>Factures</div>
              </div>
            }
            body={
              <>
                <table className="table table-sm table-bordered">
                  <thead
                    className={
                      "sticky-top" +
                      (this.props.factures?.length == 0
                        ? " theadBorderRadius"
                        : "")
                    }
                  >
                    <tr>
                      <th>Num</th>
                      <th>Date</th>
                      <th>Réf. article</th>
                      <th>Description</th>
                      <th>Quantité</th>
                      <th>Prix Unitaire</th>
                      <th>Prix HT</th>
                      <th>Prix TTC</th>
                      <th>Payée ?</th>
                      <th>Montant Reglé</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.factures
                      ?.slice()

                      .sort(
                        (a, b) =>
                          new Date(b.dateCreation) - new Date(a.dateCreation)
                      )
                      .map((facture, index) => (
                        <AccordionFacture
                          key={index}
                          index={index}
                          facture={facture}
                        />
                      ))}
                  </tbody>
                </table>
              </>
            }
          />
        </>
      );
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

export { Factures };
