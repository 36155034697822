import React from "react";
import { IndeterminateCheckbox, ReactTableServerSide } from "_components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import { ContactService, PaysService, CiviliteService } from "_services";
import { makeEmailClickable, RedirectTo } from "_utils";
import { PropTypes } from "prop-types";
import { CellWithRedirection } from "_components/ReactTable/ReactTableServerSide";
import { Helmet } from "react-helmet";
import { customFuseSearch } from "_components/Input/InputTypes/SelectSearchFunctions";
import { App } from "App";

class BaseContact extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Base contact</title>
        </Helmet>
        <ReactTableServerSide
          title="Base Contact"
          titreFiltre="Base Contact"
          service={ContactService.getAll}
          displayExtractButton={
            App.RightsGuard.current?.hasRight("Contact", "Extraction") == "RW"
          }
          serviceExtract={ContactService.Extract}
          module="Contact"
          redirectToCreate={() => {
            RedirectTo(this.props.history, "/contacts/" + "add");
          }}
          orderByDefault="nom_Contact"
          orderDefault="ASC"
          localStorageKeyColumns="baseContactColumns"
          objectFieldToExport={{
            pays: "nom_Fr",
          }}
          columns={[
            {
              id: "selection",
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div className="text-center align-middle" id="selectAll">
                  <IndeterminateCheckbox
                    className="align-middle"
                    {...getToggleAllRowsSelectedProps()}
                  />
                </div>
              ),
              Cell: ({ row }) => (
                <div className="text-center align-middle" id="selectOne">
                  <IndeterminateCheckbox
                    className="align-middle"
                    {...row.getToggleRowSelectedProps()}
                  />
                </div>
              ),
            },
            {
              Header: "Actif",
              accessor: "actif",
              hideable: true,
              sortable: true,
              exportable: true,
              defaultValue: {
                id: 1,
                display: "Oui",
                value: true,
              },
              minWidth: "130px",
              type: "selectSearch",
              optionFieldToReturn: "value",
              optionFieldToDisplay: "display",
              valueFieldToDisplay: "display",
              options: [
                {
                  id: 1,
                  display: "Oui",
                  value: true,
                },
                {
                  id: 2,
                  display: "Non",
                  value: false,
                },
              ],
              Cell: (row) => {
                if (row.cell.value == true) {
                  return <div>Oui</div>;
                } else {
                  return (
                    <div>
                      Non
                      <FontAwesomeIcon
                        className="text-danger"
                        icon={faExclamationTriangle}
                      />
                    </div>
                  );
                }
              },
            },
            {
              Header: "Civilité",
              accessor: "civilite",
              search: "civiliteId",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "170px",
              type: "selectSearch",
              optionFieldToDisplay: "designation",
              valueFieldToDisplay: "designation",
              optionFieldToReturn: "id",
              service: CiviliteService.getAll,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.designation}</span>
                ) : null;
              },
            },
            {
              Header: "Nom",
              accessor: "nom_Contact",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "300px",
              type: "text",
              Cell: (row) => {
                return (
                  <CellWithRedirection
                    history={this.props.history}
                    redirection="/contacts/"
                    id={row.cell.row.original.id}
                    value={row.cell.value}
                  />
                );
              },
            },
            {
              Header: "Prénom",
              accessor: "prenom_Contact",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "170px",
              type: "text",
            },
            {
              Header: "Email",
              accessor: "email",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "180px",
              type: "email",
              Cell: (row) => {
                return <span>{makeEmailClickable(row.cell.value)}</span>;
              },
            },
            {
              Header: "Email 2",
              accessor: "email2",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "180px",
              type: "email",
              Cell: (row) => {
                return <span>{makeEmailClickable(row.cell.value)}</span>;
              },
            },
            {
              Header: "Portable",
              accessor: "portable",
              hideable: true,
              exportable: true,
              minWidth: "180px",
              type: "text",
            },
            {
              Header: "Standard",
              accessor: "standard",
              hideable: true,
              exportable: true,
              minWidth: "180px",
              type: "text",
            },

            {
              Header: "Ville",
              accessor: "adresse.ville",
              hideable: true,
              exportable: true,
              minWidth: "180px",
              type: "text",
              search: "ville",
            },
            {
              Header: "Code postal",
              accessor: "adresse.cp",
              hideable: true,
              exportable: true,
              minWidth: "110px",
              type: "text",
              search: "cp",
            },
            {
              Header: "Pays",
              accessor: "adresse.pays",
              hideable: true,
              exportable: true,
              minWidth: "180px",
              type: "selectSearch",
              search: "pays",
              optionFieldToDisplay: "nom_Fr",
              valueFieldToDisplay: "nom_Fr",
              optionFieldToReturn: "nom_Fr",
              customFilter: (options) =>
                customFuseSearch(options, {
                  threshold: 0.4,
                  ignoreLocation: true,
                }),
              service: PaysService.getAll,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.nom_Fr}</span>
                ) : null;
              },
            },
            {
              Header: "Client",
              accessor: "hasClient",
              search: "client",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "selectSearch",
              valueFieldToDisplay: "display",
              optionFieldToDisplay: "display",
              optionFieldToReturn: "value",
              options: [
                {
                  id: 1,
                  display: "Oui",
                  value: true,
                },
                {
                  id: 2,
                  display: "Non",
                  value: false,
                },
              ],
              Cell: (row) => {
                if (row.cell.value == true) {
                  return <div>Oui</div>;
                } else {
                  return <div>Non</div>;
                }
              },
            },
            {
              Header: "Fournisseur",
              accessor: "hasFournisseur",
              search: "fournisseur",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "selectSearch",
              valueFieldToDisplay: "display",
              optionFieldToDisplay: "display",
              optionFieldToReturn: "value",
              options: [
                {
                  id: 1,
                  display: "Oui",
                  value: true,
                },
                {
                  id: 2,
                  display: "Non",
                  value: false,
                },
              ],
              Cell: (row) => {
                if (row.cell.value == true) {
                  return <div>Oui</div>;
                } else {
                  return <div>Non</div>;
                }
              },
            },
          ]}
        />
      </>
    );
  }
}

BaseContact.propTypes = {
  history: PropTypes.object,
};

export { BaseContact };
