import http from "http-common";

const FicheDeSuiviService = class {
  /**
   * Fonction permettant de récuperer tous les fichesDeSuivi avec une possibilité de filtre via params
   * @param {*} params, paramètres à utiliser pour la requête (page, nombre d'éléments par page et filtres sur les champs)
   * @returns une liste des fichesDeSuivi respectant les critères de params et le nombre de page pour ces critères
   */
  static getAll(params) {
    return http.get("/fichesDeSuivi/", { params });
  }

  static getForSS(params) {
    return http.get("/fichesDeSuivi/", {
      params: {
        orderBy: "reference",
        order: "ASC",
        nom_Client: params,
        size: 20,
        actif: true,
      },
    });
  }

  /**
   * Fonction permettant de créer un nouveau fichesDeSuivi pour un fichesDeSuivi donné
   * @param {*} fichesDeSuivi, la fiche de suivi à ajouter
   * @returns la fiche de suivi crée avec son id
   */
  static post(fichesDeSuivi) {
    return http.post("/fichesDeSuivi", fichesDeSuivi);
  }

  /**
   * Fonction permettant de mettre à jour un fichesDeSuivi
   * @param {*} fichesDeSuivi, la fiche de suivi à mettre à jour
   * @returns
   */
  static put(fichesDeSuivi) {
    return http.put("/fichesDeSuivi", fichesDeSuivi);
  }

  /**
   * Fonction permettant de récuperer un fichesDeSuivi via son id
   * @param {*} id, id de du fichesDeSuivi à récupérer
   * @returns la fiche de suivi souhaité
   */
  static getById(id) {
    return http.get("/fichesDeSuivi/" + id);
  }

  /**
   * Fonction permettant de supprimer un fichesDeSuivi
   * @param {*} fichesDeSuivi, la fiche de suivi à supprimer
   * @returns
   */
  static delete(fichesDeSuivi) {
    return http.delete("/fichesDeSuivi/" + fichesDeSuivi.id);
  }

  static print(modele,idFdS) {
    return http({
     url: "/fichesDeSuivi/impression/"+modele+"/"+idFdS,
     method: "GET",
     responseType: "blob",
   });
 }

 static printContratLoc( modele, idfds){
  return http({
    url: "/fichesDeSuivi/impressionContrat/"+modele+"/"+idfds ,
    method: "GET",
    responseType: "blob",
  });
 }

 static Extract(payload) {
  const params = new URLSearchParams(payload);
  return http({
    url: "/fichesDeSuivi/extraction?"+params,
    method: "GET",
    responseType: "blob",
  });
}

};

export { FicheDeSuiviService };
