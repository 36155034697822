import React from "react";

import { ButtonIcon } from "_components";
import { faMinusSquare, faPlusSquare } from "@fortawesome/free-solid-svg-icons";

import { ToLocaleDateString } from "_utils";

import { PropTypes } from "prop-types";

class AccordionDevis extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAccordionOpen: false,
    };
  }

  render() {
    return (
      <>
        <tr
          key={this.props.index * 1000}
          className={this.props.index % 2 ? "shadowLine" : ""}
        >
          <td className="tableIndent1">
            <ButtonIcon
              icon={this.state.isAccordionOpen ? faMinusSquare : faPlusSquare}
              className="btn"
              onClick={() =>
                this.setState((prevState) => ({
                  isAccordionOpen: !prevState.isAccordionOpen,
                }))
              }
              style={{ width: "32px" }}
            ></ButtonIcon>
            <a
              className="fixWidthHover"
              href={"/devis/" + this.props.devis.id}
              target={"_blank"}
              data-text={this.props.devis.reference}
            >
              {" " + this.props.devis.reference}
            </a>
            {this.props.devis.nom_Devis}
          </td>
          <td className="tableIndent1 pt-2">
            {ToLocaleDateString(this.props.devis.date_Create)}
          </td>
          <td className="tableIndent1"></td>
          <td className="tableIndent1"></td>
          <td className="tableIndent1"></td>
          <td className="tableIndent1"></td>
          <td className="tableIndent1 pt-2">
            {this.props.devis.prixVenteTotal}
          </td>
          <td className="tableIndent1 pt-2">
            {this.props.devis.prixVenteTotalTTC}
          </td>
        </tr>

        {this.state.isAccordionOpen
          ? this.props.devis.devisDetails
              ?.slice()
              .sort((a, b) => a.position - b.position)
              .map((detail, index) => {
                return (
                  <tr
                    key={this.props.index * 1000 + index}
                    className={
                      "text-muted " + (this.props.index % 2 ? "shadowLine" : "")
                    }
                  >
                    <td className="tableIndent1"></td>
                    <td className="tableIndent1"></td>
                    <td className="tableIndent1 ">{detail.article?.aR_Ref}</td>
                    <td className="tableIndent1">{detail.description}</td>
                    <td className="tableIndent1">{detail.quantite}</td>
                    <td className="tableIndent1">{detail.prixVenteUnitaire}</td>
                    <td className="tableIndent1">{detail.prixVenteTotal}</td>
                    <td className="tableIndent1">{detail.prixVenteTotalTTC}</td>
                  </tr>
                );
              })
          : null}
      </>
    );
  }
}

AccordionDevis.propTypes = {
  index: PropTypes.number,
  devis: PropTypes.any,
};

export { AccordionDevis };
