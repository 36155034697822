import { App } from "App";
import React from "react";
import { produce } from "immer";

import {
  faCheck,
  faExclamationTriangle,
  faFileInvoiceDollar,
  faPen,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import {
  AlertDialog,
  Box,
  ButtonIcon,
  DataFieldColumn,
  DialogForm,
} from "_components";
import { Input } from "_components/Input";
import {
  ArticleService,
  ClientService,
  ContactService,
  DevisService,
  LicencesService,
  TypesLicenceService,
} from "_services";
import { ToLocaleDateString } from "_utils";

import { BoxLicence } from "./SubTab/BoxLicence";

class Logiciels extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedLicence: null,
      selectedContact: null,
      selectedClient: null,
      isNewClient: true,
      selectedModules: [],
      nouvellesLicences: [],
      openDialogSelectContact: null,
      openDialogAddModules: null,
      openDialogRemoveModules: null,
      openDialogAddLicences: null,
      openWarningEndAll: false,
      editingInfoEntreprise: false,
    };

    this.setOpenDialogSelectContact =
      this.setOpenDialogSelectContact.bind(this);
    this.setOpenDialogAddModules = this.setOpenDialogAddModules.bind(this);
    this.setOpenDialogRemoveModules =
      this.setOpenDialogRemoveModules.bind(this);
    this.setOpenDialogAddLicences = this.setOpenDialogAddLicences.bind(this);
    this.setOpenDialogSelectDistributorClient =
      this.setOpenDialogSelectDistributorClient.bind(this);

    this.handleContactSelection = this.handleContactSelection.bind(this);
    this.handleChangeModules = this.handleChangeModules.bind(this);
    this.handleClickEndAll = this.handleClickEndAll.bind(this);
    this.handleCloseEndAllWarning = this.handleCloseEndAllWarning.bind(this);
    this.handleValidateEndAllWarning =
      this.handleValidateEndAllWarning.bind(this);
  }

  setOpenDialogSelectContact(openDialogFunc) {
    this.setState({ openDialogSelectContact: openDialogFunc });
  }

  setOpenDialogAddModules(openDialogFunc) {
    this.setState({ openDialogAddModules: openDialogFunc });
  }

  setOpenDialogRemoveModules(openDialogFunc) {
    this.setState({ openDialogRemoveModules: openDialogFunc });
  }

  setOpenDialogAddLicences(openDialogFunc) {
    this.setState({ openDialogAddLicences: openDialogFunc });
  }

  setOpenDialogSelectDistributorClient(openDialogFunc) {
    this.setState({ openDialogSelectDistributorClient: openDialogFunc });
  }

  handleContactSelection(acc, value) {
    this.setState({ selectedContact: value });
  }

  handleChangeModules(acc, value) {
    this.setState({ selectedModules: value });
  }

  handleClickEndAll() {
    this.setState({ openWarningEndAll: true });
  }

  handleCloseEndAllWarning() {
    this.setState({ openWarningEndAll: false });
  }

  handleValidateEndAllWarning() {
    this.handleCloseEndAllWarning();
    this.props.handleClickSuspendAllLicences();
  }

  generateDialogForm() {
    return (
      <>
        <DialogForm
          dialogTitle={"Choix du contact"}
          labelValidateButton="Attribuer"
          labelCancelButton="Annuler"
          setOpenDialog={this.setOpenDialogSelectContact}
          validation={() => {
            if (this.state.selectedContact != null) return true;
            else
              App.Toaster.current?.createToast({
                body: "Vous devez sélectionner un contact",
                header: "Echec",
                type: "failure",
              });

            return false;
          }}
          onValidate={() => {
            this.props.handleClickAddUserLogiciels(
              this.state.selectedLicence.numero,
              this.state.selectedContact
            );
          }}
          onClose={() =>
            this.setState({ selectedLicence: null, selectedContact: null })
          }
          body={
            <>
              <Input
                type="selectSearchDisabledValues"
                label="Contact"
                service={() =>
                  ContactService.getByIdClient(this.props.client.id)
                }
                valueFieldToDisplay={["nom_Contact", "prenom_Contact", "email"]}
                optionFieldToDisplay={[
                  "nom_Contact",
                  "prenom_Contact",
                  "email",
                ]}
                handleChange={this.handleContactSelection}
                disableOption={(contact) =>
                  this.props.isInvalidLicenceUser(
                    contact,
                    this.state.selectedLicence
                  )
                }
              />
            </>
          }
        />
        <DialogForm
          dialogTitle={"Ajout de modules"}
          labelValidateButton="Valider"
          labelCancelButton="Annuler"
          setOpenDialog={this.setOpenDialogAddModules}
          onValidate={() => {
            DevisService.postLicenceAddModules(
              this.state.selectedLicence.numero,
              { client: this.props.client },
              this.state.selectedModules.map((module) => ({
                aR_Ref: module.aR_Ref,
              }))
            )
              .then((res) => {
                this.props.history.push("/devis/" + res.data.id);
              })
              .catch((error) => {
                console.log(error);
                this.setState({
                  isSubmitting: false,
                });
              });
          }}
          onClose={() => {
            this.setState({ selectedLicence: null, selectedModules: [] });
          }}
          body={
            <>
              <Input
                type="multipleSelect"
                label="Articles"
                service={() =>
                  ArticleService.getAllModelesLicences().then((res) =>
                    res.data
                      .filter(
                        (modele) =>
                          modele.classification.id ==
                          this.state.selectedLicence.classification.id
                      )
                      .map((modele, index) => ({
                        ...modele,
                        id: index,
                        stringModules: modele.defaultModules
                          .map((module) => module.nom)
                          .join(","),
                      }))
                  )
                }
                valueFieldToDisplay="stringModules"
                optionFieldToDisplay="stringModules"
                required={true}
                showClearButton={false}
                handleChange={this.handleChangeModules}
                disableOption={this.props.isInvalidModule}
              />
            </>
          }
        />
        <DialogForm
          dialogTitle={"Retrait de modules"}
          labelValidateButton="Valider"
          labelCancelButton="Annuler"
          setOpenDialog={this.setOpenDialogRemoveModules}
          onValidate={() => {
            LicencesService.removeModules(
              this.state.selectedLicence.numero,
              this.state.selectedModules.map((module) => module.aR_Ref)
            )
              .then(() => this.props.getFilteredLicences())
              .catch((error) => {
                console.log(error);
                this.setState({
                  isSubmitting: false,
                });
              });
          }}
          onClose={() => {
            this.setState({ selectedLicence: null, selectedModules: [] });
          }}
          body={
            <>
              <Input
                type="multipleSelect"
                label="Articles"
                service={() =>
                  this.state.selectedLicence.modeles.map((modele, index) => ({
                    ...modele,
                    id: index,
                    stringModules: modele.defaultModules
                      .map((module) => module.nom)
                      .join(","),
                  }))
                }
                valueFieldToDisplay="stringModules"
                optionFieldToDisplay="stringModules"
                required={true}
                showClearButton={false}
                handleChange={this.handleChangeModules}
              />
            </>
          }
        />
        <DialogForm
          dialogTitle={"Ajout de licences"}
          labelValidateButton="Valider"
          labelCancelButton="Annuler"
          fullScreen={true}
          setOpenDialog={this.setOpenDialogAddLicences}
          validation={() => this.state.nouvellesLicences.length > 0}
          onValidate={() => {
            DevisService.postDevisLicence(
              { client: this.props.client },
              this.state.nouvellesLicences,
              null
            )
              .then((res) => {
                this.props.history.push("/devis/" + res.data.id);
              })
              .catch((error) => {
                console.log(error);
                this.setState({
                  isSubmitting: false,
                });
              });
          }}
          onClose={() => {
            this.setState({ nouvellesLicences: [] });
          }}
          body={
            <>
              {this.state.nouvellesLicences.map((licenceInfo, index) => (
                <div key={index} className="py-2">
                  <Input
                    type="selectSearchDisabledValues"
                    label="article licence"
                    value={licenceInfo.article ?? ""}
                    valueFieldToDisplay="aR_Ref"
                    optionFieldToDisplay="aR_Ref"
                    required={true}
                    showClearButton={false}
                    service={ArticleService.getAllModelesLicences}
                    disableOption={(modele) =>
                      modele.classification.type.id == 2
                    }
                    handleChange={(acc, value) => {
                      this.setState(
                        produce((prevState) => {
                          return {
                            ...prevState,
                            nouvellesLicences: prevState.nouvellesLicences.map(
                              (e, i) =>
                                i == index ? { ...e, article: value } : e
                            ),
                          };
                        })
                      );
                    }}
                  />
                  <Input
                    type="decimal"
                    label="quantite"
                    value={licenceInfo.quantite ?? ""}
                    required={true}
                    showClearButton={false}
                    numberOfDecimals={0}
                    handleBlur={(acc, value) => {
                      this.setState(
                        produce((prevState) => {
                          return {
                            ...prevState,
                            nouvellesLicences: prevState.nouvellesLicences.map(
                              (e, i) =>
                                i == index ? { ...e, quantite: value } : e
                            ),
                          };
                        })
                      );
                    }}
                  />
                  <hr />
                </div>
              ))}
              <ButtonIcon
                className={"btn btn-success btn-sm ms-1"}
                style={{ width: "40px" }}
                icon={faPlus}
                tooltip="Nouvelle licence"
                onClick={() =>
                  this.setState(
                    produce((prevState) => {
                      prevState.nouvellesLicences.push({
                        modele: null,
                        quantite: null,
                      });
                    })
                  )
                }
              />
            </>
          }
        />
        {this.props.licencesDistri_SoldByClient.length > 0 ? (
          <DialogForm
            dialogTitle={
              "Transformation d'un client du distributeur en client Sol Solution"
            }
            labelValidateButton="Valider"
            labelCancelButton="Annuler"
            contextual={true}
            setOpenDialog={this.setOpenDialogSelectDistributorClient}
            onValidate={() => {
              LicencesService.changeClient(
                this.props.client.id,
                this.state.newClient?.id,
                this.state.clientFinal
              );
            }}
            onClose={() => {}}
            body={
              <>
                <Input
                  type="selectSearch"
                  label="Sélection de l'entreprise cliente du distributeur"
                  value={this.state.selectedClient ?? ""}
                  required={true}
                  showClearButton={false}
                  options={this.props.licencesDistri_SoldByClient
                    .filter((licence) => licence.active == true)
                    .map((licence) => licence.nomClientFinal)
                    .filter(
                      (nomClient, index, arr) =>
                        arr.indexOf(nomClient) === index
                    )
                    .map((nomClient) => {
                      return Object.fromEntries([["nom_Client", nomClient]]);
                    })}
                  optionFieldToDisplay={"nom_Client"}
                  valueFieldToDisplay={"nom_Client"}
                  handleChange={(accessor, value) => {
                    this.setState({ clientFinal: value });
                  }}
                />

                <Input
                  type="selectSearch"
                  label="Client existant auquel rattacher les licences"
                  value={this.state.selectedClient ?? ""}
                  required={true}
                  showClearButton={false}
                  service={
                    ClientService.getAllClientsWithCompteTierButNoLicence
                  }
                  optionFieldToDisplay={"nom_Client"}
                  valueFieldToDisplay={"nom_Client"}
                  handleChange={(accessor, value) => {
                    this.setState({ newClient: value });
                  }}
                />
              </>
            }
          />
        ) : null}

        <AlertDialog
          title="Attention : Fin de toutes les licences"
          body={
            <div>
              Vous allez supprimer toutes les licences non permanentes vendues à
              ce client. Les licences vendues par un distributeur ne sont pas
              affectées. Cette action est définitive.
            </div>
          }
          valider="Valider"
          open={this.state.openWarningEndAll}
          handleClose={this.handleCloseEndAllWarning}
          onClick={this.handleValidateEndAllWarning}
        />
      </>
    );
  }

  generateClientStatsBox() {
    const droitsDevis = App.RightsGuard?.current.hasRight("Devis", "Creation");
    const droitsSuppression = App.RightsGuard?.current.hasRight(
      "Client - Logiciel",
      "Suppression"
    );
    const droitsDateAnniv = App.RightsGuard?.current.hasRight(
      "Client - Logiciel",
      "Date anniversaire"
    );

    return (
      <Box
        header={
          <>
            <div className="col-6 align-self-center py-2">
              Informations entreprise
            </div>
            {this.props.client.compte_Tier != "CTEST" &&
            !this.props?.licences.some((e) => e.impayee) ? (
              <div className="col-6 text-end py-2">
                <ButtonIcon
                  icon={faPen}
                  className={"btn btn-success btn-sm ms-1"}
                  style={
                    this.props.editing ? { display: "none" } : { width: "40px" }
                  }
                  tooltip="Editer des informations"
                  onClick={() => {
                    this.props.handleEditing(true);
                    this.setState({
                      editingInfoEntreprise: true,
                      savedClient: this.props.client,
                    });
                  }}
                />
                <ButtonIcon
                  icon={faCheck}
                  className={"btn btn-success btn-sm ms-1"}
                  style={
                    !this.state.editingInfoEntreprise
                      ? { display: "none" }
                      : { width: "40px" }
                  }
                  tooltip="Editer des informations"
                  onClick={() => {
                    this.props.handleUpdate();
                    this.props.handleEditing(false);
                    this.setState({ editingInfoEntreprise: false });
                  }}
                />
                <ButtonIcon
                  icon={faTimes}
                  className={"btn btn-danger btn-sm ms-1"}
                  style={
                    !this.state.editingInfoEntreprise
                      ? { display: "none" }
                      : { width: "40px" }
                  }
                  tooltip="Editer des informations"
                  onClick={() => {
                    this.props.handleEditing(false);
                    this.setState({ editingInfoEntreprise: false });
                    this.props.handleChange("client", this.state.savedClient);
                  }}
                />
                {droitsDevis == "RW" ? (
                  <ButtonIcon
                    className={"btn btn-success btn-sm ms-1"}
                    style={{ width: "40px" }}
                    icon={faFileInvoiceDollar}
                    tooltip="Créer un nouveau devis de licences"
                    onClick={this.state.openDialogAddLicences}
                  />
                ) : null}
                {/* Bouton de 'transfert' d'un client d'un distributeur vers Sol
                Solution */}
                {this.props.licencesDistri_SoldByClient.length > 0 ? (
                  <ButtonIcon
                    icon={faFileInvoiceDollar}
                    className={"btn btn-warning btn-sm ms-1"}
                    style={{
                      width: "40px",
                    }}
                    tooltip="Transférer un client du distributeur chez Sol Solution"
                    onClick={this.state.openDialogSelectDistributorClient}
                  />
                ) : null}
                {this.props.client.etat.qualification != "Distributeur" &&
                droitsSuppression == "RW" ? (
                  <ButtonIcon
                    className={"btn btn-danger btn-sm ms-1"}
                    style={{ width: "40px" }}
                    icon={faExclamationTriangle}
                    tooltip="Suprimer toutes les licences accordées à l'entreprise"
                    onClick={this.handleClickEndAll}
                  />
                ) : null}
              </div>
            ) : null}
          </>
        }
        body={
          <>
            <div className="col-6 align-self-center py-2">
              <DataFieldColumn
                label="Compte Tiers"
                value={this.props.client.compte_Tier}
              />
            </div>
            <div className="col-6 align-self-center py-2">
              <DataFieldColumn label="ID Bamboo" value={this.props.client.id} />
            </div>
            <div className="col-6 align-self-center py-2">
              {this.state.editingInfoEntreprise &&
              droitsDateAnniv == "RW" &&
              !this.props?.licences.some((e) => e.impayee) ? (
                <Input
                  label="Date anniversaire abonnement"
                  type="date"
                  value={this.props.client.dateAnniversaireLicences}
                  handleChange={(acc, value) => {
                    this.props.handleChange("dateAnniversaireLicences", value);
                  }}
                />
              ) : (
                <DataFieldColumn
                  label="Date anniversaire abonnement"
                  value={
                    ToLocaleDateString(
                      this.props.client.dateAnniversaireLicences
                    ) ?? "-"
                  }
                />
              )}

              <DataFieldColumn
                label="Nombre de licences WebSprint"
                value={this.props.statsClient?.quantiteGeosprint}
              />
              <DataFieldColumn
                label="Nombre de licences Geosprint"
                value={this.props.statsClient?.quantiteWebsprint}
              />
              <DataFieldColumn
                label="Nombre de licences affectées"
                value={
                  this.props.statsClient?.quantiteAffectee +
                  " / " +
                  (this.props.statsClient?.quantiteGeosprint +
                    this.props.statsClient?.quantiteWebsprint)
                }
              />
            </div>
            <div className="col-6 py-2">
              <DataFieldColumn
                label="Commentaire"
                value={this.props.statsClient?.commentaireWebsprint}
                functionAppliedToValue={(text) => (
                  <div className="keepWhiteSpace">{text}</div>
                )}
              />
            </div>
          </>
        }
      />
    );
  }

  generateFilterBox() {
    return (
      <Box
        header={
          <>
            <div className="col-12 align-self-center py-2 d-flex flex-row justify-content-between">
              Filtres
            </div>
          </>
        }
        body={
          <>
            <div className="col-2">
              <Input
                type="checkbox"
                label="En cours"
                value={this.props.filters.active}
                accessor="filtre.active"
                handleChange={this.props.handleChange}
              />
            </div>
            <div className="col">
              <Input
                type="selectSearch"
                label="Catégorie de logiciel"
                value={this.props.filters.categorieLogiciel ?? ""}
                service={TypesLicenceService.getAllCategories}
                valueFieldToDisplay={"designation"}
                optionFieldToDisplay={"designation"}
                accessor="filtre.categorieLogiciel"
                handleChange={this.props.handleChange}
              />
            </div>
            <div className="col">
              <Input
                type="selectSearch"
                label="Affectée"
                value={this.props.filters.withUser ?? ""}
                valueFieldToDisplay={"display"}
                optionFieldToDisplay={"display"}
                optionFieldToReturn={"value"}
                options={[
                  {
                    id: 1,
                    display: "Oui",
                    value: true,
                  },
                  {
                    id: 2,
                    display: "Non",
                    value: false,
                  },
                ]}
                accessor="filtre.withUser"
                handleChange={this.props.handleChange}
              />
            </div>
            <div className="col">
              <Input
                type="selectSearch"
                label="Type"
                accessor="filtre.type"
                value={this.props.filters.type}
                service={TypesLicenceService.getAllTypes}
                valueFieldToDisplay="designation"
                optionFieldToDisplay="designation"
                handleChange={this.props.handleChange}
              />
            </div>
          </>
        }
        rowClass="px-2"
      />
    );
  }

  generateClientLicences() {
    return (
      <>
        <p className="bg-success text-center fw-bold text-white rounded solwayFont">
          Licences du client
        </p>
        {this.props.licences.map((licence) => (
          <BoxLicence
            key={licence.numero}
            id={licence.numero}
            licence={licence}
            history={this.props.history}
            actionsAvailable={true}
            handleClickAddModules={() => {
              this.setState({ selectedLicence: licence });
              this.state.openDialogAddModules();
            }}
            handleClickAddUserLogiciels={() => {
              this.setState({ selectedLicence: licence });
              this.state.openDialogSelectContact();
            }}
            handleClickRemoveUserLogiciels={() => {
              this.props.handleClickRemoveUserLogiciels(licence.numero);
            }}
            handleClickRemoveModules={() => {
              this.setState({ selectedLicence: licence });
              this.state.openDialogRemoveModules();
            }}
            handleUpdateLicence={this.props.handleUpdateLicence}
            handleClickSuspend={this.props.handleSuspend}
          />
        ))}
      </>
    );
  }

  generateDistributorLicences() {
    return (
      <>
        <p className="bg-info text-center fw-bold text-white rounded solwayFont">
          Licences vendues par le distributeur
        </p>
        {this.props.licencesDistri_SoldByClient.map((licence) => (
          <BoxLicence
            key={licence.numero}
            id={licence.numero}
            licence={licence}
            history={this.props.history}
            actionsAvailable={false}
            handleClickAddModules={() => {
              this.setState({ selectedLicence: licence });
              this.state.openDialogAddModules();
            }}
            handleClickAddUserLogiciels={() => {
              this.setState({ selectedLicence: licence });
              this.state.openDialogSelectContact();
            }}
            handleClickRemoveUserLogiciels={() => {
              this.props.handleClickRemoveUserLogiciels(licence.numero);
            }}
            handleClickRemoveModules={() => {
              this.setState({ selectedLicence: licence });
              this.state.openDialogRemoveModules();
            }}
            handleUpdateLicence={this.props.handleUpdateLicence}
            handleClickSuspend={this.props.handleSuspend}
          />
        ))}
      </>
    );
  }

  generateSoldToLicence() {
    return (
      <>
        <p className="bg-secondary text-center fw-bold text-white rounded solwayFont">
          Licences vendues au client par un distributeur
        </p>
        {this.props.licencesDistri_SoldToClient.map((licence) => (
          <BoxLicence
            key={licence.numero}
            id={licence.numero}
            licence={licence}
            history={this.props.history}
            actionsAvailable={false}
            handleClickAddModules={() => {
              this.setState({ selectedLicence: licence });
              this.state.openDialogAddModules();
            }}
            handleClickAddUserLogiciels={() => {
              this.setState({ selectedLicence: licence });
              this.state.openDialogSelectContact();
            }}
            handleClickRemoveUserLogiciels={() => {
              this.props.handleClickRemoveUserLogiciels(licence.numero);
            }}
            handleClickRemoveModules={() => {
              this.setState({ selectedLicence: licence });
              this.state.openDialogRemoveModules();
            }}
            handleUpdateLicence={this.props.handleUpdateLicence}
            handleClickSuspend={this.props.handleSuspend}
          />
        ))}
      </>
    );
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight("Client", "Logiciel");

    if (right == "RW" || right == "RO") {
      return this.props.licences ? (
        <>
          {this.generateDialogForm()}
          {this.generateClientStatsBox()}
          {this.generateFilterBox()}
          {this.props.licences.length > 0
            ? this.generateClientLicences()
            : null}
          {this.props.licences.length > 0 &&
          this.props.licencesDistri_SoldByClient.length > 0 ? (
            <hr />
          ) : null}
          {this.props.licencesDistri_SoldByClient.length > 0
            ? this.generateDistributorLicences()
            : null}
          {(this.props.licences.length > 0 ||
            this.props.licencesDistri_SoldByClient.length > 0) &&
          this.props.licencesDistri_SoldToClient.length > 0 ? (
            <hr />
          ) : null}
          {this.props.licencesDistri_SoldToClient.length > 0
            ? this.generateSoldToLicence()
            : null}
        </>
      ) : null;
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

export { Logiciels };
