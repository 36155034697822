import React from "react";
import "./SubTab/Calculette.css";
import { App } from "App";

import { Input } from "_components/Input";

import { ButtonIcon, ButtonIconWithValidation, WarningBar } from "_components";

import {
  faCheck,
  faTimes,
  faPen,
  faFileExport,
  faFileImport,
} from "@fortawesome/free-solid-svg-icons";
import { CalculetteModeleService } from "_services";

class Calculette extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      savedDevis: null,
    };

    this.input = this.input.bind(this);
    this.emptyCell = this.emptyCell.bind(this);
    this.renderCalculette = this.renderCalculette.bind(this);
    this.handleClickEdition = this.handleClickEdition.bind(this);
    this.handleClickUndo = this.handleClickUndo.bind(this);
    this.handleClickValidate = this.handleClickValidate.bind(this);
    this.renderEntete = this.renderEntete.bind(this);
    this.renderAutre = this.renderAutre.bind(this);
    this.renderDepla = this.renderDepla.bind(this);
    this.renderHeadCalculette = this.renderHeadCalculette.bind(this);
    this.renderHypotheses = this.renderHypotheses.bind(this);
    this.renderMat = this.renderMat.bind(this);
    this.renderPersonnel = this.renderPersonnel.bind(this);
    this.renderSousTotal = this.renderSousTotal.bind(this);
    this.renderPrime = this.renderPrime.bind(this);
    this.handleExportModele = this.handleExportModele.bind(this);
    this.handleImportModele = this.handleImportModele.bind(this);
    this.getAllModele = this.getAllModele.bind(this);
    this.getColor = this.getColor.bind(this);
    this.disableCoefColonne = this.disableCoefColonne.bind(this);
    this.disableCoefLigne = this.disableCoefLigne.bind(this);
  }

  input({ accessor, disabled = false, numberOfDecimals }) {
    return (
      <td className="input">
        <Input
          value={this.props.devis.calculette[accessor]}
          ignoredValues={[0]}
          numberOfDecimals={numberOfDecimals}
          handleBlur={this.props.handleChange}
          type="decimal"
          accessor={"calculette." + accessor}
          showClearButton={false}
          showValidator={false}
          disabled={disabled || !this.props.editing}
        ></Input>
      </td>
    );
  }

  emptyCell() {
    return <td className="empty"></td>;
  }

  formatValue(value) {
    return value && value != 0 ? value : "";
  }

  handleClickEdition() {
    this.setState({
      savedDevis: { ...this.props.devis },
    });
    this.props.handleEditing(true);
  }

  handleClickUndo() {
    this.props.handleChange(
      this.props.stateFieldNameToUpdate,
      this.state.savedDevis
    );
    this.props.handleEditing(false);
  }

  handleClickValidate() {
    this.props.handleUpdate();
    this.props.handleEditing(false);
  }

  handleExportModele() {
    let devisModele = {
      ...this.props.devis.calculette,

      nom: this.state.modeleNameToExport ? this.state.modeleNameToExport : "",
    };
    CalculetteModeleService.post(devisModele);

    this.setState((prevState) => ({
      ...prevState,
      modeleNameToExport: null,
      modelePoleToExport: this.props.devis.calculette.type,
    }));
  }

  disableCoefLigne() {
    let cal = this.props.devis.calculette;
    if (
      cal?.total_Rapport_CoefMulti != 0 ||
      cal?.total_Labo_CoefMulti != 0 ||
      cal?.total_Mesures_CoefMulti != 0 ||
      cal?.total_AmeneRepli_CoefMulti != 0 ||
      cal?.total_Preparation_CoefMulti != 0 ||
      cal?.total_Devis_CoefMulti != 0 ||
      cal?.modeCalcul == "Logique 3 : Prix de vente" ||
      cal?.modeCalcul == "Logique 1 : Accord cadre"
    )
      return true;
    return false;
  }

  disableCoefColonne() {
    let cal = this.props.devis.calculette;

    if (
      cal.personnel_ResponsableService_CoefMulti != 0 ||
      cal.personnel_ResponsablePole_CoefMulti != 0 ||
      cal.personnel_ChargeDAffaire_CoefMulti != 0 ||
      cal.personnel_Techniciens_CoefMulti != 0 ||
      cal.personnel_Assistante_CoefMulti != 0 ||
      cal.personnel_HeuresDeChantierDeNuit_CoefMulti != 0 ||
      cal.personnel_HeuresDeChantierDeJour_CoefMulti != 0 ||
      cal.personnel_HeuresDimanche_CoefMulti != 0 ||
      cal.personnel_HeuresSupp_CoefMulti != 0 ||
      cal.personnel_HeuresFeriees_CoefMulti != 0 ||
      cal.prime_MajorationHeuresChantierNuit_CoefMulti != 0 ||
      cal.prime_PrimeDeNuit_CoefMulti != 0 ||
      cal.prime_PrimeDeDeplacement_CoefMulti != 0 ||
      cal.prime_PrimeDeDepla4j_CoefMulti != 0 ||
      cal.prime_PrimeDeDepla5j_CoefMulti != 0 ||
      cal.prime_RecupHeureDeNuit_CoefMulti != 0 ||
      cal.prime_MajorationHeuresSupp_CoefMulti != 0 ||
      cal.prime_MajorationHeuresDimanche_CoefMulti != 0 ||
      cal.prime_Weekend_CoefMulti != 0 ||
      cal.prime_MajorationHeuresFeriee_CoefMulti != 0 ||
      cal.deplacements_Essence_CoefMulti != 0 ||
      cal.deplacements_ForfaitNuit_CoefMulti != 0 ||
      cal.deplacements_ForfaitRestaurant_CoefMulti != 0 ||
      cal.deplacements_VehiculeFraisFixesParJour_CoefMulti != 0 ||
      cal.deplacements_Peages_CoefMulti != 0 ||
      cal.deplacements_TrainAvion_CoefMulti != 0 ||
      cal.materiels_Consommables_CoefMulti != 0 ||
      cal.materiels_MaterielIndustriel_A_CoefMulti != 0 ||
      cal.materiels_MaterielIndustriel_B_CoefMulti != 0 ||
      cal.materiels_SousTraitanceOuLocation_CoefMulti != 0 ||
      cal.materiels_SystemeDInformations_CoefMulti != 0 ||
      cal.autres_A_CoefMulti != 0 ||
      cal.autres_B_CoefMulti != 0 ||
      cal.autres_C_CoefMulti != 0 ||
      cal.autres_D_CoefMulti != 0 ||
      cal.autres_E_CoefMulti != 0 ||
      cal?.modeCalcul == "Logique 3 : Prix de vente" ||
      cal?.modeCalcul == "Logique 1 : Accord cadre"
    )
      return true;
    return false;
  }

  handleImportModele() {
    CalculetteModeleService.getById(this.state.modelToImport.id).then(
      (modelToImport) => {
        this.props.importCalculetteModele(modelToImport.data);

        this.setState((prevState) => ({
          ...prevState,
          modelToImport: null,
        }));
      }
    );
  }

  getAllModele(search) {
    return CalculetteModeleService.getAll({
      size: 25,
      nom: search,
    });
  }

  getColor(value) {
    let cal = this.props.devis.calculette;
    let couleur = cal.couleur;
    if (value > couleur?.borne5) {
      return couleur?.borne5Couleur;
    } else if (value > couleur?.borne4) {
      return couleur?.borne4Couleur;
    } else if (value > couleur?.borne3) {
      return couleur?.borne3Couleur;
    } else if (value > couleur?.borne2) {
      return couleur?.borne2Couleur;
    } else if (value > couleur?.borne1) {
      return couleur?.borne1Couleur;
    } else {
      return "red";
    }
  }

  renderEntete() {
    const right = App.RightsGuard?.current?.hasRight("Devis", "Calculette");

    return (
      <div className="d-flex justify-content-end" style={{ marginTop: "30px" }}>
        <ButtonIcon
          icon={faCheck}
          className="btn btn-success text-light ms-1"
          style={!this.props.editing ? { display: "none" } : { width: "42px" }}
          onClick={this.handleClickValidate}
        ></ButtonIcon>
        <ButtonIcon
          icon={faTimes}
          className="btn btn-danger text-light ms-1"
          onClick={this.handleClickUndo}
          style={!this.props.editing ? { display: "none" } : { width: "42px" }}
        ></ButtonIcon>
        <ButtonIcon
          icon={faPen}
          className="btn btn-secondary text-light ms-1"
          onClick={this.handleClickEdition}
          style={
            this.props.editing ||
            right != "RW" ||
            !this.props.devis.canUpdateCalculette
              ? { display: "none" }
              : { width: "42px" }
          }
        ></ButtonIcon>
      </div>
    );
  }

  renderHypotheses() {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "30px",
          }}
        >
          <ButtonIconWithValidation
            icon={faFileImport}
            className="btn btn-secondary text-light ms-1"
            textOption="Importer depuis modèle"
            alertTitle="Importer depuis modèle"
            alertButtonValidationText="Importer"
            style={!this.props.editing ? { display: "none" } : {}}
            onClick={this.handleImportModele}
            onClose={() =>
              this.setState((prevState) => ({
                ...prevState,
                modelToImport: null,
              }))
            }
            alertBody={
              <div id="AlerteDevisDetails">
                <Input
                  id="ListeImport"
                  label="Modèle à importer"
                  type="selectSearch"
                  value={null}
                  service={(query) =>
                    CalculetteModeleService.getAllGrouped({
                      produitInterne: this.props.devis.calculette?.type,
                      nom: query,
                    })
                  }
                  optionFieldToDisplay="nom"
                  valueFieldToDisplay="nom"
                  handleChange={(accessor, value) =>
                    this.setState((prevState) => ({
                      ...prevState,
                      modelToImport: value,
                    }))
                  }
                  functionAppliedToGroupByName={(option) =>
                    "".concat(option.type)
                  }
                ></Input>
              </div>
            }
          ></ButtonIconWithValidation>
          <ButtonIconWithValidation
            icon={faFileExport}
            className="btn btn-secondary text-light ms-1"
            textOption="Exporter vers modèle"
            alertTitle="Exporter vers modèle"
            alertButtonValidationText="Exporter"
            style={!this.props.editing ? { display: "none" } : {}}
            onClick={this.handleExportModele}
            onClose={() =>
              this.setState((prevState) => ({
                ...prevState,
                modeleNameToExport: null,
                modelePoleToExport: this.props.devis.produit_Interne,
              }))
            }
            alertBody={
              <div id="AlerteDevisDetails">
                <Input
                  label="Nom du modèle"
                  type="selectSearch"
                  value={this.state.modeleNameToExport}
                  service={CalculetteModeleService.getAll}
                  optionFieldToDisplay={["type", "nom"]}
                  valueFieldToDisplay={["type", "nom"]}
                  optionFieldToReturn="nom"
                  handleChange={(accessor, value) =>
                    this.setState((prevState) => ({
                      ...prevState,
                      modeleNameToExport: value,
                    }))
                  }
                  customFirstOption={(query) => ({
                    name: "* Ajouter le modèle : ".concat(query),
                    option: { nom: query },
                  })}
                ></Input>
              </div>
            }
          ></ButtonIconWithValidation>
        </div>
        <div className="d-flex" style={{ gap: "20px", marginBottom: "15px" }}>
          <div style={{ width: "20%" }}>
            <Input
              value={{
                id: -1,
                designation: this.props.devis?.calculette?.modeCalcul,
              }}
              type="selectSearch"
              options={[
                {
                  id: 0,
                  designation: "Logique 1 : Accord cadre",
                },
                {
                  id: 1,
                  designation: "Logique 2 : Prix de revient",
                },
                {
                  id: 2,
                  designation: "Logique 3 : Prix de vente",
                },
              ]}
              optionFieldToReturn="designation"
              optionFieldToDisplay="designation"
              valueFieldToDisplay="designation"
              label="Logique de calcul"
              accessor="calculette.modeCalcul"
              handleChange={this.props.handleChange}
              disabled={!this.props.editing}
            />
          </div>
          <div style={{ width: "20%" }}>
            {this.props.devis?.calculette?.modeCalcul ==
            "Logique 1 : Accord cadre" ? (
              <Input
                type="selectSearch"
                options={[
                  {
                    id: 0,
                    designation: "Jour / Nuit",
                  },
                  {
                    id: 1,
                    designation: "Km",
                  },
                ]}
                optionFieldToReturn="designation"
                optionFieldToDisplay="designation"
                valueFieldToDisplay="designation"
                label="Calcul du PV"
                accessor="calculette.financier_ModeCalcul"
                handleChange={this.props.handleChange}
                value={{
                  id: -1,
                  designation:
                    this.props.devis?.calculette?.financier_ModeCalcul,
                }}
                disabled={!this.props.editing}
              />
            ) : null}
          </div>
        </div>
        <div className="d-flex " style={{ gap: "10px" }}>
          <div>
            <table className="General Calculette">
              <thead>
                <tr>
                  <td className="label">Nombre de techniciens sur chantier</td>
                  {this.input({
                    accessor: "general_NombreDeTechnicienSurChantier",
                  })}
                </tr>
                <tr>
                  <td className="label">
                    Nombre de chargés d'affaires sur chantier
                  </td>
                  {this.input({
                    accessor: "general_NombreDeChargeAffaireSurChantier",
                  })}
                </tr>
                <tr>
                  <td className="label">Nombre de jours de chantier</td>
                  {this.input({
                    accessor: "general_NombreDeJoursDeChantier",
                  })}
                </tr>
                <tr>
                  <td className="label">Nombre de nuits de chantier</td>
                  {this.input({
                    accessor: "general_NombreDeNuitsDeChantier",
                  })}
                </tr>
                <tr>
                  <td className="label">Nombre de nuits d'hôtel techniciens</td>
                  {this.input({
                    accessor: "general_NombreDeJoursDeDeplacements_Tech",
                  })}
                </tr>

                <tr>
                  <td className="label">
                    Nombre de nuits d'hôtel chargé affaire
                  </td>
                  {this.input({
                    accessor:
                      "general_NombreDeJoursDeDeplacements_ChargeAffaire",
                  })}
                </tr>
                <tr>
                  <td className="label">Nombre total de km AR parcourus</td>
                  {this.input({
                    accessor: "general_NombreDeKmARParcourus",
                  })}
                </tr>
                <tr>
                  <td className="label">Nombre de km auscultés terrain</td>
                  {this.input({
                    accessor: "general_NombreDeKmAuscultesParcourus",
                  })}
                </tr>
                <tr>
                  <td className="label">Nombre de km à traiter bureau</td>
                  {this.input({
                    accessor: "general_NombreDeKmATraiterBureau",
                  })}
                </tr>
                <tr>
                  <td className="label">Nombre de sondages Pandoscope</td>
                  {this.input({
                    accessor: "general_NombreSondagePandoscope",
                  })}
                </tr>
              </thead>
            </table>
          </div>
          <div>
            <table className="General Financier Calculette">
              <thead>
                <tr>
                  <td className="label">Prix jour</td>
                  {this.input({
                    accessor: "financier_PrixJour",
                  })}
                </tr>
                <tr>
                  <td className="label">Prix nuit</td>
                  {this.input({
                    accessor: "financier_PrixNuit",
                  })}
                </tr>
                <tr>
                  <td className="label">Prix au mètre</td>
                  {this.input({
                    accessor: "financier_PrixMetre",
                  })}
                </tr>
                <tr>
                  <td className="label">Temps Rapport / nb km</td>
                  {this.input({
                    accessor: "financier_TempsRapport_Ou_NbKmTraiterBureau",
                  })}
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </>
    );
  }

  renderHeadCalculette() {
    return (
      <>
        <tr>
          <th colSpan="3"></th>
          <th colSpan="11">Mission</th>
        </tr>
        <tr className="sticky-top">
          <th></th>
          <th>Coût unitaire</th>
          <th>Unité d'oeuvre</th>
          <th>Devis</th>
          <th>Préparation</th>
          <th>Amené/Repli</th>
          <th>Mesures</th>
          <th>Labo</th>
          <th>Rapport</th>
          <th>Total</th>
          <th>Total coûts</th>
          <th>% coûts</th>
          <th>Coef multiplicateur</th>
          <th>€ marge</th>
        </tr>
      </>
    );
  }

  renderPersonnel() {
    let cal = this.props.devis.calculette;
    let disableLigne = this.disableCoefLigne();
    return (
      <>
        <tr>
          <th>Personnel</th>
          <th colSpan="8"></th>
          {this.input({ accessor: "personnel_Total", disabled: true })}
          {this.input({
            accessor: "personnel_TotalCouts",
            disabled: true,
          })}
          {this.input({
            accessor: "personnel_PartCouts",
            disabled: true,
          })}
          {this.input({
            accessor: "personnel_CoefMulti",
            disabled: disableLigne,
          })}
          {this.emptyCell()}
        </tr>
        <tr>
          <td className="label">Responsable Service</td>
          {this.emptyCell()}

          <td className="unite">{cal?.personnel_ResponsableService_Unite}</td>
          {this.input({ accessor: "personnel_ResponsableService_Devis" })}
          {this.input({
            accessor: "personnel_ResponsableService_Preparation",
          })}
          {this.input({
            accessor: "personnel_ResponsableService_AmeneRepli",
          })}
          {this.input({
            accessor: "personnel_ResponsableService_MesuresOuFormation",
          })}
          {this.input({ accessor: "personnel_ResponsableService_Labo" })}
          {this.input({
            accessor: "personnel_ResponsableService_RapportOuAdministratif",
          })}
          {this.input({
            accessor: "personnel_ResponsableService_Total",
            disabled: true,
          })}
          {this.input({
            accessor: "personnel_ResponsableService_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "personnel_ResponsableService_CoefMulti",
            disabled: disableLigne,
          })}
          {this.input({
            accessor: "personnel_ResponsableService_Marge",
            disabled: true,
          })}
        </tr>
        <tr>
          <td className="label">Responsable Pôle</td>
          {this.emptyCell()}

          <td className="unite">{cal?.personnel_ResponsablePole_Unite}</td>
          {this.input({ accessor: "personnel_ResponsablePole_Devis" })}
          {this.input({
            accessor: "personnel_ResponsablePole_Preparation",
          })}
          {this.input({
            accessor: "personnel_ResponsablePole_AmeneRepli",
          })}
          {this.input({
            accessor: "personnel_ResponsablePole_MesuresOuFormation",
          })}
          {this.input({ accessor: "personnel_ResponsablePole_Labo" })}
          {this.input({
            accessor: "personnel_ResponsablePole_RapportOuAdministratif",
          })}
          {this.input({
            accessor: "personnel_ResponsablePole_Total",
            disabled: true,
          })}
          {this.input({
            accessor: "personnel_ResponsablePole_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "personnel_ResponsablePole_CoefMulti",
            disabled: disableLigne,
          })}
          {this.input({
            accessor: "personnel_ResponsablePole_Marge",
            disabled: true,
          })}
        </tr>

        <tr>
          <td className="label">Chargé d'affaire</td>
          {this.emptyCell()}

          <td className="unite">{cal?.personnel_ChargeDAffaire_Unite}</td>
          {this.input({ accessor: "personnel_ChargeDAffaire_Devis" })}
          {this.input({
            accessor: "personnel_ChargeDAffaire_Preparation",
          })}
          {this.input({
            accessor: "personnel_ChargeDAffaire_AmeneRepli",
          })}
          {this.input({
            accessor: "personnel_ChargeDAffaire_MesuresOuFormation",
          })}
          {this.input({ accessor: "personnel_ChargeDAffaire_Labo" })}
          {this.input({
            accessor: "personnel_ChargeDAffaire_RapportOuAdministratif",
          })}
          {this.input({
            accessor: "personnel_ChargeDAffaire_Total",
            disabled: true,
          })}
          {this.input({
            accessor: "personnel_ChargeDAffaire_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "personnel_ChargeDAffaire_CoefMulti",
            disabled: disableLigne,
          })}
          {this.input({
            accessor: "personnel_ChargeDAffaire_Marge",
            disabled: true,
          })}
        </tr>
        <tr>
          <td className="label">Techniciens</td>
          {this.emptyCell()}

          <td className="unite">{cal?.personnel_Techniciens_Unite}</td>
          {this.input({ accessor: "personnel_Techniciens_Devis" })}
          {this.input({
            accessor: "personnel_Techniciens_Preparation",
          })}
          {this.input({
            accessor: "personnel_Techniciens_AmeneRepli",
          })}
          {this.input({
            accessor: "personnel_Techniciens_MesuresOuFormation",
          })}
          {this.input({ accessor: "personnel_Techniciens_Labo" })}
          {this.input({
            accessor: "personnel_Techniciens_RapportOuAdministratif",
          })}
          {this.input({
            accessor: "personnel_Techniciens_Total",
            disabled: true,
          })}
          {this.input({
            accessor: "personnel_Techniciens_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "personnel_Techniciens_CoefMulti",
            disabled: disableLigne,
          })}
          {this.input({
            accessor: "personnel_Techniciens_Marge",
            disabled: true,
          })}
        </tr>
        <tr>
          <td className="label">Assistante</td>
          {this.emptyCell()}

          <td className="unite">{cal?.personnel_Assistante_Unite}</td>
          {this.input({ accessor: "personnel_Assistante_Devis" })}
          {this.input({
            accessor: "personnel_Assistante_Preparation",
          })}
          {this.input({
            accessor: "personnel_Assistante_AmeneRepli",
          })}
          {this.input({
            accessor: "personnel_Assistante_MesuresOuFormation",
          })}
          {this.input({ accessor: "personnel_Assistante_Labo" })}
          {this.input({
            accessor: "personnel_Assistante_RapportOuAdministratif",
          })}
          {this.input({
            accessor: "personnel_Assistante_Total",
            disabled: true,
          })}
          {this.input({
            accessor: "personnel_Assistante_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "personnel_Assistante_CoefMulti",
            disabled: disableLigne,
          })}
          {this.input({
            accessor: "personnel_Assistante_Marge",
            disabled: true,
          })}
        </tr>
        <tr>
          <td className="label paddingLeft">dont Heures de chantier de jour</td>
          {this.emptyCell()}

          <td className="unite">
            {cal?.personnel_HeuresDeChantierDeJour_Unite}
          </td>
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "personnel_HeuresDeChantierDeJour",
            // disabled: !(
            //   cal.general_NombreDeJoursDeChantier > 0 &&
            //   cal.general_NombreDeNuitsDeChantier > 0
            // ),
          })}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "personnel_HeuresDeChantierDeJour_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "personnel_HeuresDeChantierDeJour_CoefMulti",
            disabled: true,
          })}
          {this.input({
            accessor: "personnel_HeuresDeChantierDeJour_Marge",
            disabled: true,
          })}
        </tr>
        <tr>
          <td className="label paddingLeft">dont Heures de chantier de nuit</td>
          {this.emptyCell()}

          <td className="unite">
            {cal?.personnel_HeuresDeChantierDeNuit_Unite}
          </td>
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "personnel_HeuresDeChantierDeNuit",
            // disabled: !(
            //   cal.general_NombreDeJoursDeChantier > 0 &&
            //   cal.general_NombreDeNuitsDeChantier > 0
            // ),
          })}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "personnel_HeuresDeChantierDeNuit_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "personnel_HeuresDeChantierDeNuit_CoefMulti",
            disabled: true,
          })}
          {this.input({
            accessor: "personnel_HeuresDeChantierDeNuit_Marge",
            disabled: true,
          })}
        </tr>
        <tr>
          <td className="label paddingLeft">dont heure de dimanche</td>
          {this.emptyCell()}

          <td className="unite">{cal?.personnel_HeuresDimanche_Unite}</td>
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "personnel_HeuresDimanche",
          })}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "personnel_HeuresDimanche_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "personnel_HeuresDimanche_CoefMulti",
            disabled: true,
          })}
          {this.input({
            accessor: "personnel_HeuresDimanche_Marge",
            disabled: true,
          })}
        </tr>
        <tr>
          <td className="label paddingLeft">dont heures supp</td>
          {this.emptyCell()}

          <td className="unite">{cal?.personnel_HeuresSupp_Unite}</td>
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "personnel_HeuresSupp",
          })}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "personnel_HeuresSupp_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "personnel_HeuresSupp_CoefMulti",
            disabled: true,
          })}
          {this.input({
            accessor: "personnel_HeuresSupp_Marge",
            disabled: true,
          })}
        </tr>
        <tr>
          <td className="label paddingLeft">dont heures férié</td>
          {this.emptyCell()}

          <td className="unite">{cal?.personnel_HeuresFeriees_Unite}</td>
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "personnel_HeuresFeriees",
          })}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "personnel_HeuresFeriees_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "personnel_HeuresFeriees_CoefMulti",
            disabled: true,
          })}
          {this.input({
            accessor: "personnel_HeuresFeriees_Marge",
            disabled: true,
          })}
        </tr>
      </>
    );
  }

  renderPrime() {
    let cal = this.props.devis.calculette;
    let disableLigne = this.disableCoefLigne();

    return (
      <>
        <tr>
          <th>Prime</th>
          <th colSpan="8"></th>

          {this.input({ accessor: "prime_Total", disabled: true })}
          {this.input({
            accessor: "prime_TotalCouts",
            disabled: true,
          })}
          {this.input({
            accessor: "prime_PartCouts",
            disabled: true,
          })}
          {this.input({
            accessor: "prime_CoefMulti",
            disabled: disableLigne,
          })}
          {this.emptyCell()}
        </tr>
        <tr>
          <td className="label">Majoration Heures chantier de nuit</td>
          {this.emptyCell()}

          <td className="unite">
            {cal?.prime_MajorationHeuresChantierNuit_Unite}
          </td>
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "prime_MajorationHeuresChantierNuit",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "prime_MajorationHeuresChantierNuit_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "prime_MajorationHeuresChantierNuit_CoefMulti",
            disabled: disableLigne,
          })}
          {this.input({
            accessor: "prime_MajorationHeuresChantierNuit_Marge",
            disabled: true,
          })}
        </tr>
        <tr>
          <td className="label">Prime de nuit</td>
          {this.emptyCell()}

          <td className="unite">{cal?.prime_PrimeDeNuit_Unite}</td>
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "prime_PrimeDeNuit",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "prime_PrimeDeNuit_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "prime_PrimeDeNuit_CoefMulti",
            disabled: disableLigne,
          })}
          {this.input({
            accessor: "prime_PrimeDeNuit_Marge",
            disabled: true,
          })}
        </tr>
        <tr>
          <td className="label">Prime dépla</td>
          {this.emptyCell()}

          <td className="unite">{cal?.prime_PrimeDeDeplacement_Unite}</td>
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "prime_PrimeDeDeplacement",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "prime_PrimeDeDeplacement_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "prime_PrimeDeDeplacement_CoefMulti",
            disabled: disableLigne,
          })}
          {this.input({
            accessor: "prime_PrimeDeDeplacement_Marge",
            disabled: true,
          })}
        </tr>
        <tr>
          <td className="label">Prime dépla4</td>
          {this.emptyCell()}

          <td className="unite">{cal?.prime_PrimeDeDepla4j_Unite}</td>
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "prime_PrimeDeDepla4j",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "prime_PrimeDeDepla4j_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "prime_PrimeDeDepla4j_CoefMulti",
            disabled: disableLigne,
          })}
          {this.input({
            accessor: "prime_PrimeDeDepla4j_Marge",
            disabled: true,
          })}
        </tr>
        <tr>
          <td className="label">Prime dépla5</td>
          {this.emptyCell()}

          <td className="unite">{cal?.prime_PrimeDeDepla5j_Unite}</td>
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "prime_PrimeDeDepla5j",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "prime_PrimeDeDepla5j_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "prime_PrimeDeDepla5j_CoefMulti",
            disabled: disableLigne,
          })}
          {this.input({
            accessor: "prime_PrimeDeDepla5j_Marge",
            disabled: true,
          })}
        </tr>
        <tr>
          <td className="label">Recup heure de nuit</td>
          {this.emptyCell()}

          <td className="unite">{cal?.prime_RecupHeureDeNuit_Unite}</td>
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "prime_RecupHeureDeNuit",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "prime_RecupHeureDeNuit_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "prime_RecupHeureDeNuit_CoefMulti",
            disabled: disableLigne,
          })}
          {this.input({
            accessor: "prime_RecupHeureDeNuit_Marge",
            disabled: true,
          })}
        </tr>
        <tr>
          <td className="label">Majoration heure supp</td>
          {this.emptyCell()}

          <td className="unite">{cal?.prime_MajorationHeuresSupp_Unite}</td>
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "prime_MajorationHeuresSupp",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "prime_MajorationHeuresSupp_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "prime_MajorationHeuresSupp_CoefMulti",
            disabled: disableLigne,
          })}
          {this.input({
            accessor: "prime_MajorationHeuresSupp_Marge",
            disabled: true,
          })}
        </tr>
        <tr>
          <td className="label">majoration heure dimanche</td>
          {this.emptyCell()}

          <td className="unite">{cal?.prime_MajorationHeuresDimanche_Unite}</td>
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "prime_MajorationHeuresDimanche",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "prime_MajorationHeuresDimanche_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "prime_MajorationHeuresDimanche_CoefMulti",
            disabled: disableLigne,
          })}

          {this.input({
            accessor: "prime_MajorationHeuresDimanche_Marge",
            disabled: true,
          })}
        </tr>
        <tr>
          <td className="label">prime WE</td>
          {this.emptyCell()}

          <td className="unite">{cal?.prime_Weekend_Unite}</td>
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "prime_Weekend",
          })}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "prime_Weekend_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "prime_Weekend_CoefMulti",
            disabled: disableLigne,
          })}

          {this.input({
            accessor: "prime_Weekend_Marge",
            disabled: true,
          })}
        </tr>
        <tr>
          <td className="label">Majoration heure férié</td>
          {this.emptyCell()}

          <td className="unite">{cal?.prime_MajorationHeuresFeriee_Unite}</td>
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "prime_MajorationHeuresFeriee",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "prime_MajorationHeuresFeriee_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "prime_MajorationHeuresFeriee_CoefMulti",
            disabled: disableLigne,
          })}

          {this.input({
            accessor: "prime_MajorationHeuresFeriee_Marge",
            disabled: true,
          })}
        </tr>
      </>
    );
  }

  renderDepla() {
    let cal = this.props.devis.calculette;
    let disableLigne = this.disableCoefLigne();

    return (
      <>
        <tr>
          <th className="label">Déplacements</th>
          <th colSpan="9"></th>
          {this.input({
            accessor: "deplacements_TotalCouts",
            disabled: true,
          })}
          {this.input({
            accessor: "deplacements_PartCouts",
            disabled: true,
          })}
          {this.input({
            accessor: "deplacements_CoefMulti",
            disabled: disableLigne,
          })}
          {this.emptyCell()}
        </tr>
        <tr>
          <td className="label">Forfait nuit</td>
          {this.input({
            accessor: "deplacements_ForfaitNuit_CoutUnitaire",
            disabled: true,
          })}
          <td className="unite">{cal?.deplacements_ForfaitNuit_Unite}</td>
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "deplacements_ForfaitNuit_AmeneRepli",
          })}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "deplacements_ForfaitNuit_AmeneRepli",
            disabled: true,
          })}
          {this.input({
            accessor: "deplacements_ForfaitNuit_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "deplacements_ForfaitNuit_CoefMulti",
            disabled: disableLigne,
          })}

          {this.input({
            accessor: "deplacements_ForfaitNuit_Marge",
            disabled: true,
          })}
        </tr>
        <tr>
          <td className="label">Forfait restaurant</td>
          {this.input({
            accessor: "deplacements_ForfaitRestaurant_CoutUnitaire",
            disabled: true,
          })}
          <td className="unite">{cal?.deplacements_ForfaitRestaurant_Unite}</td>
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "deplacements_ForfaitRestaurant_AmeneRepli",
          })}
          {this.emptyCell()}

          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "deplacements_ForfaitRestaurant_AmeneRepli",
            disabled: true,
          })}
          {this.input({
            accessor: "deplacements_ForfaitRestaurant_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "deplacements_ForfaitRestaurant_CoefMulti",
            disabled: disableLigne,
          })}
          {this.input({
            accessor: "deplacements_ForfaitRestaurant_Marge",
            disabled: true,
          })}
        </tr>
        <tr>
          <td className="label">Essence</td>
          {this.input({
            accessor: "deplacements_Essence_CoutUnitaire",
            disabled: true,
            numberOfDecimals: 4,
          })}
          <td className="unite">{cal?.deplacements_Essence_Unite}</td>
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "deplacements_Essence_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "deplacements_Essence_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "deplacements_Essence_CoefMulti",
            disabled: disableLigne,
          })}

          {this.input({
            accessor: "deplacements_Essence_Marge",
            disabled: true,
          })}
        </tr>
        <tr>
          <td className="label">Péages</td>
          {this.input({
            accessor: "deplacements_Peages_CoutUnitaire",
            disabled: true,
          })}
          <td className="unite">{cal?.deplacements_Peages_Unite}</td>
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "deplacements_Peages_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "deplacements_Peages_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "deplacements_Peages_CoefMulti",
            disabled: disableLigne,
          })}

          {this.input({
            accessor: "deplacements_Peages_Marge",
            disabled: true,
          })}
        </tr>
        <tr>
          <td className="label">Véhicule</td>
          {this.input({
            accessor: "deplacements_VehiculeFraisFixesParJour_CoutUnitaire",
            disabled: true,
          })}
          <td className="unite">
            {cal?.deplacements_VehiculeFraisFixesParJour_Unite}
          </td>
          {this.input({
            accessor: "deplacements_VehiculeFraisFixesParJour_Devis",
            disabled: true,
          })}
          {this.input({
            accessor: "deplacements_VehiculeFraisFixesParJour_Preparation",
            disabled: true,
          })}
          {this.input({
            accessor: "deplacements_VehiculeFraisFixesParJour_AmeneRepli",
            disabled: true,
          })}
          {this.input({
            accessor:
              "deplacements_VehiculeFraisFixesParJour_MesuresOuFormation",
            disabled: true,
          })}
          {this.input({
            accessor: "deplacements_VehiculeFraisFixesParJour_Labo",
            disabled: true,
          })}
          {this.input({
            accessor:
              "deplacements_VehiculeFraisFixesParJour_RapportOuAdministratif",
            disabled: true,
          })}
          {this.input({
            accessor: "deplacements_VehiculeFraisFixesParJour_Total",
            disabled: true,
          })}
          {this.input({
            accessor: "deplacements_VehiculeFraisFixesParJour_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "deplacements_VehiculeFraisFixesParJour_CoefMulti",
            disabled: disableLigne,
          })}
          {this.input({
            accessor: "deplacements_VehiculeFraisFixesParJour_Marge",
            disabled: true,
          })}
        </tr>
        <tr>
          <td className="label">Train / Avion</td>
          {this.input({
            accessor: "deplacements_TrainAvion_CoutUnitaire",
          })}
          <td className="unite">{cal?.deplacements_TrainAvion_Unite}</td>
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "deplacements_TrainAvion_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}

          {this.input({
            accessor: "deplacements_TrainAvion_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "deplacements_TrainAvion_CoefMulti",
            disabled: disableLigne,
          })}

          {this.input({
            accessor: "deplacements_TrainAvion_Marge",
            disabled: true,
          })}
        </tr>
      </>
    );
  }

  renderMat() {
    let calculette = this.props.devis.calculette;
    let disableLigne = this.disableCoefLigne();

    return (
      <>
        <tr>
          <th className="label">Matériels</th>
          <th colSpan="9"></th>

          {this.input({
            accessor: "materiels_TotalCouts",
            disabled: true,
          })}
          {this.input({
            accessor: "materiels_PartCouts",
            disabled: true,
          })}
          {this.input({
            accessor: "materiels_CoefMulti",
            disabled: disableLigne,
          })}
          {this.emptyCell()}
        </tr>
        <tr>
          <td className="label">Système d'informations</td>
          {this.input({
            accessor: "materiels_SystemeDInformations_CoutUnitaire",
            disabled: true,
          })}
          <td className="unite">
            {calculette?.materiels_SystemeDInformations_Unite}
          </td>
          {this.input({
            accessor: "materiels_SystemeDInformations_Devis",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "materiels_SystemeDInformations_Labo",
            disabled: true,
          })}
          {this.input({
            accessor: "materiels_SystemeDInformations_Rapport",
            disabled: true,
          })}
          {this.emptyCell()}

          {this.input({
            accessor: "materiels_SystemeDInformations_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "materiels_SystemeDInformations_CoefMulti",
            disabled: disableLigne,
          })}

          {this.input({
            accessor: "materiels_SystemeDInformations_Marge",
            disabled: true,
          })}
        </tr>

        <tr>
          <td className="label">
            {calculette?.materiels_MaterielIndustriel_A_Label}
          </td>
          {this.input({
            accessor: "materiels_MaterielIndustriel_A_CoutUnitaire",
            disabled: true,
          })}
          <td className="unite">
            {calculette?.materiels_MaterielIndustriel_A_Unite}
          </td>
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "materiels_MaterielIndustriel_A_AmeneRepli",
            disabled: true,
          })}
          {this.input({
            accessor: "materiels_MaterielIndustriel_A_MesuresOuFormation",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "materiels_MaterielIndustriel_A_Total",
            disabled: true,
          })}
          {this.input({
            accessor: "materiels_MaterielIndustriel_A_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "materiels_MaterielIndustriel_A_CoefMulti",
            disabled: disableLigne,
          })}

          {this.input({
            accessor: "materiels_MaterielIndustriel_A_Marge",
            disabled: true,
          })}
        </tr>

        <tr>
          <td className="label">Sous-traitance ou location</td>
          {this.input({
            accessor: "materiels_SousTraitanceOuLocation_CoutUnitaire",
            disabled: true,
          })}
          <td className="unite">
            {calculette?.materiels_SousTraitanceOuLocation_Unite}
          </td>
          {this.input({
            accessor: "materiels_SousTraitanceOuLocation_Devis",
          })}
          {this.input({
            accessor: "materiels_SousTraitanceOuLocation_Preparation",
          })}
          {this.input({
            accessor: "materiels_SousTraitanceOuLocation_AmeneRepli",
          })}
          {this.input({
            accessor: "materiels_SousTraitanceOuLocation_MesuresOuFormation",
          })}
          {this.input({
            accessor: "materiels_SousTraitanceOuLocation_Labo",
          })}
          {this.input({
            accessor:
              "materiels_SousTraitanceOuLocation_RapportOuAdministratif",
          })}
          {this.input({
            accessor: "materiels_SousTraitanceOuLocation_Total",
            disabled: true,
          })}
          {this.input({
            accessor: "materiels_SousTraitanceOuLocation_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "materiels_SousTraitanceOuLocation_CoefMulti",
            disabled: disableLigne,
          })}

          {this.input({
            accessor: "materiels_SousTraitanceOuLocation_Marge",
            disabled: true,
          })}
        </tr>
        <tr>
          <td className="label">Consommables</td>
          {this.input({
            accessor: "materiels_Consommables_CoutUnitaire",
          })}
          <td className="unite">{calculette?.materiels_Consommables_Unite}</td>
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "materiels_Consommables_Total",
            disabled: true,
          })}
          {this.input({
            accessor: "materiels_Consommables_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "materiels_Consommables_CoefMulti",
            disabled: disableLigne,
          })}

          {this.input({
            accessor: "materiels_Consommables_Marge",
            disabled: true,
          })}
        </tr>
      </>
    );
  }

  renderAutre() {
    let calculette = this.props.devis.calculette;
    let disableLigne = this.disableCoefLigne();

    return (
      <>
        <tr>
          <th className="label">Autres</th>
          <th colSpan="9"></th>

          {this.input({
            accessor: "autres_TotalCouts",
            disabled: true,
          })}
          {this.input({
            accessor: "autres_PartCouts",
            disabled: true,
          })}
          {this.input({
            accessor: "autres_CoefMulti",
            disabled: disableLigne,
          })}
          {this.emptyCell()}
        </tr>
        <tr>
          <td className="input">
            <Input
              value={calculette?.autres_A_Label}
              handleChange={this.props.handleChange}
              type="text"
              accessor={"calculette.autres_A_Label"}
              showClearButton={false}
              showValidator={false}
              disabled={
                !this.props.editing || calculette.autres_A_Label == "DICT"
              }
            ></Input>
          </td>
          {this.emptyCell()}
          <td className="unite">{calculette?.autres_A_Unite}</td>
          {this.input({
            accessor: "autres_A_Devis",
          })}
          {this.input({
            accessor: "autres_A_Preparation",
          })}
          {this.input({
            accessor: "autres_A_AmeneRepli",
          })}
          {this.input({
            accessor: "autres_A_MesuresOuFormation",
          })}
          {this.input({
            accessor: "autres_A_Labo",
          })}
          {this.input({
            accessor: "autres_A_RapportOuAdministratif",
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "autres_A_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "autres_A_CoefMulti",
            disabled: disableLigne,
          })}
          {this.input({
            accessor: "autres_A_Marge",
            disabled: true,
          })}
        </tr>
        <tr>
          <td className="input">
            <Input
              value={calculette?.autres_B_Label}
              handleChange={this.props.handleChange}
              type="text"
              accessor={"calculette.autres_B_Label"}
              showClearButton={false}
              showValidator={false}
              disabled={!this.props.editing}
            ></Input>
          </td>
          {this.emptyCell()}
          <td className="unite">{calculette?.autres_B_Unite}</td>
          {this.input({
            accessor: "autres_B_Devis",
          })}
          {this.input({
            accessor: "autres_B_Preparation",
          })}
          {this.input({
            accessor: "autres_B_AmeneRepli",
          })}
          {this.input({
            accessor: "autres_B_MesuresOuFormation",
          })}
          {this.input({
            accessor: "autres_B_Labo",
          })}
          {this.input({
            accessor: "autres_B_RapportOuAdministratif",
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "autres_B_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "autres_B_CoefMulti",
            disabled: disableLigne,
          })}

          {this.input({
            accessor: "autres_B_Marge",
            disabled: true,
          })}
        </tr>
        <tr>
          <td className="input">
            <Input
              value={calculette?.autres_C_Label}
              handleChange={this.props.handleChange}
              type="text"
              accessor={"calculette.autres_C_Label"}
              showClearButton={false}
              showValidator={false}
              disabled={!this.props.editing}
            ></Input>
          </td>
          {this.emptyCell()}
          <td className="unite">{calculette?.autres_C_Unite}</td>
          {this.input({
            accessor: "autres_C_Devis",
          })}
          {this.input({
            accessor: "autres_C_Preparation",
          })}
          {this.input({
            accessor: "autres_C_AmeneRepli",
          })}
          {this.input({
            accessor: "autres_C_MesuresOuFormation",
          })}
          {this.input({
            accessor: "autres_C_Labo",
          })}
          {this.input({
            accessor: "autres_C_RapportOuAdministratif",
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "autres_C_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "autres_C_CoefMulti",
            disabled: disableLigne,
          })}

          {this.input({
            accessor: "autres_C_Marge",
            disabled: true,
          })}
        </tr>
        <tr>
          <td className="input">
            <Input
              value={calculette?.autres_D_Label}
              handleChange={this.props.handleChange}
              type="text"
              accessor={"calculette.autres_D_Label"}
              showClearButton={false}
              showValidator={false}
              disabled={!this.props.editing}
            ></Input>
          </td>
          {this.emptyCell()}
          <td className="unite">{calculette?.autres_D_Unite}</td>
          {this.input({
            accessor: "autres_D_Devis",
          })}
          {this.input({
            accessor: "autres_D_Preparation",
          })}
          {this.input({
            accessor: "autres_D_AmeneRepli",
          })}
          {this.input({
            accessor: "autres_D_MesuresOuFormation",
          })}
          {this.input({
            accessor: "autres_D_Labo",
          })}
          {this.input({
            accessor: "autres_D_RapportOuAdministratif",
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "autres_D_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "autres_D_CoefMulti",
            disabled: disableLigne,
          })}

          {this.input({
            accessor: "autres_D_Marge",
            disabled: true,
          })}
        </tr>
        <tr>
          <td className="input">
            <Input
              value={calculette?.autres_E_Label}
              handleChange={this.props.handleChange}
              type="text"
              accessor={"calculette.autres_E_Label"}
              showClearButton={false}
              showValidator={false}
              disabled={!this.props.editing}
            ></Input>
          </td>
          {this.emptyCell()}
          <td className="unite">{calculette?.autres_E_Unite}</td>
          {this.input({
            accessor: "autres_E_Devis",
          })}
          {this.input({
            accessor: "autres_E_Preparation",
          })}
          {this.input({
            accessor: "autres_E_AmeneRepli",
          })}
          {this.input({
            accessor: "autres_E_MesuresOuFormation",
          })}
          {this.input({
            accessor: "autres_E_Labo",
          })}
          {this.input({
            accessor: "autres_E_RapportOuAdministratif",
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "autres_E_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.input({
            accessor: "autres_E_CoefMulti",
            disabled: disableLigne,
          })}
          {this.input({
            accessor: "autres_E_Marge",
            disabled: true,
          })}
        </tr>
      </>
    );
  }

  renderSousTotal() {
    let cal = this.props.devis.calculette;
    let disableCoefColonne = this.disableCoefColonne();
    return (
      <>
        <tr>
          <th className="label">Total/mission</th>
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "total_Devis",
            disabled: true,
          })}
          {this.input({
            accessor: "total_Preparation",
            disabled: true,
          })}
          {this.input({
            accessor: "total_AmeneRepli",
            disabled: true,
          })}
          {this.input({
            accessor: "total_Mesures",
            disabled: true,
          })}
          {this.input({
            accessor: "total_Labo",
            disabled: true,
          })}
          {this.input({
            accessor: "total_Rapport",
            disabled: true,
          })}
          {this.input({
            accessor: "total_Total",
            disabled: true,
          })}
          {this.input({
            accessor: "totalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "total_Marge",
            disabled: true,
          })}
        </tr>
        <tr>
          <td className="label">Coef multiplicateur</td>
          {this.emptyCell()}
          {this.input({
            accessor: "total_CoefMultiCommun",
            disabled: disableCoefColonne,
          })}
          {this.input({
            accessor: "total_Devis_CoefMulti",
            disabled: disableCoefColonne,
          })}
          {this.input({
            accessor: "total_Preparation_CoefMulti",
            disabled: disableCoefColonne,
          })}
          {this.input({
            accessor: "total_AmeneRepli_CoefMulti",
            disabled: disableCoefColonne,
          })}
          {this.input({
            accessor: "total_Mesures_CoefMulti",
            disabled: disableCoefColonne,
          })}
          {this.input({
            accessor: "total_Labo_CoefMulti",
            disabled: disableCoefColonne,
          })}
          {this.input({
            accessor: "total_Rapport_CoefMulti",
            disabled: disableCoefColonne,
          })}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
        </tr>
        <tr>
          <td className="label">Marge total</td>
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "total_Devis_Marge",
            disabled: true,
          })}
          {this.input({
            accessor: "total_Preparation_Marge",
            disabled: true,
          })}
          {this.input({
            accessor: "total_AmeneRepli_Marge",
            disabled: true,
          })}
          {this.input({
            accessor: "total_Mesures_Marge",
            disabled: true,
          })}
          {this.input({
            accessor: "total_Labo_Marge",
            disabled: true,
          })}
          {this.input({
            accessor: "total_Rapport_Marge",
            disabled: true,
          })}
          {this.input({
            accessor: "total_Total_Marge",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.emptyCell()}
        </tr>
        <tr>
          <th className="label">Sous-total exploitation</th>
          <th colSpan="9"></th>

          {this.input({
            accessor: "exploitation_TotalCouts",
            disabled: true,
          })}
          {this.input({
            accessor: "exploitation_PartCouts",
            disabled: true,
          })}
        </tr>

        <tr>
          <td className="label bold">Assurance</td>
          {this.input({
            accessor: "exploitation_Assurance_CoutUnitaire",
          })}
          <td className="unite">{cal?.exploitation_Assurance_Unite}</td>
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "exploitation_Assurance_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
        </tr>
        <tr>
          <td className="label bold">Aléa</td>
          {this.input({
            accessor: "exploitation_Alea_CoutUnitaire",
          })}
          <td className="unite">{cal?.exploitation_Alea_Unite}</td>
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "exploitation_Alea_TotalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
        </tr>

        <tr>
          <td className="label bold">TOTAL PRIX REVIENT HT</td>
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "totalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
        </tr>
        <tr>
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
        </tr>
        <tr>
          <td className="label bold">PV HT</td>
          {this.input({
            accessor: "pV_HT",
            disabled:
              cal?.modeCalcul == "Logique 2 : Prix de revient" ||
              cal?.modeCalcul == "Logique 1 : Accord cadre",
          })}
          <td className="unite">€ / mission</td>
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "pV_HT",
            disabled: true,
          })}
          {this.emptyCell()}
        </tr>
        <tr>
          <td className="label bold">Marge</td>
          {this.emptyCell()}
          <td className="unite">€ et %</td>
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.input({
            accessor: "marge",
            disabled: true,
          })}
          <td className="input d-flex" style={{ height: "50px" }}>
            <div
              style={{
                height: "100%",
                width: "15px",
                backgroundColor: this.getColor(
                  this.props.devis.calculette.partMarge
                ),
              }}
            ></div>
            <Input
              value={this.props.devis.calculette.partMarge}
              ignoredValues={[0]}
              handleBlur={this.props.handleChange}
              type="decimal"
              accessor={"calculette.partMarge"}
              showClearButton={false}
              showValidator={false}
              disabled={true}
              style={{
                color: this.getColor(this.props.devis.calculette.partMarge),
              }}
            ></Input>
          </td>
        </tr>
      </>
    );
  }

  renderCalculette() {
    return (
      <div
        className="shadow p-3  bg-body rounded bg-light pageClient"
        style={{ width: "fit-content" }}
      >
        <div>
          {this.renderEntete()}
          {this.renderHypotheses()}
        </div>
        <div>
          <div
            className="m-2 d-flex flex-grow-1"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              className={"row tableauBodyInformations " + "pb-2 "}
              style={{
                flexGrow: 1,
                placeContent: "flex-start",
              }}
            >
              <div
                className="lock-header-Cal"
                style={{
                  padding: "0 0 0 0 ",
                }}
              >
                <table className="Calculette">
                  <thead>{this.renderHeadCalculette()}</thead>
                  <tbody>
                    {this.renderPersonnel()}
                    {this.renderPrime()}
                    {this.renderDepla()}
                    {this.renderMat()}
                    {this.renderAutre()}
                    {this.renderSousTotal()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight("Devis", "Calculette");
    if (right == "RW" || right == "RO") {
      if (this.props.devis.calculette) return this.renderCalculette();
      else return <></>;
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

export { Calculette };
