import React, { useEffect, useRef, useState } from "react";
import L from "leaflet";
import "leaflet.markercluster";
import http from "http-common";

import { isNullOrWhiteSpaces } from "_utils";

import { renderToString } from "react-dom/server";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import "./Geomarket.css";

// Colored Markers for Leaflet
import {
  greenIcon,
  orangeIcon,
  redIcon,
  violetIcon,
  blackIcon,
} from "_components/Leaflet/Markers";



function MapGeomarket(props) {
  const [map, setMap] = useState(null);
  const [url, setURL] = useState(
    "https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
  );
  const [layerDep, setLayerDep] = useState(null);
  const mapRef = useRef(null);
  const markersRef = useRef([]);
  const clientsId = useRef([]);
  const markerClusterGroupRef = useRef(null);
  const style = () => {
    return {
      weight: 3,
      opacity: 1,
      color: "#1a7bb0",
      dashArray: "3",
      fillOpacity: 0,
    };
  };

  const CustomPopup = ({
    idClient,
    nom,
    qualification,
    type,
    produitsInternes,
    materielsProprietaires,
    materielsUtilisateurs,
    adresse,
  }) => {
    return (
      <div style={{ width: "200px" }}>
        <div id="GeomarketPopup" style={{ whiteSpace: "break-spaces" }}>
          <p style={{ fontWeight: "bold" }}>{nom}</p>
          <p>{qualification}</p>
          {!isNullOrWhiteSpaces(type) ? (
            <>
              <p>{type}</p>
            </>
          ) : (
            ""
          )}
          {!isNullOrWhiteSpaces(produitsInternes) ? (
            <>
              <p>{produitsInternes.trim()}</p>
            </>
          ) : (
            ""
          )}
          {!isNullOrWhiteSpaces(materielsProprietaires) ? (
            <>
              <p>Matériels Propriétaires : {materielsProprietaires}</p>
            </>
          ) : (
            ""
          )}
          {!isNullOrWhiteSpaces(materielsUtilisateurs) ? (
            <>
              <p>Matériels Utilisateurs : {materielsUtilisateurs}</p>
            </>
          ) : (
            ""
          )}
          {!isNullOrWhiteSpaces(adresse) ? (
            <>
              <p>{adresse}</p>
            </>
          ) : (
            ""
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <a
            href={"/clients/" + idClient}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button
              className="btn btn-secondary btn-sm text-light ms-1"
              style={{ width: "32px" }}
            >
              <FontAwesomeIcon
                icon={faExternalLinkAlt}
                style={{ position: "relative" }}
              />
            </button>
          </a>
        </div>
      </div>
    );
  };

  useEffect(() => {
    // create a Leaflet map instance
    const mapInstance = L.map(mapRef.current).setView([46.5, 2], 6);
    mapInstance.createPane("all");
    // add a tile layer to the map
    let tileLocal = L.tileLayer(url, {
      attribution:
        'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
      noWrap: true,
    }).addTo(mapInstance);

    let layerDepInstance = L.geoJSON(null, { style: style, pane: "all" }).addTo(
      mapInstance
    );

    // create a MarkerClusterGroup layer for the markers
    markerClusterGroupRef.current = L.markerClusterGroup({
      showCoverageOnHover: false,
      pane: "all",
    }).addTo(mapInstance);

    // Display a ruler/scale in km on the map
    L.control.scale({ imperial: false }).addTo(mapInstance);

    addRedirectButtonOnMap(mapInstance);
    tileLocal.on("tileerror", () => {
      tileLocal.setUrl("https://tile.openstreetmap.org/{z}/{x}/{y}.png");
      setURL("https://tile.openstreetmap.org/{z}/{x}/{y}.png");
    });
    // store the map instance in state
    setMap(mapInstance);
    setLayerDep(layerDepInstance);
    // cleanup function to remove the map instance on unmount
    return () => mapInstance.remove();
  }, []);

  const refreshDepart = () => {
    var paramsDepartement = {
      service: "WFS",
      version: "1.0.0",
      request: "getFeature",
      typeName: process.env.REACT_APP_COUCHE_DEPARTEMENT,
      maxFeatures: 3000,
      outputFormat: "application/json",
      format_options: "callback: departmentJSON",
      srsName: "EPSG:4326",
      bbox: map.getBounds().toBBoxString() + ",EPSG:4326",
    };
    if (map.getZoom() > 5) {
      http
        .get(
          process.env.REACT_APP_GEOSERVER_ADDRESS +
            L.Util.getParamString(paramsDepartement)
        )
        .then((response) => {
          layerDep.clearLayers();

          layerDep.addData(response.data.features);
        });
    } else {
      layerDep.clearLayers();
    }
  };

  const refreshMap = (map, filter) => {
    if (map) {
      requestMarkers(map.getBounds(), filter);
      refreshDepart();
    }
  };

  useEffect(() => {
    if (map) {
      refreshMap(map, props.queryFilter);
      // listen for map moveend event to refresh markers
      let myCallback = () => refreshMap(map, props.queryFilter);

      map.on("moveend", myCallback);
      return () => {
        map.off("moveend", myCallback);
      };
    }
  }, [map, props.queryFilter]);

  const requestMarkers = (bounds, queryFilter) => {
    // create a CQL filter string that includes the bounding box filter
    const boundsstring = bounds.toBBoxString();
    const bboxFilter = `BBOX(Position,${boundsstring},'EPSG:4326')`;

    const cqlFilter = isNullOrWhiteSpaces(queryFilter)
      ? bboxFilter
      : `${bboxFilter} AND ${queryFilter}`;

    // request data from GeoServer using WFS
    var geoJsonUrl = process.env.REACT_APP_GEOSERVER_ADDRESS;
    var params = {
      service: "WFS",
      version: "1.0.0",
      request: "getFeature",
      typeName: process.env.REACT_APP_COUCHE_GEOMARKET,
      maxFeatures: 1000,
      outputFormat: "application/json",
      srsName: "EPSG:4326",
      cql_filter: cqlFilter,
    };

    http.get(geoJsonUrl + L.Util.getParamString(params)).then((response) => {
      props.setNumberOfPointsDisplayedAndMatched(
        response.data.numberReturned,
        response.data.numberMatched
      );

      clearMarkers();
      clientsId.current = response.data.features?.map((e) => e.properties.Id);
      // add markers to the map from the WFS data
      response.data.features?.forEach((feature) => {
        const coords = feature.geometry.coordinates.reverse();

        const marker = L.marker(coords, {
          icon: getColoredMarker(feature.properties.Qualification),
        });

        // bind a popup to the marker with a button/link to the FicheClient.
        marker.bindPopup(
          renderToString(
            <CustomPopup
              idClient={feature.properties.Id}
              nom={feature.properties.Nom_Client}
              qualification={feature.properties.Qualification}
              type={feature.properties.Type}
              produitsInternes={feature.properties.ProduitsInternes}
              materielsProprietaires={feature.properties.MaterielsProprietaires}
              materielsUtilisateurs={feature.properties.MaterielsUtilisateurs}
              adresse={feature.properties.Adresse}
            />
          )
        );

        markersRef.current.push(marker);

        markerClusterGroupRef.current.addLayer(marker);
      });
    });
  };

  function clearMarkers() {
    // remove all existing markers from the MarkerClusterGroup layer
    markersRef.current.forEach((marker) =>
      markerClusterGroupRef.current.removeLayer(marker)
    );
    markersRef.current = [];
    clientsId.current = [];
  }

  // define a function that maps each value of Qualification to a color
  function getColoredMarker(qualification) {
    switch (qualification) {
      case "Client":
        return greenIcon;
      case "Prospect":
        return orangeIcon;
      case "Suspect":
        return redIcon;
      case "Abonné":
        return violetIcon;
      default:
        return blackIcon;
    }
  }

  function redirect() {
    props.history.push("/clients", {
      clientIdsFromGeomarket: clientsId.current,
    });
  }

  function addRedirectButtonOnMap(map) {
    // Define a custom control class for the redirect button
    const RedirectButtonControl = L.Control.extend({
      onAdd: function () {
        // Create a button element
        const button = L.DomUtil.create("button", "redirect-button");
        button.innerHTML = "Exporter les clients";
        button.className = button.className + " btn btn-info";

        // Handle button click event
        L.DomEvent.on(button, "click", () => {
          redirect();
        });

        return button;
      },
    });

    // Create an instance of the custom control
    const redirectButtonControl = new RedirectButtonControl({
      position: "bottomright",
    });

    // Add the control to the map
    redirectButtonControl.addTo(map);
  }
  return <div ref={mapRef} style={{ height: "100%" }}></div>;
}

export default MapGeomarket;
