import React from "react";
import { makeEmailClickable, ToLocaleDateString } from "_utils";
import { PropTypes } from "prop-types";
import { IfNullToText } from "_utils";

function SpecialRender(props) {
  if (props.functionAppliedToValue)
    return IfNullToText(props.functionAppliedToValue(props.value), "-");

  if (props.type) {
    let dateFormated;
    let type = props.type;
    switch (type) {
      case "email":
        return makeEmailClickable(props.value);
      case "date":
        dateFormated = ToLocaleDateString(props.value);
        return dateFormated != "Invalid Date"
          ? IfNullToText(dateFormated, "-")
          : IfNullToText(props.value, "-");
      case "period":
        if (props.value) {
          let dates = props.value.split("|");

          let dateFormated1 = ToLocaleDateString(dates[0]);
          let dateFormated2 = ToLocaleDateString(dates[1]);

          dateFormated1 =
            dateFormated1 != "Invalid Date"
              ? IfNullToText(dateFormated1, "-")
              : "-";
          dateFormated2 =
            dateFormated2 != "Invalid Date"
              ? IfNullToText(dateFormated2, "-")
              : "-";
          return dateFormated1 + " | " + dateFormated2;
        } else return "-|-";
      default:
        return IfNullToText(props.value, "-");
    }
  }

  return IfNullToText(props.value, "-");
}

SpecialRender.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
};
/**
 * Génère un champ d'information en colonne contenant :
 * @param props :
 *  @param {string} label, le label de la valeur
 *  @param {*} value, la valeur
 * @returns un champ d'information avec un label et une valeur
 */
function DataFieldColumn(props) {
  return (
    <>
      <div className="text-uppercase text-muted solwayFont">{props.label}</div>
      <div style={{ overflowWrap: "break-word" }}>{SpecialRender(props)}</div>
    </>
  );
}

DataFieldColumn.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
};

export { DataFieldColumn };
