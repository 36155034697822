import React, { useEffect, useState } from "react";

import { Box, BoxInformationsInline, WarningBar } from "_components";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { App } from "App";
import { FournisseurService } from "_services";
import { AccordionParAnnee } from "./SubTable/AccordionParAnnee";
import { AccordeonBonsAchats } from "./SubTable/AccordeonBonsAchats";
import { Loading } from "_components/FicheComponents/Loading";

const Statistiques = (props) => {
  const [anneeStatistique] = useState(2);
  // const [anneeStatistiqueTmp, setAnneeStatistiqueTmp] = useState(2);
  // const [openDialogAlertAnnee, setOpenDialogAlertAnnee] = useState(false);
  const [statistique, setStatistique] = useState({});
  const [loading, setLoading] = useState(true);

  const reloadStats = () => {
    FournisseurService.getStatistiques(props.fournisseur.id, anneeStatistique)
      .then((res) => {
        setStatistique(res.data);
        setLoading(false);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    setLoading(true);
    reloadStats();
  }, [anneeStatistique]);

  let right = App.RightsGuard?.current?.hasRight("Fournisseurs", "Fiche");
  const rightb = App.RightsGuard?.current?.isPersonne(
    props.fournisseur?.referentInterne?.oid
  );

  if (rightb == "RW") {
    right = rightb;
  }

  if (right == "RW" || right == "RO") {
    // On crée la hierarchie des documents à afficher :
    //    Factures : Les factures (plusieurs en cas d'avoir) .
    // TODO : différencier les factures d'avoir des factures classiques.
    if (props.loadingSynthese)
      return (
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon icon={faSpinner} size="lg" className="fa-spin" />
        </div>
      );

    var arrFacturesIds = [];

    var arrFacturesSansCommandes = statistique?.factures?.filter(
      (f) => !arrFacturesIds.includes(f.num_Facture)
    );

    arrFacturesSansCommandes?.sort(
      (a, b) => new Date(b.dateCreation) - new Date(a.dateCreation)
    );

    var arrDocumentParAnnee = {};
    arrFacturesSansCommandes?.forEach((facture) =>
      arrDocumentParAnnee[new Date(facture.dateCreation).getFullYear()]
        ? arrDocumentParAnnee[new Date(facture.dateCreation).getFullYear()]
            ?.factures
          ? arrDocumentParAnnee[
              new Date(facture.dateCreation).getFullYear()
            ].factures.push(facture)
          : (arrDocumentParAnnee[
              new Date(facture.dateCreation).getFullYear()
            ].factures = [facture])
        : (arrDocumentParAnnee[new Date(facture.dateCreation).getFullYear()] = {
            annee: new Date(facture.dateCreation).getFullYear(),
            factures: [facture],
          })
    );

    let bloc3 = [
      {
        label: "CA Sol Solution",
        accessor: "caSolSol",
        functionAppliedToValue: () => {
          return (
            Number(statistique.total).toLocaleString("fr", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + "€"
          );
        },
        colSize: 6,
      },
      {
        label: "CA Fournisseur",
        accessor: "caFournisseur",
        type: "decimalTable",
        handleBlur: props.handleChange,
        functionAppliedToValue: (value) => {
          return (
            Number(value).toLocaleString("fr", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + "€"
          );
        },
        colSize: 6,
      },

      {
        label: "Dépendance financière",
        accessor: "dependanceFinanciere",
        functionAppliedToValue: () => {
          let fourni = props.fournisseur.caFournisseur;
          let solsol = statistique.total;
          let value = 0;
          if (fourni != null && fourni != 0) value = solsol / fourni;

          let couleur = "red";
          if (value < 0.1) couleur = "green";
          else if (value < 0.3) couleur = "orange";
          return (
            <div style={{ color: couleur }}>
              {Number(value * 100).toLocaleString("fr", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + "%"}
            </div>
          );
        },
      },
    ];

    return (
      <>
        {/* <div style={{ display: "flex", marginBottom: "15px" }}>
          <div className="d-flex flex-row align-items-center me-2">
            <div
              style={{
                flexShrink: "0",
              }}
            >
              Nombre d'années affichées :
            </div>
            <Input
              type="decimalTable"
              numberOfDecimals={0}
              handleBlur={(acc, val) => {
                if (val < new Date().getFullYear() - 2020)
                  setAnneeStatistique(val);
                else {
               
                  setOpenDialogAlertAnnee(true);
                }
              }}
              accessor={"anneeStatistique"}
              value={anneeStatistique}
              tooltip={
                <div>
                  Attention, plus le nombre est élevé, plus le temps de
                  chargement sera long.
                </div>
              }
            />
          </div>
        </div> */}
        {loading ? (
          <Loading />
        ) : (
          <div className="row">
            <div className="col-12">
              <BoxInformationsInline
                title="Statistique"
                datas={bloc3}
                state={props.fournisseur}
                stateFieldNameToUpdate={props.stateFieldNameToUpdate}
                service={props.service}
                handleChange={props.handleChange}
                handleEditing={props.handleEditing}
                editing={props.editing}
                handleUpdate={props.handleUpdate}
                NoEdition={right == "RO"}
              />
            </div>
            <div className="col-md-12 col-lg-12">
              <Box
                noPaddingBottom={true}
                header={
                  <div className="col-12 align-self-center py-2">
                    <div>Synthèse</div>
                  </div>
                }
                body={
                  <>
                    <table className="table table-sm table-striped table-bordered">
                      <thead
                        className={
                          "sticky-top" +
                          (statistique?.synthese?.length == 0
                            ? " theadBorderRadius"
                            : "")
                        }
                      >
                        <tr>
                          <th>Année</th>
                          <th>CA</th>
                        </tr>
                      </thead>
                      <tbody>
                        {statistique?.synthese?.map((d, index) => {
                          return (
                            <tr key={index}>
                              <td>{d.annee}</td>
                              <td>{d.sommeFactures}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                }
              />
            </div>

            <div className="col-12">
              <Box
                noPaddingBottom={true}
                header={
                  <div className="col-12 align-self-center py-2">
                    <div>Factures</div>
                  </div>
                }
                body={
                  <table
                    id="tableStat"
                    className="table table-sm table-striped table-bordered m-0"
                  >
                    <thead
                      className={
                        "sticky-top" +
                        (Object.entries(arrDocumentParAnnee)?.length == 0
                          ? " theadBorderRadius"
                          : "")
                      }
                    >
                      <tr>
                        <th>Année</th>
                        <th>Document</th>
                        <th>Date de création</th>
                        <th>Prix HT</th>
                        <th>Payé ?</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(arrDocumentParAnnee)
                        .sort((a, b) => {
                          return b[0] - a[0];
                        })
                        .map(function ([key, value]) {
                          return (
                            <AccordionParAnnee
                              key={key}
                              annee={key}
                              factures={value.factures}
                            />
                          );
                        })}
                    </tbody>
                  </table>
                }
              />
            </div>
            <div className="col-12">
              <Box
                noPaddingBottom={true}
                header={
                  <div className="col-12 align-self-center py-2">
                    <div>Bons de Commande</div>
                  </div>
                }
                body={
                  <>
                    <table className="table table-sm table-bordered">
                      <thead
                        className={
                          "sticky-top" +
                          (statistique.bonsAchats?.length == 0
                            ? " theadBorderRadius"
                            : "")
                        }
                      >
                        <tr>
                          <th>Num</th>
                          <th>Date</th>
                          <th>Réf. article</th>
                          <th>Description</th>
                          <th>Quantité</th>
                          <th>Prix Unitaire</th>
                          <th>Prix HT</th>
                          <th>Prix TTC</th>
                        </tr>
                      </thead>
                      <tbody>
                        {statistique?.bonsAchats
                          ?.slice()
                          .sort(
                            (a, b) =>
                              new Date(b.dateCreation) -
                              new Date(a.dateCreation)
                          )
                          .map((bonAchat, index) => (
                            <AccordeonBonsAchats
                              key={index}
                              index={index}
                              bonAchat={bonAchat}
                            />
                          ))}
                      </tbody>
                    </table>
                  </>
                }
              />
            </div>
          </div>
        )}
      </>
    );
  } else
    return (
      <>
        <WarningBar
          active={false}
          content={"Vous n'avez pas le droit de voir cette page"}
        />
      </>
    );
};

export { Statistiques };
