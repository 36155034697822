import React, { useState } from "react";

import { ButtonIcon } from "_components";

import { ToLocaleDateString } from "_utils";
import {
  faMinusSquare,
  faPlusSquare,
  faPen,
} from "@fortawesome/free-solid-svg-icons";

const Accordeontache = (props) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  return (
    <React.Fragment key={props.tache.id}>
      <tr className={props.index % 2 ? "shadowLine" : ""}>
        <td
          className={props.tache.joursRetard ? "bg-warning" : ""}
          style={{ width: "20px" }}
        >
          <ButtonIcon
            icon={isAccordionOpen ? faMinusSquare : faPlusSquare}
            className="btn"
            onClick={() => setIsAccordionOpen(!isAccordionOpen)}
            style={{ padding: "0rem" }}
            iconSize="sm"
          />
        </td>
        <td style={{ maxWidth: "200px" }}>
          {props.tache.statut?.designation ?? "-"}
        </td>
        <td style={{ maxWidth: "250px" }}>
          {ToLocaleDateString(props.tache.dateCreation)}
        </td>
        <td>
          {props.tache.contact ? (
            <a href={"/contacts/" + props.tache.contact.id} target="_blank">
              {props.tache.contact.nom_Contact +
                " " +
                props.tache.contact.prenom_Contact ?? "-"}
            </a>
          ) : (
            "-"
          )}
        </td>
        <td>{props.tache.action?.designation ?? "-"}</td>

        <td>{props.tache.priorite?.designation ?? "-"}</td>

        <td style={{ maxWidth: "200px" }}>
          {props.tache.assigne
            ? props.tache.assigne.nom + " " + props.tache.assigne.prenom
            : "-"}
        </td>
      </tr>
      {isAccordionOpen ? (
        <>
          <tr className={props.index % 2 ? "shadowLine" : ""}>
            <th className={props.tache.joursRetard ? "bg-warning" : ""}></th>
            <th>Date d'échéance</th>
            <th>Retard</th>
            <th>Process</th>
            <th>Date de fin</th>
            <th></th>
          </tr>
          <tr className={props.index % 2 ? "shadowLine" : ""}>
            <td className={props.tache.joursRetard ? "bg-warning" : ""}></td>
            <td>{ToLocaleDateString(props.tache.dateEcheance) ?? "-"}</td>
            <td>
              {props.tache.joursRetard ? props.tache.joursRetard + "j" : "-"}
            </td>
            <td>{props.tache.familleAffaire?.designation ?? "-"}</td>
            <td>{props.tache.process?.designation ?? "-"}</td>
            <td>
              {props.tache.dateFin != null
                ? ToLocaleDateString(props.tache.dateFin)
                : "-"}
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td style={{ textAlign: "right" }} className="pe-2">
              <ButtonIcon
                icon={faPen}
                iconSize="sm"
                tooltip="Editer"
                onClick={() => props?.handleClickEditionTache?.(props.tache.id)}
                className="btn btn-secondary"
              ></ButtonIcon>
            </td>
          </tr>
          <tr className={props.index % 2 ? "shadowLine" : ""}>
            <td className={props.tache.joursRetard ? "bg-warning" : ""}></td>
            <th colSpan={9}>Commentaire</th>
          </tr>
          <tr className={props.index % 2 ? "shadowLine" : ""}>
            <td className={props.tache.joursRetard ? "bg-warning" : ""}></td>
            <td colSpan={9}>
              <span style={{ whiteSpace: "pre-line" }}>
                {props.tache.commentaire ?? "-"}
              </span>
            </td>
          </tr>
        </>
      ) : null}
    </React.Fragment>
  );
};

export { Accordeontache };
