import React from "react";
import { AlertDialog, Box, FormWithValidation, ButtonIcon } from "_components";
import { PropTypes } from "prop-types";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import {
  CiviliteService,
  ContactService,
  FonctionService,
  ClientService,
  ServiceContactService,
  FournisseurService,
} from "_services";

import { Helmet } from "react-helmet";

import { Input } from "_components/Input";
import produce from "immer";
import { ContactDepartementService } from "_services/ContactReferentielService";

class AjoutContact extends React.PureComponent {
  constructor(props) {
    super(props);
    this.isClientLoaded =
      this.props.history.location.state &&
      this.props.history.location.state.client;

    this.isFournisseurLoaded =
      this.props.history.location.state &&
      this.props.history.location.state.fournisseur;
    this.state = {
      client: null,
      errorText: null,
      error: false,
      isSubmitting: false,
      similarContacts: [],
      contact: {
        id: 0,
        n_Contact: 0,
        n_Client: props.history.location.state?.client?.id,
        fournisseurId: props.history.location.state?.fournisseur?.id,
        clientContacts: null,
        nom_Contact: null,
        prenom_Contact: null,
        adresse: {
          intitule: props.history.location.state?.fournisseur
            ? props.history.location.state?.fournisseur?.adresse?.intitule
            : props.history.location.state?.client?.adresse_Siege_Social
                ?.intitule,
          complement: props.history.location.state?.fournisseur
            ? props.history.location.state?.fournisseur?.adresse?.complement
            : props.history.location.state?.client?.adresse_Siege_Social
                ?.complement,
          voie: props.history.location.state?.fournisseur
            ? props.history.location.state?.fournisseur?.adresse?.voie
            : props.history.location.state?.client?.adresse_Siege_Social?.voie,
          ville: props.history.location.state?.fournisseur
            ? props.history.location.state?.fournisseur?.adresse?.ville
            : props.history.location.state?.client?.adresse_Siege_Social?.ville,
          pays: props.history.location.state?.fournisseur
            ? props.history.location.state?.fournisseur?.adresse?.pays
            : props.history.location.state?.client?.adresse_Siege_Social?.pays,
          region:
            props.history.location.state?.client?.adresse_Siege_Social?.region,
          cp: props.history.location.state?.fournisseur
            ? props.history.location.state?.fournisseur?.adresse?.cp
            : props.history.location.state?.client?.adresse_Siege_Social?.cp,
          nomContact: null,
          email: null,
          telephone: null,
          fax: null,
        },
        email: null,
        standard: null,
        ligne_Directe: null,
        portable: null,
        fax: null,
        civilite: null,
        date_Create: null,
        date_Modif: null,
        user_Create: null,
        user_Modif: null,
        voeux: true,
        newsletter: true,
        vip: true,
        fonction: null,
        service: null,
        a_Supprimer: false,
        ligne2: null,
        email2: null,
        commentaire: null,
      },
    };

    this.setState = this.setState.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseConfirmation = this.handleCloseConfirmation.bind(this);
    this.FournisseurProxy = this.FournisseurProxy.bind(this);
    this.form = this.form.bind(this);

    this.handleClickOnCreationButton =
      this.handleClickOnCreationButton.bind(this);
  }

  componentDidMount() {
    this.setState({
      client: this.props.history.location.state
        ? this.props.history.location.state.client
        : null,
      fournisseur: this.props.history.location.state
        ? this.props.history.location.state.fournisseur
        : null,
    });
  }

  handleClickOnCreationButton() {
    if (this.state.similarContacts?.length != 0)
      this.setState({ alertOpened: true });
  }

  getListOfSimilarContacts() {
    if (
      this.state.contact.email != null ||
      this.state.contact.standard != null ||
      this.state.contact.nom_Contact ||
      this.state.contact.prenom_Contact ||
      this.state.contact.portable
    )
      ContactService.getAllSimilaire({
        size: 10,
        email: this.state.contact.email,
        standard: this.state.contact.standard,
        nom_Contact: this.state.contact.nom_Contact,
        prenom_Contact: this.state.contact.prenom_Contact,
        portable: this.state.contact.portable,
        actif: true,
      }).then((res) => {
        let data = res.data && res.data.datas ? res.data.datas : res.data;
        let contactData = data.map((contact) => ({
          id: contact.id,
          nom: contact.nom_Contact,
          prenom: contact.prenom_Contact,
          civilite: contact.civilite,
          ville: contact.adresse?.ville,
          cp: contact.adresse?.cp,
          email: contact.email,
          telephone: contact.standard,
        }));
        this.setState((prevState) => ({
          ...prevState,
          similarContacts: contactData,
        }));
      });
    else {
      this.setState((prevState) => ({
        ...prevState,
        similarContacts: [],
      }));
    }
  }

  handleChange(accessor, value, afterUpdateFunction = null) {
    if (accessor === "client") {
      this.setState(
        produce((prevState) => {
          prevState.contact.n_Client = value?.id;
          prevState.client = value;
          prevState.contact.adresse.intitule =
            value?.adresse_Siege_Social?.intitule;
          prevState.contact.adresse.complement =
            value?.adresse_Siege_Social?.complement;
          prevState.contact.adresse.voie = value?.adresse_Siege_Social?.voie;
          prevState.contact.adresse.ville = value?.adresse_Siege_Social?.ville;
          prevState.contact.adresse.pays = value?.adresse_Siege_Social?.pays;
        }, afterUpdateFunction)
      );
    } else if (accessor === "fournisseur") {
      this.setState(
        produce((prevState) => {
          prevState.contact.fournisseurId = value?.id;
          prevState.fournisseur = value;
          prevState.contact.adresse.intitule = value?.adresse?.intitule;
          prevState.contact.adresse.complement = value?.adresse?.complement;
          prevState.contact.adresse.voie = value?.adresse?.voie;
          prevState.contact.adresse.ville = value?.adresse?.ville;
          prevState.contact.adresse.pays = value?.adresse?.pays;

          if (prevState.contact.n_Client == null) {
            prevState.contact.voeux = false;
            prevState.contact.vip = false;
            prevState.contact.newsletter = false;
          }
        }, afterUpdateFunction)
      );
    } else if (accessor.includes(".")) {
      var accessorSplit = accessor.split(".");

      // Nested property (separated by a '.')
      this.setState(
        produce((prevState) => {
          prevState.contact[accessorSplit[0]][accessorSplit[1]] = value;
        }),
        afterUpdateFunction
      );
    } else {
      // Shallow property
      this.setState(
        produce((prevState) => {
          prevState.contact[accessor] = value;
        }),
        afterUpdateFunction
      );
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    let clientloaded = this.isClientLoaded;
    ContactService.post(this.state.contact)
      .then((res) => {
        if (!clientloaded) {
          this.props.history.push("/contacts/" + res.data.id);
        } else if (this.isFournisseurLoaded) {
          this.props.history.push("/fournisseurs/" + this.state.fournisseur.id);
        } else {
          this.props.history.push("/clients/" + this.state.client.id);
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          error: true,
          isSubmitting: false,
          errorText: error.message,
        });
      });
  }

  handleClose() {
    this.setState({ error: false });
  }

  handleCloseConfirmation() {
    this.setState({ alertOpened: false });
  }

  FournisseurProxy(search) {
    return FournisseurService.getAll({
      orderBy: "nom",
      order: "ASC",
      nom: search,
      size: 20,
      actif: true,
    });
  }

  form() {
    const informationsClient = [
      {
        label: "Client",
        value: this.state.client,
        accessor: "client",
        type: "selectSearch",
        service: ClientService.getForSelectSearch,
        optionFieldToDisplay: "nom_Client",
        valueFieldToDisplay: "nom_Client",
        required: this.state.fournisseur == null,
        invalidText: "Vous devez sélectionner un client.",
      },
      {
        label: "Fournisseur",
        value: this.state.fournisseur,
        accessor: "fournisseur",
        type: "selectSearch",
        service: this.FournisseurProxy,
        optionFieldToDisplay: "nom",
        valueFieldToDisplay: "nom",
        required: this.state.client == null,
        invalidText: "Vous devez sélectionner un fournisseur.",
      },
    ];
    const informationsPersonnelles = [
      {
        label: "Civilité",
        value: this.state.contact.civilite,
        accessor: "civilite",
        type: "selectSearch",
        service: CiviliteService.getAll,
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        invalidText: "Vous devez sélectionner une civilité.",
      },
      {
        label: "Nom",
        value: this.state.contact.nom_Contact,
        accessor: "nom_Contact",
        required: true,
        invalidText: "Vous devez entrer un nom.",
        afterUpdateFunction: this.getListOfSimilarContacts,
      },
      {
        label: "Prénom",
        value: this.state.contact.prenom_Contact,
        accessor: "prenom_Contact",
        invalidText: "Vous devez entrer un prénom.",
        afterUpdateFunction: this.getListOfSimilarContacts,
      },
      {
        label: "Fonction",
        value: this.state.contact.fonction,
        accessor: "fonction",
        type: "selectSearch",
        service: FonctionService.getAll,
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        invalidText: "Vous devez sélectionner une fonction.",
      },
      {
        label: "Département",
        accessor: "departement",
        type: "selectSearch",
        functionAppliedToValue: (value) => {
          return value ? value.designation : null;
        },
        service: ContactDepartementService.getAll,
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        invalidText: "Vous devez sélectionner un service.",
      },
      {
        label: "Service",
        accessor: "service",
        type: "selectSearch",
        functionAppliedToValue: (value) => {
          return value ? value.designation : null;
        },
        service: ServiceContactService.getAll,
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        invalidText: "Vous devez sélectionner un service.",
      },
      {
        label: "Commentaire",
        value: this.state.contact.commentaire,
        accessor: "commentaire",
        type: "textArea",
      },
    ];
    const informationsContact = [
      {
        label: "Email",
        value: this.state.contact.email,
        accessor: "email",
        afterUpdateFunction: this.getListOfSimilarContacts,
        type: "email",
      },
      {
        label: "Email bis",
        value: this.state.contact.email2,
        accessor: "email2",
        type: "email",
      },
      {
        label: "Ligne directe",
        value: this.state.contact.ligne_Directe,
        accessor: "ligne_Directe",
      },
      {
        label: "Ligne bis",
        value: this.state.contact.ligne2,
        accessor: "ligne2",
      },
      {
        label: "Standard",
        value: this.state.contact.standard,
        accessor: "standard",
        afterUpdateFunction: this.getListOfSimilarContacts,
      },

      {
        label: "Portable",
        value: this.state.contact.portable,
        accessor: "portable",
        afterUpdateFunction: this.getListOfSimilarContacts,
      },

      {
        label: "LinkedIn",
        accessor: "linkedIn",
        value: this.state.contact.linkedIn,
        type: "text",
      },
    ];
    const informationsService =
      this.state.contact.n_Client != null
        ? [
            {
              label: "Newsletter",
              value: this.state.contact.newsletter,
              accessor: "newsletter",
              type: "checkbox",
            },

            {
              label: "Informations gratuites",
              value: this.state.contact.voeux,
              accessor: "voeux",
              type: "checkbox",
            },

            {
              label: "Comm. commerciales",
              value: this.state.contact.vip,
              accessor: "vip",
              type: "checkbox",
            },
          ]
        : [];

    const informationsUtilisateur = [
      {
        label: "Apprenant",
        value: this.state.contact.apprenant,
        accessor: "apprenant",
        type: "checkbox",
      },

      {
        label: "Geoshare",
        value: this.state.contact.userGeoshare,
        accessor: "userGeoshare",
        type: "checkbox",
      },

      {
        label: "Materiel",
        value: this.state.contact.userMateriel,
        accessor: "userMateriel",
        type: "checkbox",
      },

      {
        label: "WEBsprint",
        value: this.state.contact.userWEBSprint,
        accessor: "userWEBSprint",
        type: "checkbox",
      },
    ];
    const informationsAdresse = [
      {
        label: "Adresse",
        value: this.state.contact.adresse,
        accessor: "adresse",
        type: "address",
        requiredPays: true,
      },
    ];
    const infos = [
      ...(this.state.contact.fournisseurId || this.state.contact.n_Client
        ? [
            {
              info: informationsPersonnelles,
              titre: "Informations personnelles",
            },

            { info: informationsContact, titre: "Prise de contact" },
            { info: informationsAdresse, titre: "Adresse" },
          ]
        : []),
      ...(this.state.contact.n_Client
        ? [
            { info: informationsService, titre: "Abonnements" },
            { info: informationsUtilisateur, titre: "Compétence utilisateur" },
          ]
        : []),
    ];

    let nbEmailSimilaire = 0;
    nbEmailSimilaire = this.state.similarContacts
      .map((e) => e.email)
      .filter((email) => email == this.state.contact.email).length;
    return (
      <>
        <FormWithValidation onSubmit={this.handleSubmit}>
          {!this.isClientLoaded ? (
            <Box
              header={
                <>
                  <div className="col-12 align-self-center py-2">
                    <div>Informations sur le client/fournisseur</div>
                  </div>
                </>
              }
              body={
                <>
                  {informationsClient.map((field, fieldsIndex) => {
                    return (
                      <div className="col-lg-4 pt-2" key={fieldsIndex}>
                        <Input
                          label={field.label} // Titre du champ
                          value={field.value} // Valeur du champ
                          accessor={field.accessor} // Accesseur
                          placeholder={field.placeholder}
                          debounce={field.debounce}
                          service={field.service}
                          handleChange={(acc, val) =>
                            this.handleChange(
                              acc,
                              val,
                              field.afterUpdateFunction
                            )
                          } // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                          handleBlur={field.handleBlur} // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                          type={field.type} // Type de l'input a utiliser
                          options={field.options} // options de l'input
                          optionFieldToDisplay={field.optionFieldToDisplay} // champ de l'objet a afficher
                          optionFieldToReturn={field.optionFieldToReturn} // champd de l'objet a utiliser en tant que valeur
                          valueFieldToDisplay={field.valueFieldToDisplay}
                          required={field.required}
                          disabled={field.disabled}
                          validText={field.validText}
                          invalidText={field.invalidText}
                        />
                      </div>
                    );
                  })}
                </>
              }
            />
          ) : null}

          {this.state.contact.fournisseurId || this.state.contact.n_Client ? (
            <div>
              <Box
                header={
                  <div className="col-12 align-self-center py-2">
                    <div>Contacts similaires (10 au maximum sont affichés)</div>
                  </div>
                }
                body={
                  <table className="mt-1 text-uppercase table table-bordered table-striped table-sm">
                    <thead>
                      <tr>
                        <th>Nom</th>
                        <th>Prénom </th>
                        <th></th>
                        <th>Ville</th>
                        <th>Code postal</th>
                        <th>Email</th>
                        <th>Standard</th>
                      </tr>
                    </thead>
                    <tbody className="text-muted">
                      {this.state.similarContacts.map((contact) => (
                        <tr key={contact.id}>
                          <td>{contact.nom}</td>
                          <td>{contact.prenom}</td>

                          <td>
                            <ButtonIcon
                              icon={faExternalLinkAlt}
                              className="btn btn-secondary btn-sm text-light ms-1"
                              onClick={() =>
                                window.open("/contacts/" + contact.id, "_blank")
                              }
                            ></ButtonIcon>
                          </td>
                          <td>{contact.ville}</td>
                          <td>{contact.cp}</td>
                          <td>{contact.email}</td>
                          <td>{contact.telephone}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                }
              />
            </div>
          ) : null}
          {infos.map((infoItem, index) => {
            return (
              <div className="mb-4" key={index}>
                <Box
                  header={
                    <>
                      <div className="col-12 align-self-center py-2">
                        <div>{infoItem.titre}</div>
                      </div>
                    </>
                  }
                  body={
                    <>
                      {infoItem.info.map((field, fieldsIndex) => {
                        return (
                          <div className="col-lg-4 pt-2" key={fieldsIndex}>
                            <Input
                              label={field.label} // Titre du champ
                              value={field.value} // Valeur du champ
                              accessor={field.accessor} // Accesseur
                              placeholder={field.placeholder}
                              debounce={field.debounce}
                              service={field.service}
                              handleChange={(acc, val) =>
                                this.handleChange(
                                  acc,
                                  val,
                                  field.afterUpdateFunction
                                )
                              } // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                              handleBlur={field.handleBlur} // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                              type={field.type} // Type de l'input a utiliser
                              options={field.options} // options de l'input
                              optionFieldToDisplay={field.optionFieldToDisplay} // champ de l'objet a afficher
                              optionFieldToReturn={field.optionFieldToReturn} // champd de l'objet a utiliser en tant que valeur
                              valueFieldToDisplay={field.valueFieldToDisplay}
                              required={field.required}
                              disabled={field.disabled}
                              validText={field.validText}
                              invalidText={field.invalidText}
                              requiredPays={field.requiredPays}
                            />
                          </div>
                        );
                      })}
                    </>
                  }
                />
              </div>
            );
          })}

          <div className="text-center">
            <div>
              {nbEmailSimilaire != 0
                ? "Un contact avec le même mail existe déjà."
                : null}
            </div>
            <ButtonIcon
              className="btn btn-success mt-4 w-25"
              onClick={this.handleClickOnCreationButton}
              textOption="Créer le contact"
              type={
                this.state.similarContacts?.length == 0 ? "submit" : "button"
              }
              disabled={this.state.isSubmitting || nbEmailSimilaire != 0}
            />
            <AlertDialog
              title="Erreur"
              body={
                <>
                  <div>
                    Un ou des contacts similaires existent (même téléphone, ou
                    mail), êtes-vous sûr de vouloir créer ce contact ?
                  </div>
                </>
              }
              valider="Ok"
              open={this.state.alertOpened}
              handleClose={this.handleCloseConfirmation}
              onClick={() => {
                document.getElementById("confirmationAjoutContact").click();
                this.handleCloseConfirmation();
              }}
            />
            {this.state.similarContacts?.length != 0 &&
            this.state.alertOpened ? (
              <div className="disable">
                <ButtonIcon
                  className="btn btn-success mt-4 w-25"
                  textOption="Confirmer"
                  type="submit"
                  disabled={this.state.isSubmitting}
                  id="confirmationAjoutContact"
                />
              </div>
            ) : null}
          </div>
        </FormWithValidation>
      </>
    );
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Création d'un contact</title>
        </Helmet>
        <AlertDialog
          title="Une erreur a été rencontrée"
          body={
            <>
              <div>La création du contact a échouée.</div>
              <div>{this.state.errorText}</div>
            </>
          }
          valider="Ok"
          open={this.state.error}
          handleClose={this.handleClose}
        />
        <div className="p-3 m-4" id="ajoutContact">
          <div className="d-flex overflow-auto justify-content-between py-1">
            <h2 className="nowrap pr-100 solwayFont">
              Création d&apos;un contact
              {this.state.client
                ? " pour " + this.state.client.nom_Client
                : null}
            </h2>
            <div>
              {this.props.history.length > 1 && (
                <button
                  className="btn btn-primary nowrap mx-1"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  Retour
                </button>
              )}
            </div>
          </div>
          <div className="row mt-4">{this.form()}</div>
        </div>
      </>
    );
  }
}

AjoutContact.propTypes = {
  history: PropTypes.object,
};

export { AjoutContact };
