import http from "http-common";

const FournisseurCritereService = class {
  /**
   * Fonction permettant de récuperer tous les EtatAvancement avec une possibilité de filtre via params
   * @returns la liste de tous les EtatAvancement
   */
  static getAll(params) {
    let url = "/FournisseurCriteres";

    //Gestion de la valeur par défaut de la propriété "isActive" des paramètres
    if (!params) return http.get(url, { params: { isActive: true } });
    else if (typeof params === "object" && params.isActive === undefined)
      return http.get(url, { params: { ...params, isActive: true } });
    else if (typeof params === "object" && params.isActive !== undefined)
      return http.get(url, { params });
    else return http.get(url, { params: { isActive: true } });
  }
};

const FournisseurDomaineService = class {
  /**
   * Fonction permettant de récuperer tous les EtatAvancement avec une possibilité de filtre via params
   * @returns la liste de tous les EtatAvancement
   */
  static getAll(params) {
    let url = "/FournisseurDomaines";

    //Gestion de la valeur par défaut de la propriété "isActive" des paramètres
    if (!params) return http.get(url, { params: { isActive: true } });
    else if (typeof params === "object" && params.isActive === undefined)
      return http.get(url, { params: { ...params, isActive: true } });
    else if (typeof params === "object" && params.isActive !== undefined)
      return http.get(url, { params });
    else return http.get(url, { params: { isActive: true } });
  }
};

const FournisseurQualificationService = class {
  /**
   * Fonction permettant de récuperer tous les EtatAvancement avec une possibilité de filtre via params
   * @returns la liste de tous les EtatAvancement
   */
  static getAll(params) {
    let url = "/FournisseurQualifications";

    //Gestion de la valeur par défaut de la propriété "isActive" des paramètres
    if (!params) return http.get(url, { params: { isActive: true } });
    else if (typeof params === "object" && params.isActive === undefined)
      return http.get(url, { params: { ...params, isActive: true } });
    else if (typeof params === "object" && params.isActive !== undefined)
      return http.get(url, { params });
    else return http.get(url, { params: { isActive: true } });
  }
};

const FournisseurUniteService = class {
  static cache = null;
  static loading = true;

  /**
   * Fonction permettant de récuperer tous les EtatAvancement avec une possibilité de filtre via params
   * @returns la liste de tous les EtatAvancement
   */
  static getAll(params, refresh) {
    //Gestion de la valeur par défaut de la propriété "isActive" des paramètres
    let reqParams;
    if (!params) {
      // null
      reqParams = { isActive: true };
    } else if (typeof params === "object" && params.isActive === undefined) {
      // Objet ne contenant pas isActive
      reqParams = { ...params, isActive: true };
    } else if (typeof params === "object" && params.isActive !== undefined) {
      // Objet contenant isActive (null/true/false)
      reqParams = params;
    } else {
      reqParams = { isActive: true };
    }

    //On a des params (autres que isActive) ou isActive !=true => On ne veut pas les données stockées en cache.
    if (!(Object.keys(reqParams).length == 1) || reqParams.isActive !== true)
      return FournisseurUniteService.get(reqParams);

    if (refresh == true || FournisseurUniteService.loading) {
      FournisseurUniteService.cache = FournisseurUniteService.get({
        isActive: true,
      });
      FournisseurUniteService.loading = false;
    }
    return FournisseurUniteService.cache;
  }

  static get(params) {
    return http.get("/FournisseurUnites", { params });
  }
};

export {
  FournisseurCritereService,
  FournisseurDomaineService,
  FournisseurQualificationService,
  FournisseurUniteService,
};
