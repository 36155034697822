import React from "react";
import {
  ReactTableServerSide,
  IndeterminateCheckbox,
  CellWithRedirection,
} from "_components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import { RedirectTo } from "_utils";
import { PropTypes } from "prop-types";
import { CarteBancaireService, RubriqueCarteBancaireService } from "_services/";
import { Helmet } from "react-helmet";

class BaseCB extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Base Carte Bancaire</title>
        </Helmet>
        <ReactTableServerSide
          title="Base Carte Bancaire"
          titreFiltre="Base Carte Bancaire"
          service={CarteBancaireService.getAll}
          module="Cartes bleues"
          redirectToCreate={() => {
            RedirectTo(this.props.history, "/cartesbancaires/add");
          }}
          orderByDefault="id"
          orderDefault="ASC"
          localStorageKeyColumns="BaseCBColumns"
          columns={[
            {
              id: "selection",
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div className="text-center align-middle" id="selectAll">
                  <IndeterminateCheckbox
                    className="align-middle"
                    {...getToggleAllRowsSelectedProps()}
                  />
                </div>
              ),
              Cell: ({ row }) => (
                <div className="text-center align-middle" id="selectOne">
                  <IndeterminateCheckbox
                    className="align-middle"
                    {...row.getToggleRowSelectedProps()}
                  />
                </div>
              ),
            },
            {
              Header: "Nom",
              accessor: "name",
              search: "name",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "180px",
              type: "text",
              Cell: (row) => {
                return (
                  <CellWithRedirection
                    history={this.props.history}
                    redirection="/cartesbancaires/"
                    id={row.cell.row.original.id}
                    value={row.cell.value}
                  />
                );
              },
            },
            {
              Header: "Nom detailé",
              hideable: true,
              sortable: true,
              exportable: true,
              search: "nameDetail",

              accessor: "nameDetail",
              minWidth: "180px",
              type: "text",
            },
            {
              Header: "Compte tier",
              hideable: true,
              sortable: true,
              exportable: true,
              search: "compteTier",

              accessor: "compteTiers",
              type: "text",
              minWidth: "180px",
            },
            {
              Header: "CC Num",
              hideable: true,
              sortable: true,
              exportable: true,
              accessor: "cC_Num",
              search: "ccNum",

              type: "text",
              minWidth: "180px",
            },
            {
              Header: "JO Num",
              hideable: true,
              sortable: true,
              exportable: true,
              accessor: "jO_Num",
              type: "selectSearch",
              valueFieldToDisplay: ["rubrique"],
              optionFieldToReturn: "rubrique",
              optionFieldToDisplay: ["rubrique"],
              service: RubriqueCarteBancaireService.getAll,
              minWidth: "180px",
              search: "joNum",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.rubrique}</span>
                ) : null;
              },
            },
            {
              Header: "Actif",
              accessor: "actif",
              defaultValue: {
                id: 0,
                display: "Oui",
                value: true,
              },
              hideable: true,
              sortable: true,
              exportable: true,
              search: "actif",
              minWidth: "130px",
              type: "selectSearch",
              optionFieldToReturn: "value",
              optionFieldToDisplay: "display",
              valueFieldToDisplay: "display",
              options: [
                {
                  id: 0,
                  display: "Oui",
                  value: true,
                },
                {
                  id: 1,
                  display: "Non",
                  value: false,
                },
              ],
              Cell: (row) => {
                if (row.cell.value == true) {
                  return <div>Oui</div>;
                } else {
                  return (
                    <div>
                      Non
                      <FontAwesomeIcon
                        className="text-danger"
                        icon={faExclamationTriangle}
                      />
                    </div>
                  );
                }
              },
            },
          ]}
        />
      </>
    );
  }
}

BaseCB.propTypes = {
  history: PropTypes.object,
};

export { BaseCB };
