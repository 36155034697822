import React from "react";
import { PropTypes } from "prop-types";
import { App } from "App";

import { BoxInformationsInline, Box, WarningBar } from "_components";
import {
  LangueService,
  TypeLigneService,
  OrigineService,
  ProduitInterneService,
} from "_services";

import { AccordionParAnnee } from "./SubTab/AccordionParAnnee";
import { AccordionParAnneeMOE } from "./SubTab/AccordionParAnneeMOE";

import { Input } from "_components/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

class StatistiqueClient extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight("Client", "Stat");
    if (right == "RW" || right == "RO") {
      // On crée la hierarchie des documents à afficher :
      // Devis : Chaque devis qui vient des commandes du client.
      //    Commandes : Les commandes associées à chaque devis.
      //        Factures : Les factures (plusieurs en cas d'avoir) associées à chaque commande.
      // TODO : différencier les factures d'avoir des factures classiques.
      if (this.props.loadingSynthese)
        return (
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon icon={faSpinner} size="lg" className="fa-spin" />
          </div>
        );
      var arrDevisIds = [];
      var arrDevis = [];
      var arrCommandesSansDevis = [];
      var arrFacturesIds = [];
      this.props.statClient?.commandes?.forEach((c) => {
        var facturesLiees = this.props.statClient?.factures.filter(
          (f) => f.ref_BC == c.reference
        );
        facturesLiees.forEach((f) => arrFacturesIds.push(f.num_Facture));

        if (c.devis != null) {
          if (!arrDevisIds.includes(c.devis.id)) {
            arrDevisIds.push(c.devis.id);
            arrDevis.push({
              ...c.devis,
              commandes: [
                {
                  ...c,
                  factures: facturesLiees,
                },
              ],
            });
          } else {
            arrDevis[
              arrDevis.findIndex((d) => d.id == c.devis.id)
            ].commandes.push({
              ...c,
              factures: facturesLiees,
            });
          }
        } else {
          arrCommandesSansDevis.push({
            ...c,
            factures: facturesLiees,
          });
        }
      });

      var arrFacturesSansCommandes = this.props.statClient?.factures?.filter(
        (f) => !arrFacturesIds.includes(f.num_Facture)
      );
      this.props.statClient?.devisSansCommande?.forEach((e) =>
        arrDevis.push({ ...e, commandes: [] })
      );

      arrDevis.sort(
        (a, b) => new Date(b.date_Create) - new Date(a.date_Create)
      );
      arrDevis.forEach((d) =>
        d.commandes.sort(
          (a, b) => new Date(b.date_Create) - new Date(a.date_Create)
        )
      );
      arrDevis.forEach((d) =>
        d.commandes.forEach((c) =>
          c.factures.sort(
            (a, b) => new Date(b.dateCreation) - new Date(a.dateCreation)
          )
        )
      );

      arrCommandesSansDevis.sort(
        (a, b) => new Date(b.date_Create) - new Date(a.date_Create)
      );
      arrCommandesSansDevis.forEach((c) =>
        c.factures.sort(
          (a, b) => new Date(b.dateCreation) - new Date(a.dateCreation)
        )
      );

      arrFacturesSansCommandes.sort(
        (a, b) => new Date(b.dateCreation) - new Date(a.dateCreation)
      );

      var arrDocumentParAnnee = {};

      arrDevis.forEach((devis) =>
        arrDocumentParAnnee[new Date(devis.date_Create).getFullYear()]
          ? arrDocumentParAnnee[new Date(devis.date_Create).getFullYear()]
              ?.devis
            ? arrDocumentParAnnee[
                new Date(devis.date_Create).getFullYear()
              ].devis.push(devis)
            : (arrDocumentParAnnee[
                new Date(devis.date_Create).getFullYear()
              ].devis = [devis])
          : (arrDocumentParAnnee[new Date(devis.date_Create).getFullYear()] = {
              annee: new Date(devis.date_Create).getFullYear(),
              devis: [devis],
              commandes: [],
              factures: [],
            })
      );

      arrCommandesSansDevis.forEach((commande) =>
        arrDocumentParAnnee[new Date(commande.date_Create).getFullYear()]
          ? arrDocumentParAnnee[new Date(commande.date_Create).getFullYear()]
              ?.commandes
            ? arrDocumentParAnnee[
                new Date(commande.date_Create).getFullYear()
              ].commandes.push(commande)
            : (arrDocumentParAnnee[
                new Date(commande.date_Create).getFullYear()
              ].commandes = [commande])
          : (arrDocumentParAnnee[new Date(commande.date_Create).getFullYear()] =
              {
                annee: new Date(commande.date_Create).getFullYear(),
                devis: [],
                commandes: [commande],
                factures: [],
              })
      );

      arrFacturesSansCommandes.forEach((facture) =>
        arrDocumentParAnnee[new Date(facture.dateCreation).getFullYear()]
          ? arrDocumentParAnnee[new Date(facture.dateCreation).getFullYear()]
              ?.factures
            ? arrDocumentParAnnee[
                new Date(facture.dateCreation).getFullYear()
              ].factures.push(facture)
            : (arrDocumentParAnnee[
                new Date(facture.dateCreation).getFullYear()
              ].factures = [facture])
          : (arrDocumentParAnnee[new Date(facture.dateCreation).getFullYear()] =
              {
                annee: new Date(facture.dateCreation).getFullYear(),
                devis: [],
                commandes: [],
                factures: [facture],
              })
      );

      // On crée la hierarchie des documents à afficher :
      // Devis : Chaque devis qui vient des commandes du client.
      //    Commandes : Les commandes associées à chaque devis.

      var arrDevisIdsMOE = [];
      var arrDevisMOE = [];
      var arrCommandesSansDevisMOE = [];
      this.props.statClient?.commandesMOE?.forEach((c) => {
        if (c.devis != null) {
          if (!arrDevisIdsMOE.includes(c.devis.id)) {
            arrDevisIdsMOE.push(c.devis.id);
            arrDevisMOE.push({
              ...c.devis,
              commandes: [
                {
                  ...c,
                },
              ],
            });
          } else {
            arrDevisMOE[
              arrDevisMOE.findIndex((d) => d.id == c.devis.id)
            ].commandes.push({
              ...c,
            });
          }
        } else {
          arrCommandesSansDevisMOE.push({
            ...c,
          });
        }
      });

      this.props.statClient?.devisMOESansCommande?.forEach((e) =>
        arrDevisMOE.push({ ...e, commandes: [] })
      );

      arrDevisMOE.sort(
        (a, b) => new Date(b.date_Create) - new Date(a.date_Create)
      );
      arrDevisMOE.forEach((d) =>
        d.commandes.sort(
          (a, b) => new Date(b.date_Create) - new Date(a.date_Create)
        )
      );

      arrCommandesSansDevisMOE.sort(
        (a, b) => new Date(b.date_Create) - new Date(a.date_Create)
      );

      var arrDocumentParAnneeMOE = {};

      arrDevisMOE.forEach((devis) =>
        arrDocumentParAnneeMOE[new Date(devis.date_Create).getFullYear()]
          ? arrDocumentParAnneeMOE[new Date(devis.date_Create).getFullYear()]
              ?.devis
            ? arrDocumentParAnneeMOE[
                new Date(devis.date_Create).getFullYear()
              ].devis.push(devis)
            : (arrDocumentParAnneeMOE[
                new Date(devis.date_Create).getFullYear()
              ].devis = [devis])
          : (arrDocumentParAnneeMOE[new Date(devis.date_Create).getFullYear()] =
              {
                annee: new Date(devis.date_Create).getFullYear(),
                devis: [devis],
                commandes: [],
              })
      );

      arrCommandesSansDevisMOE.forEach((commande) =>
        arrDocumentParAnneeMOE[new Date(commande.date_Create).getFullYear()]
          ? arrDocumentParAnneeMOE[new Date(commande.date_Create).getFullYear()]
              ?.commandes
            ? arrDocumentParAnneeMOE[
                new Date(commande.date_Create).getFullYear()
              ].commandes.push(commande)
            : (arrDocumentParAnneeMOE[
                new Date(commande.date_Create).getFullYear()
              ].commandes = [commande])
          : (arrDocumentParAnneeMOE[
              new Date(commande.date_Create).getFullYear()
            ] = {
              annee: new Date(commande.date_Create).getFullYear(),
              devis: [],
              commandes: [commande],
            })
      );

      const informationsStatistique = [
        {
          label: "Catégorie",
          accessor: "type_Client",
          functionAppliedToValue: (value) => {
            return value && value.categorie ? value.categorie.type : null;
          },
          colSize: 2,
        },
        {
          label: "Langue",
          accessor: "langue",
          functionAppliedToValue: (value) => {
            return value ? value.langage : null;
          },
          type: "selectSearch",
          service: LangueService.getAll,
          optionFieldToDisplay: "langage",
          valueFieldToDisplay: "langage",
          colSize: 2,
        },
        {
          value: this.props.client.adresse_Siege_Social?.region,
          label: "Région",
          accessor: "adresse_Siege_Social.region",
          functionAppliedToValue: (value) => {
            return value ? value.nouvelle_Region : null;
          },
          colSize: 2,
        },
        {
          value: this.props.client.adresse_Siege_Social?.region,
          label: "Région SNCF",
          accessor: "adresse_Siege_Social.region",
          functionAppliedToValue: (value) => {
            return value ? value.region_Sncf : null;
          },
          colSize: 2,
        },
        {
          label: "Type ligne",
          accessor: "type_Ligne",
          functionAppliedToValue: (value) => {
            return value ? value.type : null;
          },
          type: "selectSearch",
          service: TypeLigneService.getAll,
          optionFieldToDisplay: "type",
          valueFieldToDisplay: "type",
          colSize: 4,
        },
        {
          label: "Origine",
          accessor: "origine",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          type: "selectSearch",
          service: OrigineService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          colSize: 2,
        },
        {
          label: "Prescripteur",
          accessor: "commentairePrescripteur",
          type: "text",
          colSize: 2,
        },
      ];
      return (
        <>
          <div style={{ display: "flex", marginBottom: "15px" }}>
            <div className="d-flex flex-row align-items-center me-2">
              <div
                style={{
                  flexShrink: "0",
                }}
              >
                Nombre d'années affichées :
              </div>
              <Input
                type="decimal"
                numberOfDecimals={0}
                handleBlur={this.props.handleChange}
                accessor={"anneeStatistique"}
                value={this.props.anneeStatistique}
                tooltip={
                  <div>
                    Attention, plus le nombre est élevé, plus le temps de
                    chargement sera long.
                  </div>
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <Box
                noPaddingBottom={true}
                header={
                  <div className="col-12 align-self-center py-2">
                    <div>Synthèse</div>
                  </div>
                }
                body={
                  <>
                    <div style={{ width: "25%", marginBottom: "15px" }}>
                      <Input
                        label="Produits internes"
                        value={this.props.produitInternes}
                        accessor="produitInternesSynthese"
                        type="multipleSelect"
                        service={ProduitInterneService.getAll}
                        optionFieldToDisplay="designation"
                        colSize={8}
                        handleChange={this.props.handleChange}
                      />
                    </div>
                    <table className="table table-sm table-striped table-bordered">
                      <thead
                        className={
                          "sticky-top" +
                          (this.props.statClient?.synthese?.length == 0
                            ? " theadBorderRadius"
                            : "")
                        }
                      >
                        <tr>
                          <th>Année</th>
                          <th>CA Devis</th>
                          <th>CA Commandes</th>
                          <th>CA Factures</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.statClient?.synthese?.map((d, index) => {
                          return (
                            <tr key={index}>
                              <td>{d.annee}</td>
                              <td>{d.sommeDevis}</td>
                              <td>{d.sommeCommandes}</td>
                              <td>{d.sommeFactures}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                }
              />
            </div>
            <div className="col-md-12 col-lg-12">
              <BoxInformationsInline
                title="Statistiques"
                datas={informationsStatistique}
                state={this.props.client}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                handleUpdate={this.props.handleUpdate}
                NoEdition={right == "RO"}
              />
            </div>
            <div className="col-12">
              <Box
                noPaddingBottom={true}
                header={
                  <div className="col-12 align-self-center py-2">
                    <div>Devis/Commandes/Factures</div>
                  </div>
                }
                body={
                  <table
                    id="tableStat"
                    className="table table-sm table-striped table-bordered m-0"
                  >
                    <thead
                      className={
                        "sticky-top" +
                        (Object.entries(arrDocumentParAnnee)?.length == 0
                          ? " theadBorderRadius"
                          : "")
                      }
                    >
                      <tr>
                        <th>Année</th>
                        <th>Document</th>
                        <th>Date de création</th>
                        <th>Prix HT</th>
                        <th>Montant Reglé</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(arrDocumentParAnnee)
                        .sort((a, b) => {
                          return b[0] - a[0];
                        })
                        .map(function ([key, value], index) {
                          return (
                            <AccordionParAnnee
                              key={key}
                              annee={key}
                              isInitiallyOpen={index == 0}
                              devis={value.devis}
                              commandes={value.commandes}
                              factures={value.factures}
                            />
                          );
                        })}
                    </tbody>
                  </table>
                }
              />
            </div>
            <div className="col-12">
              <Box
                noPaddingBottom={true}
                header={
                  <div className="col-12 align-self-center py-2">
                    <div>Devis/Commandes MOE</div>
                  </div>
                }
                body={
                  <table
                    id="tableStat"
                    className="table table-sm table-striped table-bordered"
                  >
                    <thead
                      className={
                        "sticky-top" +
                        (Object.entries(arrDocumentParAnneeMOE)?.length == 0
                          ? " theadBorderRadius"
                          : "")
                      }
                    >
                      <tr>
                        <th>Année</th>
                        <th>Document</th>
                        <th>Date de création</th>
                        <th>Prix HT</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(arrDocumentParAnneeMOE)
                        .sort((a, b) => {
                          return b[0] - a[0];
                        })
                        .map(function ([key, value], index) {
                          return (
                            <AccordionParAnneeMOE
                              key={key}
                              annee={key}
                              isInitiallyOpen={index == 0}
                              devis={value.devis}
                              commandes={value.commandes}
                            />
                          );
                        })}
                    </tbody>
                  </table>
                }
              />
            </div>
          </div>
        </>
      );
    } else
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
  }
}

StatistiqueClient.propTypes = {
  client: PropTypes.object.isRequired,
  stateFieldNameToUpdate: PropTypes.string,
  service: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export { StatistiqueClient };
