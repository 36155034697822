import React from "react";

import { ArticleService } from "_services";
import { ButtonIcon } from "_components";
import { Input } from "_components/Input";

import { useDrag, useDrop } from "react-dnd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faPlus,
  faTrash,
  faCommentDots,
} from "@fortawesome/free-solid-svg-icons";

import { formatValueDecimal } from "_utils/DecimalFunctions";

import { noFilter } from "_components/Input/InputTypes/SelectSearchFunctions.js";

function LigneDevisDetail(props) {
  const refDrag = React.useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: "ligneDevisDetail",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item) {
      if (!refDrag.current || !props.editing) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = props.index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Time to actually perform the action
      props.moveLigneDevisDetail(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: "ligneDevisDetail",
    item: () => {
      return { id: props.devisDetail.id, index: props.index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(refDrag));

  const inputDecimal = (accessor) => (
    <Input
      accessor={"devisDetail.".concat(props.devisDetail.id, ".", accessor)}
      value={props.devisDetail[accessor]}
      ignoredValues={[0]}
      type="decimal"
      placeholder=""
      handleBlur={props.handleChange}
      showValidator={false}
      showClearButton={false}
      disabled={!props.editing}
      numberOfDecimals={accessor == "quantite" ? 3 : 2}
    />
  );

  if (props.devisDetail.type === 0) {
    // Ligne Type Article
    return (
      <>
        <tr
          ref={preview}
          className="ligneDevisDetail"
          style={{
            backgroundColor: isDragging ? "#fbb" : "white",
          }}
          data-handler-id={handlerId}
        >
          <td className="col1 background-color" ref={refDrag}>
            <FontAwesomeIcon
              icon={faEllipsisH}
              size="lg"
              className="fa-ellipsis-h flex-auto m-auto d-block"
            />
          </td>
          <td className="col2 background-color">
            <Input
              accessor={"devisDetail.".concat(props.devisDetail.id, ".article")}
              value={props.devisDetail.article}
              type="selectSearch"
              placeholder=""
              service={(params) => {
                return ArticleService.getForSelectSearch(
                  params,
                  props.langueClient
                );
              }}
              debounce={300}
              valueFieldToDisplay="aR_Ref"
              optionFieldToDisplay={["aR_Ref", "description"]}
              handleChange={props.handleChange}
              disabled={!props.editing}
              customFilter={noFilter}
            />
          </td>
          <td className="col3 background-color">
            <Input
              accessor={"devisDetail.".concat(
                props.devisDetail.id,
                ".description"
              )}
              value={props.devisDetail.description}
              type="text"
              placeholder=""
              handleChange={props.handleChange}
              showValidator={false}
              showClearButton={false}
              disabled={!props.editing}
            />
          </td>
          <td className="col4 background-color">
            {inputDecimal("prixVenteUnitaire")}
          </td>
          <td className="col5 background-color">{inputDecimal("quantite")}</td>
          <td className="col6 background-color">
            {props.devisDetail.article?.unite ?? ""}
          </td>
          <td className="col7 background-color">{inputDecimal("remise")}</td>
          <td className="col8 background-color">
            {inputDecimal("prixVenteTotal")}
          </td>
          <td className="col9 background-color">
            {inputDecimal("montantTVA")}
          </td>
          <td className="col10 background-color">
            <Input
              accessor={"devisDetail.".concat(
                props.devisDetail.id,
                ".prixVenteTotalTTC"
              )}
              value={props.devisDetail.prixVenteTotalTTC}
              ignoredValues={[0]}
              type="decimal"
              placeholder=""
              handleBlur={props.handleChange}
              showValidator={false}
              showClearButton={false}
              disabled={true}
            />
          </td>
          <td className="col11 px-2 background-color">
            <ButtonIcon
              icon={faPlus}
              iconSize="sm"
              tooltip="Ajouter article"
              onClick={() => props?.addLineDevisDetail(0, props.devisDetail.id)}
              className="btn btn-success addLineDevisDetail"
              style={!props.editing ? { display: "none" } : {}}
            ></ButtonIcon>
          </td>
          <td className="col12 px-2 background-color">
            <ButtonIcon
              icon={faCommentDots}
              iconSize="sm"
              tooltip="Ajouter commentaire"
              onClick={() => props?.addLineDevisDetail(1, props.devisDetail.id)}
              className="btn btn-success addLineDevisDetail"
              style={!props.editing ? { display: "none" } : {}}
            ></ButtonIcon>
          </td>
          <td className="col13 px-2 background-color">
            <ButtonIcon
              iconSize="sm"
              tooltip="Ajout sous-total"
              textOption={"\u03A3"}
              onClick={() => props?.addLineDevisDetail(2, props.devisDetail.id)}
              className="btn btn-success addLineDevisDetail"
              style={!props.editing ? { display: "none" } : {}}
            ></ButtonIcon>
          </td>
          <td className="col14 px-2 background-color">
            <ButtonIcon
              smallText=""
              icon={faTrash}
              iconSize="sm"
              tooltip="Supprimer"
              onClick={() => {
                props?.deleteLineDevisDetail(props.devisDetail.id);
              }}
              className="btn btn-danger deleteLineDevisDetail"
              style={!props.editing ? { display: "none" } : {}}
            ></ButtonIcon>
          </td>
        </tr>
      </>
    );
  } else if (props.devisDetail.type === 1) {
    // Ligne Type Commentaire
    return (
      <>
        <tr
          ref={preview}
          className="ligneDevisDetail commentaryLine"
          style={{
            backgroundColor: isDragging ? "#fbb" : "white",
          }}
          data-handler-id={handlerId}
        >
          <td className="col1 background-color" ref={refDrag}>
            <FontAwesomeIcon
              icon={faEllipsisH}
              size="lg"
              className="fa-ellipsis-h flex-auto m-auto d-block"
            />
          </td>
          <td className="col2 background-color"></td>
          <td className="col3 background-color">
            <Input
              accessor={"devisDetail.".concat(
                props.devisDetail.id,
                ".description"
              )}
              value={props.devisDetail.description}
              type="text"
              placeholder=""
              handleChange={props.handleChange}
              showValidator={false}
              showClearButton={false}
              disabled={!props.editing}
            />
          </td>
          <td className="col4 background-color"></td>
          <td className="col5 background-color"></td>
          <td className="col6 background-color"></td>
          <td className="col7 background-color"></td>
          <td className="col8 background-color"></td>
          <td className="col9 background-color"></td>
          <td className="col10 background-color"></td>
          <td className="col11 px-2 background-color">
            <ButtonIcon
              icon={faPlus}
              iconSize="sm"
              onClick={() => props?.addLineDevisDetail(0, props.devisDetail.id)}
              className="btn btn-success addLineDevisDetail"
              style={!props.editing ? { display: "none" } : {}}
              tooltip="Ajouter article"
            ></ButtonIcon>
          </td>
          <td className="col12 px-2 background-color">
            <ButtonIcon
              icon={faCommentDots}
              iconSize="sm"
              onClick={() => props?.addLineDevisDetail(1, props.devisDetail.id)}
              className="btn btn-success addLineDevisDetail"
              style={!props.editing ? { display: "none" } : {}}
              tooltip="Ajouter commentaire"
            ></ButtonIcon>
          </td>
          <td className="col13 px-2 background-color">
            <ButtonIcon
              textOption={"\u03A3"}
              onClick={() => props?.addLineDevisDetail(2, props.devisDetail.id)}
              className="btn btn-success addLineDevisDetail"
              style={!props.editing ? { display: "none" } : {}}
              tooltip="Ajout sous-total"
            />
          </td>
          <td className="col14 px-2 background-color">
            <ButtonIcon
              smallText=""
              icon={faTrash}
              iconSize="sm"
              onClick={() => {
                props?.deleteLineDevisDetail(props.devisDetail.id);
              }}
              className="btn btn-danger deleteLineDevisDetail"
              style={!props.editing ? { display: "none" } : {}}
              tooltip="Supprimer ligne"
            ></ButtonIcon>
          </td>
        </tr>
      </>
    );
  } else if (props.devisDetail.type === 2) {
    // Ligne Type SousTotal
    return (
      <>
        <tr
          ref={preview}
          className="ligneDevisDetail sousTotalLine"
          style={{
            backgroundColor: isDragging ? "#fbb" : "white",
          }}
          data-handler-id={handlerId}
        >
          <td className="col1 background-color" ref={refDrag}>
            <FontAwesomeIcon
              icon={faEllipsisH}
              size="lg"
              className="fa-ellipsis-h flex-auto m-auto d-block"
            />
          </td>
          <td className="col2 background-color"></td>
          <td className="col3 background-color"></td>
          <td className="col4 background-color"></td>
          <td className="col5 background-color"></td>
          <td className="col6 background-color"></td>
          <td className="col7 background-color">
            <span>Sous-Total</span>
          </td>
          <td className="col8 background-color">
            <span>{formatValueDecimal(props.devisDetail.prixVenteTotal)}</span>
          </td>
          <td className="col9 background-color">
            <span>{formatValueDecimal(props.devisDetail.montantTVA)}</span>
          </td>
          <td className="col10 background-color">
            <span>
              {formatValueDecimal(props.devisDetail.prixVenteTotalTTC)}
            </span>
          </td>
          <td className="col11 px-2 background-color">
            <ButtonIcon
              icon={faPlus}
              iconSize="sm"
              onClick={() => props?.addLineDevisDetail(0, props.devisDetail.id)}
              className="btn btn-success addLineDevisDetail"
              style={!props.editing ? { display: "none" } : {}}
              tooltip="Ajouter article"
            ></ButtonIcon>
          </td>
          <td className="col12 px-2 background-color">
            <ButtonIcon
              icon={faCommentDots}
              iconSize="sm"
              onClick={() => props?.addLineDevisDetail(1, props.devisDetail.id)}
              className="btn btn-success addLineDevisDetail"
              style={!props.editing ? { display: "none" } : {}}
              tooltip="Ajouter commentaire"
            ></ButtonIcon>
          </td>
          <td className="col13 px-2 background-color">
            <ButtonIcon
              textOption={"\u03A3"}
              onClick={() => props?.addLineDevisDetail(2, props.devisDetail.id)}
              className="btn btn-success addLineDevisDetail"
              style={!props.editing ? { display: "none" } : {}}
              tooltip="Ajout sous-total"
            />
          </td>
          <td className="col14 px-2 background-color">
            <ButtonIcon
              smallText=""
              icon={faTrash}
              iconSize="sm"
              onClick={() => {
                props?.deleteLineDevisDetail(props.devisDetail.id);
              }}
              className="btn btn-danger deleteLineDevisDetail"
              style={!props.editing ? { display: "none" } : {}}
              tooltip="Supprimer ligne"
            ></ButtonIcon>
          </td>
        </tr>
      </>
    );
  } else {
    return <></>;
  }
}

export { LigneDevisDetail };
