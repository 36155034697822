import React from "react";

import { BoxInformationsInline, WarningBar } from "_components";
import {
  FournisseurDomaineService,
  FournisseurQualificationService,
  LangueService,
  PersonnelService,
  ProduitInterneService,
} from "_services";
import { App } from "App";

const General = (props) => {
  const personnelProxy = (search) => {
    return new Promise((resolve, reject) =>
      PersonnelService.getAll({ global: search, actif: true })
        .then((res) => {
          resolve({ data: res.data?.datas });
        })
        .catch((err) => reject(err))
    );
  };
  let right = App.RightsGuard?.current?.hasRight("Fournisseurs", "Fiche");

  const rightb = App.RightsGuard?.current?.isPersonne(
    props.fournisseur?.referentInterne?.oid
  );

  if (rightb == "RW") {
    right = rightb;
  }

  if (right == "RO" || right == "RW") {
    const bloc1 = [
      {
        label: "Qualification",
        accessor: "qualification",
        functionAppliedToValue: (value) => {
          return value ? value.designation : null;
        },
        colSize: 4,
        service: FournisseurQualificationService.getAll,
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        type: "selectSearch",
        required: true,
      },

      {
        label: "Site web",
        accessor: "siteWeb",
        colSize: 4,
        type: "text",
      },

      {
        label: "Langue",
        accessor: "langue",
        functionAppliedToValue: (value) => {
          return value ? value.langage : null;
        },
        colSize: 4,
        service: LangueService.getAll,
        optionFieldToDisplay: "langage",
        valueFieldToDisplay: "langage",
        type: "selectSearch",
      },

      {
        label: "Domaines",
        accessor: "domaines",
        functionAppliedToValue: (value) => {
          return value && value.length > 0 ? (
            <>{value?.map((e) => e.designation).join(", ")}</>
          ) : (
            "-"
          );
        },
        colSize: 4,
        service: FournisseurDomaineService.getAll,
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        type: "multipleSelectSearch",
        required: true,
      },
    ];
    const bloc2 = [
      {
        label: "Nom société",
        accessor: "nom",
        colSize: 4,
      },
      {
        label: "Compte tier",
        accessor: "compteTier",
        colSize: 4,
      },
      {
        label: "Siret",
        accessor: "siret",
        colSize: 4,
      },
      {
        label: "Adresse",
        accessor: "adresse",
        colSize: 4,
        functionAppliedToValue: () => {
          if (props.fournisseur.adresse)
            return (
              <>
                {props.fournisseur.adresse?.intitule ?? ""}
                <br />
                {props.fournisseur.adresse?.voie ?? ""}
                <br />
                {props.fournisseur.adresse?.complement ?? ""}
                <br />
                {(props.fournisseur.adresse?.cp ?? "") +
                  " " +
                  props.fournisseur.adresse?.ville ?? ""}
                <br />
                {props.fournisseur.adresse?.pays?.nom_Fr ?? ""}
              </>
            );
          else return <>-</>;
        },
      },
    ];

    const bloc3 = [
      {
        label: "Mode de paiement",
        accessor: "modePaiement",
        colSize: 4,
        functionAppliedToValue: (value) => {
          return value ? value.mode : null;
        },
      },
      {
        label: "Echeance",
        accessor: "echeance",
        colSize: 4,
        functionAppliedToValue: (value) => {
          return value ? value.designation : null;
        },
      },
    ];

    const blocInfoSolsol = [
      {
        label: "Produits internes",
        accessor: "produitsInternes",
        functionAppliedToValue: (value) => {
          return value && value.length > 0 ? (
            <>{value?.map((e) => e.designation).join(", ")}</>
          ) : (
            "-"
          );
        },
        colSize: 4,
        service: ProduitInterneService.getAll,
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        type: "multipleSelectSearch",
      },

      {
        label: "Référent interne",
        accessor: "referentInterne",
        functionAppliedToValue: (value) => {
          return value ? value.nom + " " + value.prenom : null;
        },
        colSize: 4,
        service: personnelProxy,
        optionFieldToDisplay: ["nom", "prenom"],
        valueFieldToDisplay: ["nom", "prenom"],
        type: "selectSearch",
      },
      {
        label: "Lien serveur",
        accessor: "lienServeurSolSolution",
        colSize: 4,
        type: "text",
      },
    ];

    return (
      <>
        <div className="row ficheMateriel">
          <div className="col-12 ">
            <BoxInformationsInline
              title="Info Sol Sol"
              datas={blocInfoSolsol}
              state={props.fournisseur}
              stateFieldNameToUpdate={props.stateFieldNameToUpdate}
              service={props.service}
              handleChange={props.handleChange}
              handleEditing={props.handleEditing}
              editing={props.editing}
              handleUpdate={props.handleUpdate}
              NoEdition={right == "RO"}
            />
          </div>
          <div className="col-12 ">
            <BoxInformationsInline
              title="Informations"
              datas={bloc1}
              state={props.fournisseur}
              stateFieldNameToUpdate={props.stateFieldNameToUpdate}
              service={props.service}
              handleChange={props.handleChange}
              handleEditing={props.handleEditing}
              editing={props.editing}
              handleUpdate={props.handleUpdate}
              NoEdition={right == "RO"}
            />
          </div>
          <div className="col-12 ">
            <BoxInformationsInline
              title="Informations Sage"
              datas={bloc2}
              state={props.fournisseur}
              NoEdition={true}
            />
          </div>
          <div className="col-12 ">
            <BoxInformationsInline
              title="Conditions de paiement"
              datas={bloc3}
              state={props.fournisseur}
              NoEdition={true}
            />
          </div>
        </div>
      </>
    );
  } else
    return (
      <>
        <WarningBar
          active={false}
          content={"Vous n'avez pas le droit de voir cette page"}
        />
      </>
    );
};

export { General };
