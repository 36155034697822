import React from "react";
import { PropTypes } from "prop-types";

import { BoxInformationsInline, Box, ButtonIcon } from "_components";

import {
  ResponsiveContainer,
  LineChart,
  Line,
  Legend,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";

import {
  faPlus,
  faCheck,
  faTimes,
  faPen,
} from "@fortawesome/free-solid-svg-icons";

import { LigneSuivi } from "./LigneSuivi";

class SuiviCVM extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      savedMateriel: null,
      graphLineColors: ["#600606", "#037203", "#030372", "#7070c0", "#70b080"],
    };

    this.handleClickEdition = this.handleClickEdition.bind(this);
    this.handleClickUndo = this.handleClickUndo.bind(this);
    this.handleClickValidate = this.handleClickValidate.bind(this);
  }

  handleClickEdition() {
    this.setState({
      savedMateriel: { ...this.props.materiel },
    });
    this.props.handleEditing(true);
  }

  handleClickUndo() {
    this.props.handleChange("materiel", this.state.savedMateriel);
    this.props.handleEditing(false);
  }

  handleClickValidate() {
    this.props.handleUpdate();
    this.props.handleEditing(false);
  }

  render() {
    const informationsFiche = [
      {
        label: "Id Sage",
        accessor: "CBMarq",
        colSize: 3,
      },
      {
        label: "Num Serie",
        accessor: "num_Serie",
        colSize: 3,
      },
    ];

    const data = [];

    this.props.materiel.suiviElement?.forEach((s) => {
      if (s.lignes != null) {
        var dataPoint = { name: new Date(s.date)?.getFullYear() };
        s.lignes.forEach((l) => {
          dataPoint[l.typeElement + "-Fidelite"] = l.erreurFidelite;
          dataPoint[l.typeElement + "-Sensibilite"] = l.erreurSensibilite;
          dataPoint[l.typeElement + "-Linearite"] = l.erreurLinearite;
        });
        data.push(dataPoint);
      }
    });
    return this.props.materiel.articleASuivre?.elementsMateriels?.length > 0 ? (
      <>
        <div className="row ficheMateriel">
          <div className="col-12 boxTableCVM">
            <Box
              noPaddingBottom={true}
              header={
                <>
                  <div className="col-6 align-self-center py-2">
                    <div>Informations CVM</div>
                  </div>
                  <div className="col-6 text-end py-2">
                    <ButtonIcon
                      icon={faPlus}
                      iconSize="sm"
                      onClick={() => this.props.addLineSuivi?.()}
                      className="btn btn-success addElement"
                      style={!this.props.editing ? { display: "none" } : {}}
                    ></ButtonIcon>
                    <ButtonIcon
                      icon={faCheck}
                      className="btn btn-success text-light ms-1"
                      style={
                        !this.props.editing
                          ? { display: "none" }
                          : { width: "42px" }
                      }
                      onClick={this.handleClickValidate}
                    ></ButtonIcon>
                    <ButtonIcon
                      icon={faTimes}
                      className="btn btn-danger text-light ms-1"
                      onClick={this.handleClickUndo}
                      style={
                        !this.props.editing
                          ? { display: "none" }
                          : { width: "42px" }
                      }
                    ></ButtonIcon>
                    <ButtonIcon
                      icon={faPen}
                      className="btn btn-secondary text-light ms-1"
                      onClick={this.handleClickEdition}
                      style={
                        this.props.editing
                          ? { display: "none" }
                          : { width: "42px" }
                      }
                    ></ButtonIcon>
                  </div>
                </>
              }
              body={
                <table
                  className={
                    " text-uppercase table table-sm " +
                    (this.props.materiel.suiviElement?.length > 0
                      ? ""
                      : "table-bordered")
                  }
                >
                  <thead
                    className={
                      "sticky-top" +
                      (this.props.materiel.suiviElement?.length == 0
                        ? " theadBorderRadius"
                        : "")
                    }
                  >
                    <tr>
                      <th>Date</th>
                      <th>Etalonnage</th>
                      <th>Element</th>
                      <th>Numéro de série</th>
                      {this.props.materiel.produitInterne?.designation ===
                      "GRZ" ? (
                        <th>Erreur de fidélité</th>
                      ) : (
                        <>
                          <th>Erreur de sensibilité</th>
                          <th>Erreur de linéarité</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.materiel.suiviElement.map((suivi) => (
                      <LigneSuivi
                        key={suivi.id}
                        suivi={suivi}
                        handleChange={this.props.handleChange}
                        editing={this.props.editing}
                        materiel={this.props.materiel}
                        deleteBlocSuivi={this.props.deleteBlocSuivi}
                      />
                    ))}
                  </tbody>
                </table>
              }
            />
          </div>
        </div>
        <div className="row ficheMateriel">
          <div className="col-12 boxGrapheCVM">
            <Box
              header={
                <div className="col-12 align-self-center py-2">
                  <div>Carte de contrôle</div>
                </div>
              }
              body={
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart
                    margin={{
                      top: 30,
                      right: 100,
                      left: 60,
                      bottom: 10,
                    }}
                    data={data}
                  >
                    {this.props.materiel.articleASuivre?.elementsMateriels?.map(
                      (el, index) => (
                        <React.Fragment key={index}>
                          {this.props.materiel.produitInterne?.designation ===
                          "GRZ" ? (
                            <Line
                              dataKey={el.type + "-Fidelite"}
                              stroke={this.state.graphLineColors[index]}
                            />
                          ) : (
                            <>
                              <Line
                                dataKey={el.type + "-Sensibilite"}
                                stroke={this.state.graphLineColors[index]}
                              />
                              <Line
                                dataKey={el.type + "-Linearite"}
                                stroke={this.state.graphLineColors[index]}
                                strokeDasharray="5 5"
                              />
                            </>
                          )}
                        </React.Fragment>
                      )
                    )}
                    <XAxis dataKey="name" />
                    <YAxis domain={[0, 1]} />
                    <Tooltip />
                    <Legend />
                  </LineChart>
                </ResponsiveContainer>
              }
            />
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="col-lg-6 col-md-12 boxInformationsFiche">
            <BoxInformationsInline
              title="Informations sur la fiche"
              datas={informationsFiche}
              state={this.props.materiel}
              stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
              service={this.props.service}
              handleChange={this.props.handleChange}
              colSize="3"
              boxSize="small"
              NoEdition
            />
          </div>
        </div>
      </>
    ) : (
      <h4 className="mt-5">Ce matériel n'a pas d'éléments matériels</h4>
    );
  }
}

SuiviCVM.propTypes = {
  materiel: PropTypes.object,
  stateFieldNameToUpdate: PropTypes.string,
  handleChange: PropTypes.func,
  history: PropTypes.object,
  service: PropTypes.any,
};

export { SuiviCVM };
