import React, { useEffect, useState } from "react";
import { App } from "App";

import { Box, ButtonIcon, DialogForm } from "_components";

import { WarningBar } from "_components";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  ActionsTacheService,
  FournisseurService,
  PersonnelService,
  PrioritesTacheService,
  TacheService,
} from "_services";
import { Accordeontache } from "./SubTable/AccordeonTache";
import { Input } from "_components/Input";

const Actions = (props) => {
  const [taches, setTaches] = useState([]);
  const [tache, setTache] = useState({});
  const [openDialogCreationTacheFunc, setOpenDialogCreationTacheFunc] =
    useState(() => {});
  let right = App.RightsGuard?.current?.hasRight("Fournisseurs", "Fiche");

  const reloadTaches = () => {
    FournisseurService.getTaches(props.fournisseur.id).then((res) =>
      setTaches(res.data)
    );
  };

  useEffect(() => {
    reloadTaches();
  }, []);

  const actionProxy = () => {
    return new Promise((resolve, reject) =>
      ActionsTacheService.getAll()
        .then((res) => {
          resolve({ data: res.data?.filter((e) => e.fournisseur) });
        })
        .catch((err) => reject(err))
    );
  };

  const handleClickEditionTache = (idTache) => {
    var tache = taches.find((el) => el.id == idTache);

    setTache(tache);
    console.log("test2");
    openDialogCreationTacheFunc();
  };

  const rightb = App.RightsGuard?.current?.isPersonne(
    props.fournisseur?.referentInterne?.oid
  );

  const personnelProxy = (search) => {
    return new Promise((resolve, reject) =>
      PersonnelService.getAll({
        orderBy: "nom",
        order: "ASC",
        nom: search,
        size: 25,
        actif: true,
      })
        .then((res) => {
          resolve({ data: res.data?.datas });
        })
        .catch((err) => reject(err))
    );
  };

  if (rightb == "RW") {
    right = rightb;
  }
  if (right == "RW" || right == "RO") {
    return (
      <>
        <div>
          <DialogForm
            tooltip="Ajouter une tache"
            classNameButton="btn btn-success"
            dialogTitle="Ajouter une tache"
            labelValidateButton="Valider"
            setOpenDialog={(func) => setOpenDialogCreationTacheFunc(() => func)}
            onValidate={() => {
              if (tache.id) {
                TacheService.putTache({
                  ...tache,
                  idReference: props.fournisseur.id,
                  typeEntityReference: "fournisseurs",
                }).then(() => {
                  reloadTaches();
                  setTache({});
                });
              } else {
                TacheService.postTache({
                  ...tache,
                  idReference: props.fournisseur.id,
                  typeEntiteReference: "fournisseurs",
                }).then(() => {
                  reloadTaches();
                  setTache({});
                });
              }
            }}
            onClose={() => {}}
            body={
              <div
                id="PopupCommande"
                className={"col-lg-12 row"}
                style={{ minWidth: "1000px" }}
              >
                <div className={"col-lg-6"}>
                  <Input
                    value={tache.action}
                    label="Action"
                    accessor="action"
                    type="selectSearch"
                    service={actionProxy}
                    showClearButton={false}
                    optionFieldToDisplay={["designation"]}
                    valueFieldToDisplay={["designation"]}
                    handleChange={(accessor, value) => {
                      let tachetmp = { ...tache };
                      tachetmp.action = value;
                      setTache(tachetmp);
                    }}
                  />

                  <Input
                    accessor="assigne"
                    value={tache.assigne}
                    type="selectSearch"
                    label="Assigné"
                    service={personnelProxy}
                    valueFieldToDisplay={["nom", "prenom"]}
                    optionFieldToDisplay={["nom", "prenom"]}
                    handleChange={(accessor, value) => {
                      let tachetmp = { ...tache };
                      tachetmp.assigne = value;
                      setTache(tachetmp);
                    }}
                  />
                </div>
                <div className={"col-lg-6"}>
                  <Input
                    value={tache.contact}
                    accessor="contact"
                    type="selectSearch"
                    label="Contact"
                    options={props.fournisseur.contacts}
                    showClearButton={false}
                    optionFieldToDisplay={["nom_Contact", "prenom_Contact"]}
                    valueFieldToDisplay={["nom_Contact", "prenom_Contact"]}
                    handleChange={(accessor, value) => {
                      let tachetmp = { ...tache };
                      tachetmp.contact = value;
                      setTache(tachetmp);
                    }}
                  />
                  <Input
                    accessor="priorite"
                    value={tache.priorite}
                    type="selectSearch"
                    label="Priorité"
                    service={PrioritesTacheService.getAll}
                    valueFieldToDisplay={"designation"}
                    optionFieldToDisplay={"designation"}
                    handleChange={(accessor, value) => {
                      let tachetmp = { ...tache };
                      tachetmp.priorite = value;
                      setTache(tachetmp);
                    }}
                  />
                  <Input
                    accessor="dateEcheance"
                    value={tache.dateEcheance}
                    type="date"
                    label="Date d'écheance"
                    handleChange={(accessor, value) => {
                      let tachetmp = { ...tache };
                      tachetmp.dateEcheance = value;
                      setTache(tachetmp);
                    }}
                  />
                </div>
                <div className={"col-lg-12"}>
                  <Input
                    value={tache.commentaire}
                    accessor="commentaire"
                    type="textArea"
                    label="Commentaire"
                    required={true}
                    handleChange={(accessor, value) => {
                      let tachetmp = { ...tache };
                      tachetmp.commentaire = value;
                      setTache(tachetmp);
                    }}
                  />
                </div>
              </div>
            }
          />
        </div>
        <Box
          header={
            <div className="row text-align-center">
              <div className="col-6 py-2">Tâches</div>
              <div className="col-6 text-end py-2">
                <ButtonIcon
                  smallText=""
                  icon={faPlus}
                  iconSize="sm"
                  onClick={() => {
                    console.log("test");
                    setTache({});
                    openDialogCreationTacheFunc();
                  }}
                  className="btn btn-success"
                  style={right != "RW" ? { display: "none" } : {}}
                />
              </div>
            </div>
          }
          body={
            <table
              id="tableTache"
              className={
                "table table-sm" + (taches?.length > 0 ? "" : " table-bordered")
              }
            >
              <thead
                className={
                  "sticky-top" +
                  (taches?.length == 0 ? " theadBorderRadius" : "")
                }
              >
                <tr className="py-1">
                  <th></th>
                  <th>Statut</th>
                  <th>Date de création</th>
                  <th>Contact</th>
                  <th>Action</th>
                  <th>Priorité</th>
                  <th>Assignée à</th>
                </tr>
              </thead>
              <tbody>
                {taches
                  ?.slice()
                  .sort((a, b) =>
                    (b.joursRetard ?? 0) === (a.joursRetard ?? 0)
                      ? new Date(b.dateCreation) - new Date(a.dateCreation)
                      : (b.joursRetard ?? 0) - (a.joursRetard ?? 0)
                  )
                  .map((tache, index) => (
                    <Accordeontache
                      key={index}
                      tache={tache}
                      index={index}
                      handleClickEditionTache={handleClickEditionTache}
                    />
                  ))}
              </tbody>
            </table>
          }
          noPaddingBottom={true}
        />
      </>
    );
  } else
    return (
      <>
        <WarningBar
          active={false}
          content={"Vous n'avez pas le droit de voir cette page"}
        />
      </>
    );
};

export { Actions };
