import React from "react";

// Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Validation des props
import { PropTypes } from "prop-types";

import { AlertDialog } from "_components";

/**
 * Génère un composant bouton avec une icône et un texte optionnel à l'interieur et un texte en dessous du bouton et une validation
 */
class ButtonIconWithSmallAndValidation extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorText: "",
      opened: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleActionValidation = this.handleActionValidation.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseValidation = this.handleCloseValidation.bind(this);
  }

  handleClick() {
    this.setState({ opened: true });
  }

  handleActionValidation() {
    this.props.onClick().catch((error) => {
      this.setState({ error: true, errorText: error.message });
    });
    this.setState({ opened: false });
  }

  handleClose() {
    this.setState({ error: false });
  }

  handleCloseValidation() {
    this.setState({ opened: false });
  }

  render() {
    return (
      <>
        <AlertDialog
          title={this.props.alertTitle}
          body={this.props.alertBody}
          valider={this.props.alertButtonValidationText}
          open={this.state.opened}
          onClick={this.handleActionValidation}
          handleClose={this.handleCloseValidation}
        />
        <AlertDialog
          title="Une erreur a été rencontrée"
          body={
            <>
              <div>
                Une erreur est survenue lors de la mise à jour des données :
              </div>
              <div>{this.state.errorText}</div>
            </>
          }
          valider="Ok"
          open={this.state.error}
          handleClose={this.handleClose}
        />
        <div id={this.props.id} className="form-group ms-1">
          <button
            onClick={this.handleClick}
            className={this.props.className}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={this.props.tooltip}
          >
            <FontAwesomeIcon
              size={this.props.iconSize}
              icon={this.props.icon}
            />
            {this.props.textOption}
          </button>
          <small className="form-text text-muted d-block text-center mt-0">
            {this.props.smallText}
          </small>
        </div>
      </>
    );
  }
}

/**
 * Validation des props :
 * icon : icone de fontawesome
 * iconSize : taille gérées par fontawesome [xs, sm, lg, 2x, 3x, ...]
 * smallText : texte écrit en petit sous le bouton
 * onClick : fonction appliquée lors du click sur le bouton
 * id : id de la division comportant le bouton
 * className : classes à appliquer au bouton
 * textOption : texte à ajouter à côté de l'icône
 * tooltip: contenu du tooltip
 */
ButtonIconWithSmallAndValidation.propTypes = {
  icon: PropTypes.any,
  iconSize: PropTypes.string,
  smallText: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
  className: PropTypes.string,
  textOption: PropTypes.any,
  alertTitle: PropTypes.string,
  alertBody: PropTypes.string,
  alertButtonValidationText: PropTypes.string,
  tooltip: PropTypes.any,
};

export { ButtonIconWithSmallAndValidation };
