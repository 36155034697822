import React, { useState } from "react";

import { ButtonIcon } from "_components";
import { faMinusSquare, faPlusSquare } from "@fortawesome/free-solid-svg-icons";

import { ToLocaleDateString } from "_utils";


const AccordeonBonsAchats = (props) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);


  
  return (
    <>
      <tr
        key={props.index * 1000}
        className={props.index % 2 ? "shadowLine" : ""}
      >
        <td className="tableIndent1">
          <ButtonIcon
            icon={isAccordionOpen ? faMinusSquare : faPlusSquare}
            className="btn"
            onClick={() =>

              setIsAccordionOpen(!isAccordionOpen)
            }
            style={{ width: "32px" }}
          ></ButtonIcon>
          {props.bonAchat.numeroDocument}
        </td>
        <td className="tableIndent1 pt-2">
          {ToLocaleDateString(props.bonAchat.dateCreation)}
        </td>
        <td className="tableIndent1"></td>
        <td className="tableIndent1"></td>
        <td className="tableIndent1"></td>
        <td className="tableIndent1"></td>
        <td className="tableIndent1 pt-2">
          {props.bonAchat.prixTotalHT}
        </td>
        <td className="tableIndent1 pt-2">
          {props.bonAchat.prixTotalTTC}
        </td>
      </tr>

      {isAccordionOpen
        ? props.bonAchat.lignes.map((ligne, index) => {
            return (
              <tr
                key={props.index * 1000 + index}
                className={
                  "text-muted " + (props.index % 2 ? "shadowLine" : "")
                }
              >
                <td className="tableIndent1"></td>
                <td className="tableIndent1"></td>
                <td className="tableIndent1">{ligne.referenceArticle}</td>
                <td className="tableIndent1">{ligne.descriptionArticle}</td>
                <td className="tableIndent1">{ligne.quantite}</td>
                <td className="tableIndent1">{ligne.prixVenteUnitaireHT}</td>
                <td className="tableIndent1">{ligne.prixVenteTotalHT}</td>
                <td className="tableIndent1">{ligne.prixVenteTotalTTC}</td>
                <td className="tableIndent1"></td>
                <td className="tableIndent1"></td>
              </tr>
            );
          })
        : null}
    </>
  );
  
};

export { AccordeonBonsAchats };
