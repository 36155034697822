import React from "react";
import { PropTypes } from "prop-types";
import { App } from "App";

import {
  TypeClientService,
  EtatClientService,
  ProduitInterneService,
  ReferentGeotechniqueService,
  LangueService,
  TailleEntrepriseService,
  RegionService,
} from "_services";

import { IfNullToText, ToLocaleDateString, getIconClient } from "_utils";
import {
  BoxMapLeaflet,
  BoxInformationsInline,
  WarningBar,
  ButtonIcon,
} from "_components";
import { faCopy, faPaste } from "@fortawesome/free-solid-svg-icons";

class InformationsClient extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight("Client", "General");
    const suppressionRight = App.RightsGuard?.current?.hasRight(
      "Client",
      "Suppression"
    );

    if (right == "RW" || right == "RO") {
      const isClientEmpty = !Object.entries(this.props.client).length;
      if (isClientEmpty) {
        return (
          <div className="alert alert-danger mt-2 mx-auto" role="alert">
            Une erreur a été rencontrée ! (Les informations du client ne sont
            pas disponibles)
          </div>
        );
      } else {
        const client = this.props.client;
        const voie = IfNullToText(
          client.adresse_Siege_Social?.voie,
          IfNullToText(
            client.adresse_Siege_Social?.intitule +
              IfNullToText(client.adresse_Siege_Social?.complement, ""),
            ""
          )
        );
        const ville = IfNullToText(client.adresse_Siege_Social?.ville, "");
        const codePostal = IfNullToText(client.adresse_Siege_Social?.cp, "");
        const pays = client.adresse_Siege_Social?.pays?.nom_Fr ?? "";
        const precision = client.adresse_Siege_Social?.precision;
        const latitude = client.adresse_Siege_Social?.lat ?? 0;
        const longitude = client.adresse_Siege_Social?.lon ?? 0;

        const informationsSurLaSociete = [
          {
            label: "état",
            accessor: "etat",
            functionAppliedToValue: (value) => {
              return value ? (
                <div className="d-flex justify-content-start align-items-center">
                  {getIconClient(value.qualification, "small")}
                  {value.qualification}
                </div>
              ) : null;
            },
            type: "selectSearch",
            service: EtatClientService.getAll,
            optionFieldToDisplay: "qualification",
            valueFieldToDisplay: "qualification",
            required: true,
            invalidText: "Vous devez sélectionner un statut.",
            colSize: 2,
            value: this.props.client.etat,
          },
          {
            label: "Nom",
            accessor: "nom_Client",
            type: "text",
            required: true,
            invalidText: "Vous devez entrer un nom.",
            colSize: 2,
          },
          {
            label: "Siret",
            accessor: "siret",
            type: "text",
            colSize: 2,
          },
          {
            label: "Langue",
            accessor: "langue",
            type: "selectSearch",
            required: true,
            service: LangueService.getAll,
            colSize: 2,
            optionFieldToDisplay: "langage",
            valueFieldToDisplay: "langage",
            functionAppliedToValue: (value) => {
              if (value) return <>{value?.langage}</>;
              return null;
            },
          },
          {
            label: "Actif",
            accessor: "actif",
            type: suppressionRight == "RW" ? "checkbox" : null,
            functionAppliedToValue: (value) => {
              return value ? "Oui" : "Non";
            },
            colSize: 2,
          },
          {
            label: "Adresse du siège",
            requiredIntitule: true,
            functionAppliedToValue: () => {
              return (
                <>
                  {client.adresse_Siege_Social?.intitule ?? ""}
                  <br />
                  {client.adresse_Siege_Social?.voie ?? ""}
                  <br />
                  {client.adresse_Siege_Social?.complement ?? ""}
                  <br />
                  {(client.adresse_Siege_Social?.cp ?? "") +
                    " " +
                    (client.adresse_Siege_Social?.ville ?? "")}
                  <br />
                  {client.adresse_Siege_Social?.pays?.nom_Fr ?? ""}
                </>
              );
            },
            accessor: "adresse_Siege_Social",
            colSize: 12,
            colSizeInnerFields: 4,
            type: "address",
            optionFieldToDisplay: "nom_Fr",
          },
          {
            value: client.adresse_Siege_Social?.region,
            label: "Région",
            accessor: "adresse_Siege_Social.region",
            functionAppliedToValue: (value) => {
              return value ? value.nouvelle_Region : null;
            },
            type: "selectSearch",
            service: RegionService.getAllNouvelleRegions,
            optionFieldToDisplay: "nouvelle_Region",
            valueFieldToDisplay: "nouvelle_Region",
            colSize: 2,
          },

          {
            value: client.adresse_Siege_Social?.email,
            label: "Email",
            accessor: "adresse_Siege_Social.email",
            type: "email",
            colSize: 2,
          },
          {
            value: client.adresse_Siege_Social?.telephone,
            label: "Téléphone",
            accessor: "adresse_Siege_Social.telephone",
            type: "text",
            colSize: 2,
          },
          {
            value: client.adresse_Siege_Social?.fax,
            label: "Fax",
            accessor: "adresse_Siege_Social.fax",
            type: "text",
            colSize: 2,
          },
          {
            label: "Site Web",
            accessor: "site_Web",
            type: "text",
            colSize: 2,
          },
        ];
        const informationsGroupe = [
          {
            label: "Groupe",
            accessor: "groupe",
            type: "text",
            colSize: 6,
          },
          {
            label: "Taille entreprise (nombre employés)",
            accessor: "taille_Entreprise",
            type: "selectSearch",
            service: TailleEntrepriseService.getAll,
            optionFieldToDisplay: "libelle",
            valueFieldToDisplay: "libelle",
            functionAppliedToValue: (value) => {
              if (value) {
                return value.libelle;
              }
              return null;
            },
            colSize: 6,
          },
          {
            label: "Produits internes",
            accessor: "produitInternes",
            functionAppliedToValue: (value) => {
              let res = null;
              if (value) {
                res = value.map((item) => item.designation).join(", ");
              }
              return res;
            },
            required: true,
            type: "multipleSelectSearch",
            service: ProduitInterneService.getAll,
            optionFieldToDisplay: "designation",
            colSize: 6,
          },
          {
            label: "Activité",
            accessor: "type_Client",
            functionAppliedToValue: (value) => {
              return value ? value.type : null;
            },
            type: "selectSearch",
            required: true,
            service: TypeClientService.getAll,
            optionFieldToDisplay: "type",
            valueFieldToDisplay: "type",
            colSize: 6,
          },
          {
            label: "Catégorie",
            accessor: "type_Client",
            functionAppliedToValue: (value) => {
              return value && value.categorie ? value.categorie.type : null;
            },
            colSize: 6,
          },
          {
            label: "Compétence géotechnique interne",
            accessor: "referent_Geotechnique",
            functionAppliedToValue: (value) => {
              return value ? value.designation : null;
            },
            type: "selectSearch",
            service: ReferentGeotechniqueService.getAll,
            optionFieldToDisplay: "designation",
            valueFieldToDisplay: "designation",
            colSize: 6,
          },
        ];
        const informationsFiche = [
          {
            label: "Date de création",
            accessor: "date_Create",
            functionAppliedToValue: (value) => {
              return value
                ? ToLocaleDateString(client.date_Create) +
                    " par " +
                    IfNullToText(client.userCreate?.nom, "inconnu") +
                    " " +
                    IfNullToText(client.userCreate?.prenom, "")
                : "-";
            },
            colSize: 6,
          },
          {
            label: "Date de modification",
            accessor: "date_Modif",
            functionAppliedToValue: (value) => {
              return value
                ? ToLocaleDateString(client.date_Modif) +
                    " par " +
                    IfNullToText(client.userModif?.nom, "inconnu") +
                    " " +
                    IfNullToText(client.userModif?.prenom, "")
                : "-";
            },
            colSize: 6,
          },
        ];
        const remiseOptions = [
          {
            id: 1,
            value: 0,
            display: "0%",
          },
          {
            id: 2,
            value: 5,
            display: "5%",
          },
          {
            id: 3,
            value: 10,
            display: "10%",
          },
          {
            id: 4,
            value: 15,
            display: "15%",
          },
          {
            id: 5,
            value: 20,
            display: "20%",
          },
          {
            id: 6,
            value: 25,
            display: "25%",
          },
          {
            id: 7,
            value: 30,
            display: "30%",
          },
          {
            id: 8,
            value: 35,
            display: "35%",
          },
          {
            id: 9,
            value: 40,
            display: "40%",
          },
          {
            id: 10,
            value: 45,
            display: "45%",
          },
          {
            id: 11,
            value: 50,
            display: "50%",
          },
          {
            id: 12,
            value: 55,
            display: "55%",
          },
          {
            id: 13,
            value: 60,
            display: "60%",
          },
          {
            id: 14,
            value: 65,
            display: "65%",
          },
          {
            id: 15,
            value: 70,
            display: "70%",
          },
          {
            id: 16,
            value: 75,
            display: "75%",
          },
          {
            id: 17,
            value: 80,
            display: "80%",
          },
          {
            id: 18,
            value: 85,
            display: "85%",
          },
          {
            id: 19,
            value: 90,
            display: "90%",
          },
          {
            id: 20,
            value: 95,
            display: "95%",
          },
          {
            id: 21,
            value: 100,
            display: "100%",
          },
        ];

        const conditionsCommerciales = [
          {
            label: "Tarif négocié",
            accessor: "tarif_Negocie",
            functionAppliedToValue: (value) => {
              return value ? "Oui" : "Non";
            },
            type: "checkbox",
            colSize: 6,
          },
          {
            label: "Pourcentage de remise",
            accessor: "remise",
            type: "selectSearch",
            optionFieldToReturn: "value",
            optionFieldToDisplay: "display",
            valueFieldToDisplay: "display",
            options: remiseOptions,
            value:
              remiseOptions.find(
                (el) => el.value === this.props.client?.remise
              ) ?? null,
            functionAppliedToValue: (el) => {
              return (el ?? "0") + "%";
            },
            colSize: 6,
          },
          ...(this.props.client?.tarif_Negocie
            ? [
                {
                  label: "Date de tarification",
                  accessor: "dateTarifNegocie",
                  colSize: 6,
                },
                {
                  label: "Commentaire commercial",
                  accessor: "commentaireCommercial",
                  type: "textArea",
                  colSize: 12,
                },
              ]
            : []),
        ];

        const commentaire = [
          {
            label: "Commentaire",
            accessor: "commentaire",

            type: "textArea",
            functionAppliedToValue: (el) => {
              if (el != null) {
                return <div className="keepWhiteSpace">{el}</div>;
              }
            },
            colSize: 12,
          },
        ];
        return (
          <>
            <div className="row">
              <div className="col-12">
                <BoxInformationsInline
                  title={"Informations client"}
                  datas={informationsSurLaSociete}
                  state={this.props.client}
                  stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                  service={this.props.service}
                  handleChange={this.props.handleChange}
                  colSize="4"
                  editing={this.props.editing}
                  handleEditing={this.props.handleEditing}
                  handleUpdate={this.props.handleUpdate}
                  NoEdition={right == "RO"}
                  boutonSupplementaire={
                    <>
                      <ButtonIcon
                        icon={faCopy}
                        className="btn btn-success btn-sm text-light ms-1"
                        onClick={() =>
                          this.props.copierAdresse("adresse_Siege_Social")
                        }
                        style={
                          this.props.copierAdresse && this.props.editing
                            ? null
                            : { display: "none" }
                        }
                        tooltip="Copier l'adresse"
                      ></ButtonIcon>
                      <ButtonIcon
                        icon={faPaste}
                        className="btn btn-success btn-sm text-light ms-1"
                        onClick={() =>
                          this.props.collerAdresse("adresse_Siege_Social")
                        }
                        style={
                          this.props.collerAdresse && this.props.editing
                            ? null
                            : { display: "none" }
                        }
                        tooltip="Coller l'adresse"
                      ></ButtonIcon>
                    </>
                  }
                />
              </div>
              <div className="col-md-12 col-lg-6">
                <BoxMapLeaflet
                  editing={this.props.editing}
                  handleEditing={this.props.handleEditing}
                  handleChange={
                    this.props.handleChangeAdresseLivraisonPrincipalePosition
                  }
                  handleUpdate={this.props.handleUpdate}
                  voie={voie}
                  ville={ville}
                  codePostal={codePostal}
                  pays={pays}
                  precision={precision}
                  latitude={latitude}
                  longitude={longitude}
                  NoEdition={right == "RO"}
                />
                <BoxInformationsInline
                  title="Informations sur la fiche"
                  datas={informationsFiche}
                  state={this.props.client}
                  stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                  service={this.props.service}
                  handleChange={this.props.handleChange}
                  handleUpdate={this.props.handleUpdate}
                  colSize="4"
                  boxSize="small"
                  NoEdition
                />
              </div>
              <div className="col-md-12 col-lg-6">
                <BoxInformationsInline
                  title="Regroupement statistique"
                  datas={informationsGroupe}
                  state={this.props.client}
                  stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                  service={this.props.service}
                  handleChange={this.props.handleChange}
                  colSize="6"
                  editing={this.props.editing}
                  handleEditing={this.props.handleEditing}
                  handleUpdate={this.props.handleUpdate}
                  NoEdition={right == "RO"}
                />
                <BoxInformationsInline
                  title="Conditions commerciales"
                  datas={conditionsCommerciales}
                  state={this.props.client}
                  stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                  service={this.props.service}
                  handleChange={this.props.handleChange}
                  colSize="6"
                  editing={this.props.editing}
                  handleEditing={this.props.handleEditing}
                  handleUpdate={this.props.handleUpdate}
                  NoEdition={right == "RO"}
                />
                <BoxInformationsInline
                  title="Commentaire"
                  datas={commentaire}
                  state={this.props.client}
                  stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                  service={this.props.service}
                  handleChange={this.props.handleChange}
                  colSize="6"
                  editing={this.props.editing}
                  handleEditing={this.props.handleEditing}
                  handleUpdate={this.props.handleUpdate}
                  NoEdition={right == "RO"}
                />
              </div>
            </div>
          </>
        );
      }
    } else
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
  }
}

{
  /* <ButtonIcon
      icon={faTrash}
      className="btn btn-danger btn-sm text-light ms-1"
      onClick={this.props.handleDelete}
      style={
        this.props.handleDelete && this.state.edition
          ? null
          : { display: "none" }
      }
      tooltip="Copier l'adresse"
    ></ButtonIcon>
    <ButtonIcon
      icon={faTrash}
      className="btn btn-danger btn-sm text-light ms-1"
      onClick={this.props.handleDelete}
      style={
        this.props.handleDelete && this.state.edition
          ? null
          : { display: "none" }
      }
      tooltip="Coller l'adresse"
    ></ButtonIcon> */
}

InformationsClient.propTypes = {
  client: PropTypes.object,
  stateFieldNameToUpdate: PropTypes.string,
  handleChange: PropTypes.func,
  history: PropTypes.object,
  service: PropTypes.func,
};

export { InformationsClient };
