import React from "react";
import {
  ReactTableServerSide,
  IndeterminateCheckbox,
  CellWithRedirection,
} from "_components";

//import { RedirectTo } from "_utils";
import { PropTypes } from "prop-types";
import {
  CommandeService,
  PersonnelService,
  ClientService,
  SageDocumentService,
  StatutsCommandeService,
  ProduitInterneService,
} from "_services/";
import { ToLocaleDateString } from "_utils";
import { Helmet } from "react-helmet";
import { App } from "App";

class BaseCommande extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Base commande</title>
        </Helmet>
        <ReactTableServerSide
          title="Base Commande"
          titreFiltre="Base Commande"
          service={CommandeService.getAll}
          displayExtractButton={
            App.RightsGuard.current?.hasRight("Commande", "Extraction") == "RW"
          }
          serviceExtract={CommandeService.Extract}
          module="Commande"
          // redirectToCreate={() => {
          //   RedirectTo(this.props.history, "/commandes/add");
          // }}
          orderByDefault="reference"
          orderDefault="DESC"
          localStorageKeyColumns="BaseCommandeColumns"
          columns={[
            {
              id: "selection",
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div className="text-center align-middle" id="selectAll">
                  <IndeterminateCheckbox
                    className="align-middle"
                    {...getToggleAllRowsSelectedProps()}
                  />
                </div>
              ),
              Cell: ({ row }) => (
                <div className="text-center align-middle" id="selectOne">
                  <IndeterminateCheckbox
                    className="align-middle"
                    {...row.getToggleRowSelectedProps()}
                  />
                </div>
              ),
            },
            {
              Header: "N° Commande",
              accessor: "reference",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "180px",
              type: "text",
              Cell: (row) => {
                return (
                  <CellWithRedirection
                    history={this.props.history}
                    redirection="/commandes/"
                    id={row.cell.row.original.id}
                    value={row.cell.value}
                  />
                );
              },
            },
            {
              Header: "Nom Commande",
              accessor: "nom_Commande",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "text",
              Cell: (row) => {
                return (
                  <CellWithRedirection
                    history={this.props.history}
                    redirection="/commandes/"
                    id={row.cell.row.original.id}
                    value={row.cell.value}
                  />
                );
              },
            },
            {
              Header: "Client",
              accessor: "client",
              hideable: true,
              exportable: true,
              minWidth: "150px",
              type: "selectSearch",
              valueFieldToDisplay: "nom_Client",
              optionFieldToReturn: "id",
              optionFieldToDisplay: "nom_Client",
              service: ClientService.getForSelectSearch,
              search: "num_Client",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.nom_Client}</span>
                ) : null;
              },
            },
            {
              Header: "Compte Tier",
              accessor: "client.compte_Tier",
              hideable: true,
              exportable: true,
              minWidth: "150px",
              type: "selectSearch",
              valueFieldToDisplay: "compte_Tier",
              optionFieldToDisplay: "compte_Tier",
              optionFieldToReturn: "compte_Tier",
              service: SageDocumentService.getCompteTiers,
              search: "compteTier",
              Cell: (row) => {
                return row.cell.value ? <span>{row.cell.value}</span> : null;
              },
            },
            {
              Header: "Statut",
              accessor: "statut",
              hideable: true,
              exportable: true,
              minWidth: "150px",
              type: "selectSearch",
              search: "statut",
              service: StatutsCommandeService.getAll,
              optionFieldToReturn: "id",
              valueFieldToDisplay: "designation",
              optionFieldToDisplay: "designation",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.designation}</span>
                ) : null;
              },
            },
            {
              Header: "Chargé D'Affaire",
              accessor: "charge_Affaire",
              hideable: true,
              exportable: true,
              minWidth: "150px",
              type: "selectSearch",
              valueFieldToDisplay: ["nom", "prenom"],
              optionFieldToReturn: "id",
              optionFieldToDisplay: ["nom", "prenom"],
              service: PersonnelService.getForSelectSearch,
              search: "num_Charge_Affaire",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>
                    {row.cell.value.nom} {row.cell.value.prenom}
                  </span>
                ) : null;
              },
            },
            {
              Header: "Date de création",
              accessor: "date_Create",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "period",
              search: "date_Create",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{ToLocaleDateString(row.cell.value)}</span>
                ) : null;
              },
            },
            {
              Header: "Montant HT",
              accessor: "prixVenteTotal",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "intervalDecimal",
              search: "prixVenteTotal",
              Cell: (row) => {
                return row.cell.value != null ? (
                  <span>{row.cell.value}</span>
                ) : null;
              },
            },
            {
              Header: "Montant TTC",
              accessor: "prixVenteTTCTotal",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "intervalDecimal",
              search: "montantTTC",
              Cell: (row) => {
                return row.cell.value != null ? (
                  <span>{row.cell.value}</span>
                ) : null;
              },
            },
            {
              Header: "Produit interne",
              accessor: "produit_Interne",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "selectSearch",
              optionFieldToDisplay: "designation",
              optionFieldToReturn: "id",
              valueFieldToDisplay: "designation",
              search: "idProduitInterne",
              service: ProduitInterneService.getAll,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.designation}</span>
                ) : null;
              },
            },

            {
              Header: "International",
              accessor: "international",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "130px",
              type: "selectSearch",
              optionFieldToReturn: "value",
              optionFieldToDisplay: "display",
              valueFieldToDisplay: "display",
              options: [
                {
                  id: 0,
                  display: "Oui",
                  value: true,
                },
                {
                  id: 1,
                  display: "Non",
                  value: false,
                },
              ],
              Cell: (row) => {
                if (row.cell.value == true) {
                  return <div>Oui</div>;
                } else {
                  return <div>Non</div>;
                }
              },
            },
            {
              Header: "Num ARC",
              accessor: "num_Arc_Client",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "text",
              search: "num_ARC",
              Cell: (row) => {
                return row.cell.value ? <span>{row.cell.value}</span> : null;
              },
            },
            {
              Header: "Montant acompte",
              accessor: "montant_Acompte_A_Recevoir",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "intervalDecimal",
              search: "montantAcompte",
              Cell: (row) => {
                return row.cell.value != null ? (
                  <span>{row.cell.value}</span>
                ) : null;
              },
            },
            {
              Header: "Mont. acom. reçu",
              accessor: "montant_Acompte_Recu",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "intervalDecimal",
              search: "montantAcompteRecu",
              Cell: (row) => {
                return row.cell.value != null ? (
                  <span>{row.cell.value}</span>
                ) : null;
              },
            },
            {
              Header: "Date acompte",
              accessor: "date_Acompte",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "period",
              search: "date_Acompte",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{ToLocaleDateString(row.cell.value)}</span>
                ) : null;
              },
            },
            {
              Header: "Acompte",
              accessor: "acompte",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "130px",
              type: "selectSearch",
              optionFieldToReturn: "value",
              optionFieldToDisplay: "display",
              valueFieldToDisplay: "display",
              options: [
                {
                  id: 1,
                  display: "Oui",
                  value: true,
                },
                {
                  id: 2,
                  display: "Non",
                  value: false,
                },
              ],
              Cell: (row) => {
                if (row.cell.value == true) {
                  return <div>Oui</div>;
                } else {
                  return <div>Non</div>;
                }
              },
            },
          ]}
        />
      </>
    );
  }
}

BaseCommande.propTypes = {
  history: PropTypes.object,
};

export { BaseCommande };
