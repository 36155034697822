import React from "react";
import { PropTypes } from "prop-types";

/**
 * Génère un formulaire avec une vérification sur ses champs
 * Pour être utilisé il faut lui passer les inputs qu'il va contenir ainsi qu'une fonction de soumission
 * Ex :
 *  <FormWithValidation onSubmit={handleSubmit}>
 *    <div>
 *      <label>Souhaitez-vous vous abonner à la newsletter ?</label>
 *      <input type="checkbox">
 *    </div>
 *  </FormWithValidation>
 */
class FormWithValidation extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // Validation Bootstrap 5
    (function () {
      "use strict";

      let forms = document.querySelectorAll(".needs-validation");

      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            if (!form.checkValidity()) {
              event.preventDefault();
              event.stopPropagation();
              form.classList.add("was-validated");
              // Gestion des select à choix multiples
              let multipleSelects = form.querySelectorAll(".multipleSelect");
              Array.prototype.slice
                .call(multipleSelects)
                .forEach(function (multipleSelect) {
                  if (!multipleSelect.childNodes[1].value) {
                    multipleSelect.classList.remove("is-valid");
                    multipleSelect.classList.add("is-invalid");
                  } else {
                    multipleSelect.classList.remove("is-invalid");
                    multipleSelect.classList.add("is-valid");
                  }
                });

              let multipleSelectSearchs = form.querySelectorAll(
                ".multipleSelectSearch"
              );
              Array.prototype.slice
                .call(multipleSelectSearchs)
                .forEach(function (multipleSelectSearch) {
                  if (!multipleSelectSearch.childNodes[0].childNodes[1].value) {
                    multipleSelectSearch.classList.remove("is-valid");
                    multipleSelectSearch.classList.add("is-invalid");
                  } else {
                    multipleSelectSearch.classList.remove("is-invalid");
                    multipleSelectSearch.classList.add("is-valid");
                  }
                });
            } else {
              form.classList.remove("was-validated");
            }
          },
          false
        );
      });
    })();
  }

  render() {
    return (
      <form
        id={this.props.id}
        className={this.props.className + " needs-validation"}
        onSubmit={this.props.onSubmit}
        noValidate
      >
        {this.props.children}
      </form>
    );
  }
}

FormWithValidation.propTypes = {
  children: PropTypes.any,
  onSubmit: PropTypes.func,
  className: PropTypes.string,
};

export { FormWithValidation };
