import React from "react";

import { LigneDroit } from "./LigneDroit";

import { PropTypes } from "prop-types";

import { Box, ButtonIcon } from "_components";

import { faCheck, faTimes, faPen } from "@fortawesome/free-solid-svg-icons";
import { DroitsService } from "_services";
import { Input } from "_components/Input";
import { produce } from "immer";

import "./ReferentielDroit.css";

class ReferentielDroit extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      savedDroits: null,
      loading: false,
      module: "Affaire",
    };

    this.handleClickEdition = this.handleClickEdition.bind(this);
    this.handleClickUndo = this.handleClickUndo.bind(this);
    this.handleClickValidate = this.handleClickValidate.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    DroitsService.getAll().then((res) =>
      this.setState({
        droit: res.data,
        loading: true,
      })
    );
  }

  handleClickEdition() {
    this.setState({
      savedDroits: [...this.state.droit],
    });
    this.props.handleEditing(true);
  }

  handleClickUndo() {
    this.handleChange("droits", this.state.savedDroits);
    this.props.handleEditing(false);
  }

  handleClickValidate() {
    this.handleUpdate();
    this.props.handleEditing(false);
  }

  /**
   * Permet de mettre a jour l'etat du fiche de suivi sans devoir le recharger
   * @param {*} accessor
   * @param {*} value
   */
  handleChange(accessor, value, callbackFunction) {
    if (accessor === "droits") {
      //Undo
      this.setState(
        produce((prevState) => {
          prevState.droit = value;
        })
      );
    } else if (accessor.includes(".")) {
      var accessorSplit = accessor.split(".");
      if (accessorSplit[0] === "droits") {
        // Format droit.id.accessor
        var droitId = accessorSplit[1];
        var droitAccessor = accessorSplit[2];

        this.setState(
          produce((prevState) => {
            let prev = prevState.droit.find((el) => el.id == droitId);
            prev[droitAccessor] = value;
          }),
          callbackFunction
        );
      }
    }
  }

  handleUpdate() {
    DroitsService.update(this.state.droit);
  }

  render() {
    return (
      <div className="row">
        <div className="col-12">
          <Box
            header={
              <>
                <div className="col-6 align-self-center py-2">
                  Table des droits
                </div>
                <div className="col-6 text-end py-2">
                  <ButtonIcon
                    icon={faCheck}
                    className="btn btn-success text-light ms-1"
                    style={
                      !this.props.editing
                        ? { display: "none" }
                        : { width: "42px" }
                    }
                    onClick={this.handleClickValidate}
                  ></ButtonIcon>
                  <ButtonIcon
                    icon={faTimes}
                    className="btn btn-danger text-light ms-1"
                    onClick={this.handleClickUndo}
                    style={
                      !this.props.editing
                        ? { display: "none" }
                        : { width: "42px" }
                    }
                  ></ButtonIcon>
                  <ButtonIcon
                    icon={faPen}
                    className="btn btn-secondary text-light ms-1"
                    onClick={this.handleClickEdition}
                    style={
                      this.props.editing
                        ? { display: "none" }
                        : { width: "42px" }
                    }
                  ></ButtonIcon>
                </div>
              </>
            }
            body={
              <>
                <div className="col-3 align-self-center py-2">
                  <div
                    id="module"
                    style={{
                      width: "80px",
                      flexShrink: "0",
                    }}
                  >
                    Module :
                  </div>
                  <Input
                    accessor="module"
                    type="selectSearch"
                    value={{ designation: "Affaire", id: 0 }}
                    valueFieldToDisplay={"designation"}
                    optionFieldToDisplay={"designation"}
                    optionFieldToReturn={"designation"}
                    handleChange={(acc, val) => this.setState({ module: val })}
                    options={[
                      { designation: "Affaire", id: 0 },
                      { designation: "Article", id: 1 },
                      { designation: "Cartes bleues", id: 2 },
                      { designation: "Chantiers", id: 13 },

                      { designation: "Client", id: 3 },
                      { designation: "Client - Logiciel", id: 11 },

                      { designation: "Commande", id: 4 },
                      { designation: "Compte Tiers", id: 12 },

                      { designation: "Contact", id: 5 },
                      { designation: "Contrat", id: 14 },

                      { designation: "Consultation", id: 6 },
                      { designation: "Devis", id: 7 },
                      { designation: "Fiche de suivi", id: 8 },
                      { designation: "Fournisseurs", id: 15 },
                      { designation: "Materiel", id: 9 },
                      { designation: "Personnel", id: 10 },
                    ]}
                  />
                </div>
                <div>
                  <div style={{ overflowX: "scroll" }}>
                    <table className="table table-sm table-striped m-0">
                      <thead>
                        <tr>
                          <th className="ps-2 position-sticky bg-light">
                            Module
                          </th>
                          <th className="ps-2 position-sticky bg-light">
                            Onglet
                          </th>
                          <th className="ps-2" style={{ minWidth: "60px" }}>
                            DGG
                          </th>
                          <th className="ps-2" style={{ minWidth: "60px" }}>
                            DCO
                          </th>
                          <th className="ps-2" style={{ minWidth: "60px" }}>
                            DST
                          </th>
                          <th className="ps-2" style={{ minWidth: "60px" }}>
                            DDO
                          </th>
                          <th className="ps-2" style={{ minWidth: "60px" }}>
                            DET
                          </th>
                          <th className="ps-2" style={{ minWidth: "60px" }}>
                            DRD
                          </th>

                          <th className="ps-2" style={{ minWidth: "60px" }}>
                            AAD
                          </th>
                          <th className="ps-2" style={{ minWidth: "60px" }}>
                            AGG
                          </th>
                          <th className="ps-2" style={{ minWidth: "60px" }}>
                            ARH
                          </th>

                          <th className="ps-2" style={{ minWidth: "60px" }}>
                            RDO
                          </th>
                          <th className="ps-2" style={{ minWidth: "60px" }}>
                            RCO
                          </th>
                          <th className="ps-2" style={{ minWidth: "60px" }}>
                            RET
                          </th>
                          <th className="ps-2" style={{ minWidth: "60px" }}>
                            RQS
                          </th>
                          <th className="ps-2" style={{ minWidth: "60px" }}>
                            RRD
                          </th>
                          <th className="ps-2" style={{ minWidth: "60px" }}>
                            RRH
                          </th>
                          <th className="ps-2" style={{ minWidth: "60px" }}>
                            RFI
                          </th>
                          <th className="ps-2" style={{ minWidth: "60px" }}>
                            RST
                          </th>

                          <th className="ps-2" style={{ minWidth: "60px" }}>
                            SCO
                          </th>
                          <th className="ps-2" style={{ minWidth: "60px" }}>
                            SDO
                          </th>
                          <th className="ps-2" style={{ minWidth: "60px" }}>
                            SET
                          </th>
                          <th className="ps-2" style={{ minWidth: "60px" }}>
                            SIN
                          </th>
                          <th className="ps-2" style={{ minWidth: "60px" }}>
                            SFI
                          </th>
                          <th className="ps-2" style={{ minWidth: "60px" }}>
                            SRD
                          </th>

                          <th className="ps-2" style={{ minWidth: "60px" }}>
                            SST
                          </th>
                          <th className="ps-2" style={{ minWidth: "60px" }}>
                            SQS
                          </th>

                          <th className="ps-2" style={{ minWidth: "60px" }}>
                            UDO
                          </th>
                          <th className="ps-2" style={{ minWidth: "60px" }}>
                            UCO
                          </th>
                          <th className="ps-2" style={{ minWidth: "60px" }}>
                            URD
                          </th>
                          <th className="ps-2" style={{ minWidth: "60px" }}>
                            UET
                          </th>
                          <th className="ps-2" style={{ minWidth: "60px" }}>
                            UST
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.loading
                          ? this.state.droit
                              .filter((d) => {
                                return d.module == this.state.module;
                              })
                              .map((droit) => (
                                <LigneDroit
                                  key={droit.id}
                                  droit={droit}
                                  handleChange={this.handleChange}
                                  editing={this.props.editing}
                                />
                              ))
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
    );
  }
}

ReferentielDroit.propTypes = {
  stateFieldNameToUpdate: PropTypes.string,
  handleChange: PropTypes.func,
  history: PropTypes.object,
  service: PropTypes.func,
};

export { ReferentielDroit };
