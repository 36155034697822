import React, { useEffect, useState } from "react";
import { App } from "App";

import { LigneNdF } from "./SubTab/LigneNdF";

import {
  Box,
  ButtonIcon,
  DialogForm,
  FormWithValidation,
  WarningBar,
} from "_components";
import { Input } from "_components/Input";

import {
  faPlus,
  faCheck,
  faTimes,
  faPen,
  faFileExport,
  faDownload,
  faFileUpload,
  faFileInvoiceDollar,
} from "@fortawesome/free-solid-svg-icons";
import { CarteBancaireService, NoteDeFraisService } from "_services";
import * as FileSaver from "file-saver";

const NotesDeFrais = (props) => {
  const [notesDeFrais, setNotesDeFrais] = useState([]);
  const [savedNdF, setSavedNdF] = useState([]);
  const [ligneACopier, setLigneACopier] = useState(null);
  const [numeroFrais, setNumeroFrais] = useState(null);
  const [exportAll, setExportAll] = useState(false);

  const [editingNdF, setEditingNdF] = useState(false);
  const [openDialogue, setOpenDialogue] = useState(() => {});

  let date = new Date();
  date.setMonth(new Date().getMonth() - 2);

  const [periode, setPeriode] = useState(
    date.toISOString() + "|" + new Date().toISOString()
  );

  useEffect(() => props.handleEditing(editingNdF), [editingNdF]);
  useEffect(() => {
    notesDeFrais
      ?.slice()
      ?.filter((e) => e.numeroFrais == null)

      .map((e) => {
        let prev = notesDeFrais.find((el) => el.id == e.id);
        prev.export = exportAll;
        return prev;
      });
  }, [exportAll]);

  const loadNdF = () => {
    NoteDeFraisService.getNdFCb({
      cb: props.cartebancaire.id,
      intervalleDate: periode,
      intervalle: true,
    }).then((res) => {
      console.log(res);
      setNotesDeFrais(res.data.ndFs);
    });
  };

  useEffect(() => {
    loadNdF();
  }, [periode]);
  const handleClickEditionNotesDeFrais = () => {
    setSavedNdF(notesDeFrais);
    setEditingNdF(true);
    props.handleEditing(true);
  };

  const handleClickUndoNotesDeFrais = () => {
    setNotesDeFrais(savedNdF);
    setEditingNdF(false);
    props.handleEditing(false);
  };

  const handleClickValidateNotesDeFrais = (event) => {
    event.preventDefault();
    NoteDeFraisService.updateNdFCb({
      cb: props.cartebancaire,
      ndFs: notesDeFrais.filter((e) => e.new || e.deleted || e.updated),
    }).then(() => {
      loadNdF();
      setEditingNdF(false);
      props.handleEditing(false);
    });
  };

  const addLineNoteDeFrais = () => {
    if (notesDeFrais == null) {
      setNotesDeFrais([]);
    }
    // To keep the ids unique, if there is no other new line (with negative id), the new line id is -1, else, it's the minimum id - 1.
    let newLineId = Math.min(...notesDeFrais.map((el) => el.id), 0) - 1;

    setNotesDeFrais([
      ...notesDeFrais,
      {
        id: newLineId,
        dateFrais: new Date(),
        libelle: "",
        affaire: null,
        nombre: 0,
        new: true,
      },
    ]);
  };

  const copierLineNoteDeFrais = (id) => {
    let aCopier = notesDeFrais.find((el) => el.id == id);
    setLigneACopier({ ...aCopier });
  };

  const collerLineNoteDeFrais = (id) => {
    if (ligneACopier != null) {
      let ndfstmp = notesDeFrais.map((e) => {
        return { ...e };
      });

      let aChanger = ndfstmp.find((el) => el.id == id);

      aChanger.dateFrais = ligneACopier.dateFrais;
      aChanger.libelle = ligneACopier.libelle;
      aChanger.affaire = ligneACopier.affaire;
      aChanger.nombre = ligneACopier.nombre;
      aChanger.montant_TTC = ligneACopier.montant_TTC;
      aChanger.montant_TVA = ligneACopier.montant_TVA;
      aChanger.typeNoteDeFrais = ligneACopier.typeNoteDeFrais;
      aChanger.updated = true;

      ndfstmp.splice(
        ndfstmp.findIndex((el) => el.id == id),
        1,
        aChanger
      );
      setNotesDeFrais(ndfstmp);
    }
  };

  const deleteLineNoteDeFrais = (ndfId) => {
    let ndfstmp = notesDeFrais.map((e) => {
      return { ...e };
    });
    if (ndfId < 1) {
      ndfstmp.splice(
        ndfstmp.findIndex((el) => el.id == ndfId),
        1
      );
      setNotesDeFrais([...ndfstmp]);
    } else {
      let prev = notesDeFrais.find((el) => el.id == ndfId);
      prev = { ...prev };
      prev.deleted = true;
      ndfstmp.splice(
        ndfstmp.findIndex((el) => el.id == ndfId),
        1,
        prev
      );

      setNotesDeFrais([...ndfstmp]);
    }
  };

  const handleChangeNdF = (acc, value) => {
    let accessorSplit = acc.split(".");
    var id = accessorSplit[1];
    var realAccessor = accessorSplit[2];

    let ndfstmp = notesDeFrais.map((e) => {
      return { ...e };
    });

    let ndfToUpdate = ndfstmp.find((el) => el.id == id);
    ndfToUpdate[realAccessor] = value;
    ndfToUpdate.updated = true;
    ndfstmp.splice(
      ndfstmp.findIndex((el) => el.id == id),
      1,
      ndfToUpdate
    );
    setNotesDeFrais(ndfstmp);
  };

  const right = App.RightsGuard?.current?.hasRight(
    "Cartes bleues",
    "Notes de frais"
  );

  const rightExtraction = App.RightsGuard?.current?.hasRight(
    "Cartes bleues",
    "Extraction"
  );

  const rightCreationNDF = App.RightsGuard?.current?.hasRight(
    "Cartes bleues",
    "CreationNdF"
  );

  const rightSuppression = App.RightsGuard?.current?.hasRight(
    "Cartes bleues",
    "Suppression"
  );

  const rightImport = App.RightsGuard?.current?.hasRight(
    "Affaire",
    "Import excel"
  );

  if (right == "RW" || right == "RO") {
    let total = 0;
    notesDeFrais?.map(
      (ndf) => (total = total + (ndf.export ? ndf.montant_TTC : 0))
    );

    let enoughNdF = notesDeFrais?.filter((e) => e.export).length != 0;

    return (
      <>
        {rightImport == "RW" ? (
          <ButtonIcon
            icon={faFileUpload}
            onClick={() => {
              props.history.push("/notesDeFraisCb/");
            }}
            className="btn btn-primary text-light mt-1"
            textOption=" Import notes de frais"
          />
        ) : null}

        <DialogForm
          icon={faFileInvoiceDollar}
          tooltip="Générer une commande"
          classNameButton="btn btn-success"
          dialogTitle="Numéro de bordereau"
          labelValidateButton="Lier les notes de frais"
          setOpenDialog={(func) => setOpenDialogue(() => func)}
          onValidate={() => {
            let exportCB = notesDeFrais.filter((e) => e.export);
            CarteBancaireService.numeroter(numeroFrais, exportCB).then(() => {
              loadNdF();
              NoteDeFraisService.extract(exportCB).then((res) => {
                var blob = new Blob([res?.data], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                FileSaver.saveAs(blob, "extractionNdF" + ".xlsx");
              });
            });
          }}
          onClose={() => {}}
          body={
            <div>
              <Input
                label="Numéro de bordereau"
                accessor="numeroFrais"
                type="decimal"
                numberOfDecimals={0}
                value={numeroFrais}
                showClearButton={false}
                handleBlur={(accessor, value) => {
                  setNumeroFrais(value);
                }}
                required={true}
              />
            </div>
          }
        />

        <div className="row">
          <div className="col-12">
            <FormWithValidation onSubmit={handleClickValidateNotesDeFrais}>
              <Box
                header={
                  <>
                    <div className="col-6 align-self-center py-2">
                      <div>Note de frais</div>
                    </div>
                    <div className="col-6 text-end py-2">
                      <ButtonIcon
                        icon={faPlus}
                        iconSize="sm"
                        onClick={() => addLineNoteDeFrais()}
                        className="btn btn-success addHeure"
                        style={
                          !editingNdF || rightCreationNDF != "RW"
                            ? { display: "none" }
                            : {}
                        }
                      />
                      <ButtonIcon
                        icon={faCheck}
                        className="btn btn-success text-light ms-1"
                        style={
                          !editingNdF ? { display: "none" } : { width: "42px" }
                        }
                        type="submit"
                      />
                      <ButtonIcon
                        icon={faTimes}
                        className="btn btn-danger text-light ms-1"
                        onClick={handleClickUndoNotesDeFrais}
                        style={
                          !editingNdF ? { display: "none" } : { width: "42px" }
                        }
                      />
                      <ButtonIcon
                        icon={faPen}
                        className="btn btn-secondary text-light ms-1"
                        onClick={handleClickEditionNotesDeFrais}
                        style={
                          props.editing
                            ? { display: "none" }
                            : { width: "42px" }
                        }
                        disabled={!(right == "RW")}
                      />
                    </div>
                  </>
                }
                body={
                  <>
                    <div className="col-4 align-self-center py-2">
                      Filtrer selon la période du :
                      <Input
                        value={periode}
                        type="period"
                        handleChange={(acc, val) => setPeriode(val)}
                      />
                    </div>
                    <div className="col-4 align-self-center py-2">
                      <div>
                        Nombre de lignes sélectionnées:
                        {notesDeFrais?.filter((e) => e.export).length}
                      </div>
                      <div>
                        Total des lignes sélectionnées :
                        {Number(total.toFixed(2))}
                      </div>
                      <ButtonIcon
                        icon={faFileExport}
                        className="btn btn-secondary text-light ms-1"
                        onClick={() => {
                          openDialogue();
                        }}
                        style={{ width: "42px" }}
                        tooltip={
                          editingNdF || enoughNdF
                            ? "Extraire et numéroter"
                            : "Vous ne pouvez pas extraire car aucune ligne n'est sélectionnée"
                        }
                        disabled={rightExtraction != "RW" || !enoughNdF}
                      />
                      <ButtonIcon
                        icon={faDownload}
                        className="btn btn-secondary text-light ms-1"
                        onClick={() => {
                          let exportCB = notesDeFrais.filter((e) => e.export);
                          NoteDeFraisService.extract(exportCB).then((res) => {
                            var blob = new Blob([res?.data], {
                              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                            });
                            FileSaver.saveAs(blob, "extractionNdF" + ".xlsx");
                          });
                        }}
                        style={{ width: "42px" }}
                        tooltip={
                          editingNdF || enoughNdF
                            ? "Extraire"
                            : "Vous ne pouvez pas extraire car aucune ligne n'est sélectionnée"
                        }
                        disabled={rightExtraction != "RW" || !enoughNdF}
                      />
                    </div>

                    <table className="mt-1 table table-sm table-striped table-bordered">
                      <thead>
                        <tr>
                          <th className="ps-2" style={{ width: "30px" }}>
                            <Input
                              accessor={"exportAll"}
                              type="checkbox"
                              placeholder=""
                              value={exportAll}
                              handleChange={() => setExportAll(!exportAll)}
                              showValidator={false}
                              showClearButton={false}
                            />
                          </th>
                          <th className="ps-2" style={{ width: "135px" }}>
                            Numéro de Frais
                          </th>
                          <th className="ps-2">Libelle</th>
                          <th className="ps-2">Affaire</th>
                          <th className="ps-2" style={{ maxWidth: "100px" }}>
                            Montant TTC
                          </th>
                          <th className="ps-2" style={{ maxWidth: "100px" }}>
                            Montant TVA
                          </th>
                          <th className="ps-2">Rubrique</th>
                          <th className="ps-2" style={{ maxWidth: "100px" }}>
                            Nombre
                          </th>
                          <th className="ps-2">Date de Frais</th>
                          <th className="ps-2">BDC Existant</th>

                          <th style={{ width: "41px" }}></th>
                          <th style={{ width: "41px" }}></th>
                          <th
                            style={
                              rightSuppression != "RW"
                                ? { display: "none" }
                                : { width: "41px" }
                            }
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                        {notesDeFrais
                          ?.slice()
                          ?.filter((e) => e.new)

                          .map((ndf) => (
                            <LigneNdF
                              key={ndf.id}
                              ndf={ndf}
                              handleChange={handleChangeNdF}
                              editing={editingNdF}
                              deleteLineNoteDeFrais={deleteLineNoteDeFrais}
                              copierLineNotesDeFrais={copierLineNoteDeFrais}
                              collerLineNotesDeFrais={collerLineNoteDeFrais}
                              rightSuppression={rightSuppression}
                            />
                          ))}
                        {notesDeFrais
                          ?.filter((e) => !e.new && !e.deleted)

                          .slice()
                          .sort(
                            (a, b) =>
                              new Date(b.dateFrais) - new Date(a.dateFrais)
                          )
                          .map((ndf) => (
                            <LigneNdF
                              key={ndf.id}
                              ndf={ndf}
                              handleChange={handleChangeNdF}
                              editing={editingNdF}
                              deleteLineNoteDeFrais={deleteLineNoteDeFrais}
                              copierLineNotesDeFrais={copierLineNoteDeFrais}
                              collerLineNotesDeFrais={collerLineNoteDeFrais}
                              rightSuppression={rightSuppression}
                            />
                          ))}
                      </tbody>
                    </table>
                  </>
                }
              />
            </FormWithValidation>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <WarningBar
          active={false}
          content={"Vous n'avez pas le droit de voir cette page"}
        />
      </>
    );
  }
};

export { NotesDeFrais };
