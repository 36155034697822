import {
  faCheck,
  faPen,
  faPlus,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Input } from "_components/Input";

const { Box, ButtonIcon } = require("_components");
const { useState } = require("react");

const LigneDatesQuestionnaire = (props) => {
  return (
    <tr className="ligneFournisseurDatesQuestionnaire">
      <td className="col3 background-color">
        <Input
          accessor={"" + props.dateQ.id + ".date"}
          value={props.dateQ.date}
          type="date"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td className="col4 background-color" style={{ width: "55px" }}>
        <ButtonIcon
          smallText=""
          icon={faTrash}
          iconSize="sm"
          onClick={() => {
            props?.deleteLineDatesQuestionnaire(props.dateQ.id);
          }}
          className="btn btn-danger deleteLineDatesQuestionnaire"
          style={!props.editing ? { display: "none" } : {}}
        />
      </td>
    </tr>
  );
};

const BoxDatesQuestionnaire = (props) => {
  const [savedFournisseur, setSavedFournisseur] = useState({});

  const [editingDatesQuestionnaire, setEditingDatesQuestionnaire] =
    useState(false);
  const [dateQs, setDatesQuestionnaires] = useState([]);

  const handleClickEdition = () => {
    setSavedFournisseur({ ...props.fournisseur });
    props.handleEditing(true);
    setEditingDatesQuestionnaire(true);
    if (props.fournisseur.datesQuestionnaire == null) {
      setDatesQuestionnaires([]);
    } else {
      setDatesQuestionnaires(
        props.fournisseur.datesQuestionnaire.map((e) => {
          return { ...e };
        })
      );
    }
  };

  const handleClickUndo = () => {
    props.handleChange("fournisseur", savedFournisseur);
    props.handleEditing(false);
    setEditingDatesQuestionnaire(false);
    setDatesQuestionnaires([]);
  };

  const handleClickValidate = () => {
    props.handleChange(
      "datesQuestionnaire",
      dateQs.map((e) => {
        return { ...e };
      })
    );
    console.log(props.fournisseur);

    props.handleEditing(false);
    setEditingDatesQuestionnaire(false);
    setDatesQuestionnaires([]);
  };

  const addLineDatesQuestionnaire = () => {
    let newLineId = Math.min(...dateQs.map((el) => el.id), 0) - 1;

    let newDatesQuestionnaire = {
      id: newLineId,
      date: null,
    };
    setDatesQuestionnaires([...dateQs, newDatesQuestionnaire]);
  };

  const deleteLineDatesQuestionnaire = (dateQId) => {
    let dateQtmp = dateQs.map((e) => {
      return { ...e };
    });
    dateQtmp.splice(
      dateQtmp.findIndex((el) => el.id == dateQId),
      1
    );
    console.log(dateQs, dateQtmp);
    setDatesQuestionnaires([...dateQtmp]);
  };

  const handleChangeDatesQuestionnaire = (accessor, value) => {
    // Format enfantPersonnel.id.accessor
    let accessorSplit = accessor.split(".");
    var id = accessorSplit[0];
    var realAccessor = accessorSplit[1];

    let dateQtmp = dateQs.map((e) => {
      return { ...e };
    });

    let dateQToUpdate = dateQtmp.find((el) => el.id == id);
    dateQToUpdate[realAccessor] = value;

    dateQtmp.splice(
      dateQtmp.findIndex((el) => el.id == id),
      1,
      dateQToUpdate
    );

    setDatesQuestionnaires(dateQtmp);
  };

  return (
    <Box
      header={
        <>
          <div className="col-6 align-self-center py-2">
            <div>Dates Questionnaire</div>
          </div>
          <div className="col-6 text-end py-2">
            <ButtonIcon
              icon={faPlus}
              iconSize="sm"
              onClick={() => addLineDatesQuestionnaire?.()}
              className="btn btn-success addEnfant"
              style={!editingDatesQuestionnaire ? { display: "none" } : {}}
            />
            <ButtonIcon
              icon={faCheck}
              className="btn btn-success text-light ms-1"
              style={
                !editingDatesQuestionnaire
                  ? { display: "none" }
                  : { width: "42px" }
              }
              onClick={handleClickValidate}
            />
            <ButtonIcon
              icon={faTimes}
              className="btn btn-danger text-light ms-1"
              onClick={handleClickUndo}
              style={
                !editingDatesQuestionnaire
                  ? { display: "none" }
                  : { width: "42px" }
              }
            />
            <ButtonIcon
              icon={faPen}
              className="btn btn-secondary text-light ms-1"
              onClick={handleClickEdition}
              style={
                props.editing || props.NoEdition
                  ? { display: "none" }
                  : { width: "42px" }
              }
            />
          </div>
        </>
      }
      body={
        <>
          <table className={"text-uppercase table table-sm "}>
            <thead
              className={
                "sticky-top" +
                (editingDatesQuestionnaire
                  ? dateQs.length == 0
                    ? " theadBorderRadius"
                    : ""
                  : props.fournisseur.datesQuestionnaire?.length == 0
                  ? " theadBorderRadius"
                  : "")
              }
            >
              <tr>
                <th>Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {editingDatesQuestionnaire
                ? dateQs
                    ?.slice()
                    .map((dateQ) => (
                      <LigneDatesQuestionnaire
                        key={dateQ.id}
                        dateQ={dateQ}
                        handleChange={handleChangeDatesQuestionnaire}
                        editing={editingDatesQuestionnaire}
                        deleteLineDatesQuestionnaire={
                          deleteLineDatesQuestionnaire
                        }
                      />
                    ))
                : props.fournisseur.datesQuestionnaire
                    ?.slice()
                    .map((dateQ) => (
                      <LigneDatesQuestionnaire
                        key={dateQ.id}
                        dateQ={dateQ}
                        handleChange={handleChangeDatesQuestionnaire}
                        editing={editingDatesQuestionnaire}
                        deleteLineDatesQuestionnaire={
                          deleteLineDatesQuestionnaire
                        }
                      />
                    ))}
            </tbody>
          </table>
        </>
      }
    />
  );
};

export { BoxDatesQuestionnaire };
