import React, { useState, useEffect } from "react";
import { faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { ButtonIcon, TooltipEncapsulator } from "_components";
import { sanitizeValue, formatValueDecimal } from "_utils/DecimalFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Génère le composant Input
 * @param {string} label, le label de l'input
 * @param {*} value, la valeur de l'input
 * @param {string} accessor, l'accesseur de l'objet lié à cet input
 * @param {function} handleChange, la fonction à appeler lors d'un changement
 * @returns le composant Input
 */

const DecimalTable = (props) => {
  const [value, setValue] = useState(props.value);

  /**
   *
   * Recupère les filtres liés  à la grille
   */

  // const handleOnBlur = () => {
  //   let valuetmp = formatValueDecimal(
  //     value,
  //     props.ignoredValues,
  //     props.numberOfDecimals
  //   );
  //   setValue(sanitizeValue(valuetmp));
  //   if (props.handleBlur) {
  //     props.handleBlur(valuetmp);
  //   }
  // };
  // useEffect(() => {
  //   setValue();
  // }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (value != props.value) props.handleBlur(props.accessor, value);
      // Send Axios request here
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [value]);

  return (
    <>
      {props.label ? (
        <div className="text-uppercase text-muted solwayFont">
          {props.label}
        </div>
      ) : (
        ""
      )}
      {props.tooltip ? (
        <TooltipEncapsulator tooltip={props.tooltip}>
          <span className="ps-2" style={{ marginRight: "10px" }}>
            <FontAwesomeIcon icon={faInfoCircle} size="sm" />
          </span>
        </TooltipEncapsulator>
      ) : (
        ""
      )}
      <div className="input-decimal input-group has-validation">
        <input
          className="form-control"
          style={props.style}
          name={props.accessor}
          required={props.required ?? false}
          value={value ?? ""}
          //onChange={() => {}}
          type="text"
          onChange={(event) => {
            let valuetmp = formatValueDecimal(
              event.target.value,
              props.ignoredValues ?? [],
              props.numberOfDecimals ?? 2
            );
            setValue(sanitizeValue(valuetmp));
          }}
          aria-describedby={
            "inputGroup" + props.accessor + " validation" + props.accessor
          }
          disabled={props.disabled}
        />
        {props.showClearButton ? (
          <ButtonIcon
            id={"inputGroup" + props.accessor}
            smallText=""
            icon={faTimes}
            iconSize="sm"
            onClick={() => {
              setValue(null);
            }}
            className="btn btn-danger"
            tabIndex="-1"
            style={props.disabled ? { display: "none" } : {}}
          ></ButtonIcon>
        ) : null}
        {props.showValidator ? (
          <div id={"validation" + props.accessor} className="invalid-feedback">
            {props.invalidText}
          </div>
        ) : null}
      </div>
    </>
  );
};

export { DecimalTable };
